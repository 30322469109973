import { APIResponse, User} from '../../Shared/interfaces';
import { environment } from '../../../environments/environment';
import axios from 'axios';
import { SharedService } from '../../Shared/Shared.service';
 
const sharedService = new SharedService();

export class AdminService {
  private APIURL = environment.APIURL;


  async getAdmins() {
    return axios.get<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/getAdmins`, {params: {}, headers: await sharedService.getAuthHeader()});
  }

  async getCompanyMembers(limit: number, searchPage: number) {
    return axios.get<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/getCompanyMembers`, {params: {limit, searchPage}, headers: await sharedService.getAuthHeader()});
  }

  async getTokenSymbol(req: { companyId: any}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/getTokenSymbol`, req, {headers: await sharedService.getAuthHeader()});
  }

  async assignAdminRoles(req: {role: string, teamMembersIds: string[]}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/assignAdminRoles`, req, {headers: await sharedService.getAuthHeader()});
  }

  // async getTeamRoles(departmentId: string) {
  //   return axios.get<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/getTeamRoles`, {params: {departmentId}, headers: await sharedService.getAuthHeader()});
  // }

  // async getTeamDepartments(team?: string) {
  //   return axios.get<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/getTeamDepartments`, {params: {team}, headers: await sharedService.getAuthHeader()});
  // }

  // async sendInviteToTeamMember(req: any) {
  //   return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/sendInviteToTeamMember`, req, {headers: await sharedService.getAuthHeader()});
  // }


  async addAuthorizers(req: {teamMembersIds: string[]}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/addAuthorizers`, req, {headers: await sharedService.getAuthHeader()});
  }

  async assignAuthorizers(req: {teamMembersIds: string[]}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/assignAuthorizers`, req, {headers: await sharedService.getAuthHeader()});
  }

  async removeAuthorizers(req: {teamMembersIds: string[]}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/removeAuthorizers`, req, {headers: await sharedService.getAuthHeader()});
  }

  async confirmAuthorization(req: { _id: any , companyId : any }) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/confirmAuthorization`, req, {headers: await sharedService.getAuthHeader()});
  }


}