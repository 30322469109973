import React from 'react';
import { Modal, Typography, Row, Col, Spin } from 'antd';
import { SharedService } from '../Shared.service';
import { MetamaskService } from '../Metamask.service';
import {CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons';

const {Text, Title} = Typography;
const sharedService = new SharedService();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default ({title, transactions, closeModal, isModalVisible, closingModal} : {title: string | false | undefined, transactions: {submitting?: boolean, receipt?: any, details: string}[], closeModal: () => void, isModalVisible: boolean, closingModal?: boolean}) => {
  const {networkId} = useSelectedWalletContext();

  return (
    <Modal
        title={`Transaction processing`} 
        closable={false}
        onOk={() => closeModal()}
        keyboard={false}
        maskClosable={false}
        okText={'Close'}
        cancelButtonProps = {{hidden: true}}
        okButtonProps={{hidden: !!transactions.find(transaction => transaction.submitting), loading: closingModal}}
        // okButtonProps={{hidden: !(!submitting && transactionReceipt?.status), loading: updatingSymbolDetailsAndSTData}}
        // cancelButtonProps={{hidden: !(!submitting && (!transactionReceipt || !transactionReceipt.status))}}
        visible={isModalVisible}>
      <Row>
        {isModalVisible && 
          <Col span={24}>
            <Title level={3}>{title}</Title>
            <br/>
          </Col>
        }

        {transactions.map((transaction, index) => 
          <React.Fragment key = {index}>
            <Col span={1}>
              {transaction.submitting && <Spin size='large'/>}
              {!transaction.submitting && transaction.receipt?.status && 
                <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:'25px'}}/>
              }
              {!transaction.submitting && !transaction.receipt?.status && (index === 0 || (!transactions[index-1].submitting && transactions[index-1].receipt?.status)) && 
                <CloseCircleTwoTone twoToneColor="#ff0000" style={{fontSize:'25px'}}/>
              }
            </Col>
            <Col span={22} offset={1}>
              <Text style={{fontWeight:'bold'}}>
                {transaction.details}
              </Text>
              <br/><br/>
              {transaction.receipt && 
                <Text style={{color:'grey'}}>
                  Transaction details on Etherscan: 
                  <a style={{marginLeft:'6px'}} href={`${sharedService.etherscanURL[networkId as string]}/tx/${transaction.receipt.transactionHash}`} target="_blank" rel="noopener noreferrer">
                    {sharedService.minifyTxHash(transaction.receipt.transactionHash)}
                  </a>
                </Text>
              }
            </Col>
          </React.Fragment>
        )}

      </Row>
    </Modal>
  );
}