import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Button, Table, Spin, Result } from 'antd';
import { FiledNotice, SymbolDetailsAndSTData } from '../../../Shared/interfaces';
import { useHistory } from 'react-router-dom';
import { IssuerCounselService } from '../../IssuerCounsel.service';
import { AuthService } from '../../../Shared/Auth.service';
import { MetamaskService } from '../../../Shared/Metamask.service';
import { SecurityTokenRegistryService } from '../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { TokenConfigurationService } from '../../../TokenConfigurations/TokenConfiguration.service';

// import { SecurityTokenService } from '../../../Shared/SecurityToken/SecurityToken.service';
import WhitelistFacet from '../../../Shared/SecurityToken/Facets/WhitelistFacet';

const {Title} = Typography;
const issuerCounselService = new IssuerCounselService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();

// const securityTokenService = new SecurityTokenService();
const whitelistFacet = new WhitelistFacet();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default function FiledNoticesWithSEC() {
  const history = useHistory();
  const {userInfo} = useUserContext();
  const {selectedWallet} = useSelectedWalletContext();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();

  const [filedNotices, setFiledNotices] = useState<FiledNotice[]>();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    (async () => {
      if(!userInfo || !selectedWallet) return;

      let _tokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);


      const response = await issuerCounselService.getFiledNoticesWithoutApproval(_symbolDetailsAndSTData.securityTokenData.contractAddress);
      let _filedNotices = [] as FiledNotice[];

      for(let filedNotice of response.filedNotices as FiledNotice[]) {

        // const whitelistedWallet = await securityTokenService.getWhitelistedWallet(_symbolDetailsAndSTData.securityTokenData.contractAddress, filedNotice.wallet);
        const whitelistedWallet = await whitelistFacet.getWhitelistedWallet(_symbolDetailsAndSTData.securityTokenData.contractAddress, filedNotice.wallet);
  
        const filedNoticeExists = filedNotice && whitelistedWallet && filedNotice.creationTS > +(whitelistedWallet.dateFiledNoticeRemoved)*1000;
      
        if(filedNoticeExists) _filedNotices.push(filedNotice);
      }

      console.log(_filedNotices);

      setFiledNotices(_filedNotices);
      setLoading(false);

    })();
  }, [userInfo, selectedWallet]);

  
  const columns = [

    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
    },
    // {
    //   title: 'Noticed File',
    //   dataIndex: 'noticeFile',
    //   render: (value: FiledNotice['noticeFile'], record) => 
    //   <a target="_blank" rel="noopener noreferrer" href={value.URL} download={value.name} >
    //     {value.name}
    //   </a> 
    // },
    {
      title: 'Details',
      render: (value, record) => {
        return (
          <>
            <Button type='link' onClick={() => {
              history.push(`/issuer-counsel/review-affiliate-form-144/${record._id}`)
            }}>
              View Details
            </Button>
          </>
        );
      }
    }
  ];


  return (
    <>
      <br/><br/>
      <Row justify="center">
      <Col span={20}>
        {/* {loading? 'TRUE' : 'FALSE'} */}
        {loading && 
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }
        {!loading && 
          <Card>
            <Title level={1} style={{textAlign:'center'}}>Review Affiliate Form 144</Title>

            {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
              <Result
                title={`Security Token not deployed`}
              />
            }

            {symbolDetailsAndSTData?.symbolDetails.isDeployed && 
              <Table
                columns={columns} 
                dataSource={filedNotices}
                rowKey='_id'
                pagination={false}
              />
            }
          </Card>
        }
      </Col>
      </Row>
    </>
  );
}