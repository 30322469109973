import React from 'react';
import { Result, Typography } from 'antd';
import { SharedService } from '../Shared.service';
import { MetamaskService } from '../Metamask.service';

const sharedService = new SharedService();

const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

const { Title } = Typography;

export default function (prop: { address: string }) {
  const { networkId } = useSelectedWalletContext();

  return (
    <>
      <Title level={2} style={{ textAlign: 'center' }}>
        Wrong selected wallet on metamask
      </Title>
      <Result
        status="error"
        title={
          <p>
            Select the wallet{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${
                sharedService.etherscanURL[networkId as string]
              }/address/${prop.address}`}
            >
              {sharedService.minifyAddress(prop.address.toLowerCase())}
            </a>{' '}
            in order to proceed
          </p>
        }
      />
    </>
  );
}
