import React, { useEffect,useState } from 'react'
import {Table,Card,Col,Row,Typography, Descriptions,Button,Tabs} from 'antd'
import {PlatformIssuerAdminService} from '../PlatformIssuerAdmin.service'
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import moment from 'moment'
const {Title}=Typography
const {TabPane}=Tabs;
const platformIssuerAdminService=new PlatformIssuerAdminService()
export default ()=>{
  const [investors,setInvestors]=useState()
  const [detailsDivision,setDetailsDivision]=useState(true)
  const [investorDetails,setInvestorDetails]=useState(null)
  const [submitting,setSubmitting]=useState(false)
  const [approvedAndRejectedInvestors,setInvestorList]=useState([])
  const columns=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'PHONE',
      dataIndex:'contactNumber',
      key:'contactNumber'
    },
    {
      title:'CREATED ON',
      dataIndex:'creationTS',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'ACTION',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=><Button type='primary' onClick={()=>showDetails(investor)}>DETAILS</Button>
    }

  ]
  const columns1=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'PHONE',
      dataIndex:'contactNumber',
      key:'contactNumber'
    },
    {
      title:'CREATED ON',
      dataIndex:'creationTS',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'Status',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=>{
        return (
        <div>
        {investor.verified && <span>Approved</span>}
        {investor.rejected && <span>Rejected</span>}
        </div>
        )
      }
    }

  ]
  const showDetails=investor=>{
    setDetailsDivision(false)
    setInvestorDetails(investor)
  }
  const verifyUser=(details)=>{
    (async ()=>{
      setSubmitting(true)
      details.verified=true
      const response=await platformIssuerAdminService.verifyInvestor(details);
      if(response.success)
      {
        setDetailsDivision(true);
      }
      setSubmitting(false)
    })();
  }
  
  useEffect(
    ()=>{
      (async ()=>{
        const response=await platformIssuerAdminService.getInvestorRegistratioRequests();
        setInvestors(response.data)
      }
        
        )();
    },[detailsDivision]
  )

  useEffect(
    ()=>{
      (async ()=>{
        const response=await platformIssuerAdminService.getInvestorsList();
        setInvestorList(response.data)
      }
        
        )();
    },[approvedAndRejectedInvestors]
  )


  return(
  <>
   <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Investors Registration Request
          </Title>
          <Tabs defaultActiveKey="request" centered>
            <TabPane tab={<span><PlaySquareOutlined/>Pending Registration Requests</span>} key="request">
          <div hidden={!detailsDivision}>
          <Table dataSource={investors} columns={columns} />
          </div>
          <div hidden={detailsDivision}>
            <Descriptions title='Investor Details' bordered>
          <Descriptions.Item label="First Name">{investorDetails?.firstName}</Descriptions.Item>
              <Descriptions.Item label="Last Name">{investorDetails?.lastName}</Descriptions.Item>
              <Descriptions.Item label="Date of registration">{moment(investorDetails?.creationTs).format('LLL')}</Descriptions.Item>
              <Descriptions.Item label="Country">{investorDetails?.country}</Descriptions.Item>
              <Descriptions.Item label="State">{investorDetails?.state}</Descriptions.Item>
              <Descriptions.Item label="Contact Number">{investorDetails?.contactNumber}</Descriptions.Item>
              <Descriptions.Item label="Wallet Address">{investorDetails?.walletAddress}</Descriptions.Item>
            </Descriptions>
            <br/>
            <Button onClick={()=>verifyUser(investorDetails)} type='primary' loading={submitting} >Approve</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={()=>setDetailsDivision(true)} disabled={submitting}>Reject</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={()=>setDetailsDivision(true)} disabled={submitting}>Back</Button>
          </div>
          </TabPane>
          <TabPane tab={<span><ProjectOutlined/> Approved/Rejected Registration Request</span>}>
            <Table columns={columns1} dataSource={approvedAndRejectedInvestors} ></Table>
          </TabPane>
          </Tabs>
          
          
          </Card>
      </Col>
     </Row>
  </>
  )
}