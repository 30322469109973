import React, {Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
 
class ExportToExcel extends Component {
 
    constructor(props) {
        super(props);
    }
 
    render() {
        const styler={float:"right",color:'#fdfcff',background:'#120866'};
        return (
            <div>
            <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="styler"
                    table="table-to-xls"
                    filename={this.props.fileName}
                    sheet="Investor Details"
                    buttonText="Export"/>
                <table id="table-to-xls" hidden={true}>
                  <tr>
                    <td>FIRST NAME</td>
                    <td>LAST NAME</td>
                    <td>COUNTRY</td>
                    <td>TOKEN TYPE</td>
                    <td>REGULATION</td>
                    <td>TOKEN ISSUED</td>
                    <td>DATE OF INVESTMENT</td>
                    <td>WALLET ADDRESS</td>
                    <td>DATE OF ISSUE</td>
                    <td>LOCK OPEN DATE</td>
                  </tr>
                    {
                        this.props.data.map((data,index)=>{
                            return(

                                <tr key={index}>
                                <td>{data.firstName}</td>
                                <td>{data.lastName}</td>
                                <td>{data.country}</td>
                                <td>{data.tokenType}</td>
                                <td>{data.regulation}</td>
                                <td>{data.tokenIssued}</td>
                                <td>{data.dateOfInvestment}</td>
                                <td>{data.walletAddress}</td>
                                <td>{data.dateOfIssue}</td>
                                <td>{data.lockOpenDate}</td>
                                </tr>
                                );
                        })
                    }
                </table>
 
            </div>
        );
    }
}
 
export default ExportToExcel