import React,{useState,useEffect} from 'react';
import {Result,Button,Card,Col,Row,Typography,Divider} from 'antd'
import {InvestorService} from '../Investor.service'
import { useHistory } from 'react-router-dom';
import {AuthService} from '../../Shared/Auth.service'
import Process from '../Process';
const {Title}=Typography;
const authService=new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService=new InvestorService();
export default ()=>{
    const history=useHistory();
    const {userInfo,setUserInfo}=useUserContext();
    const [currentStatus,setCurrentStatus]=useState(null);
    useEffect(()=>{
        (async ()=>{
            const response=await investorService.getAiDetails({investorId:userInfo._id});
            if(response.success && response.data)
            {
                if(response.data.length<=0)
                {
                    setCurrentStatus('notSubmitted')
                }
                else
                {
                    setCurrentStatus('submitted')
                    if(response.data['0'].verified)
                    {   
                        setCurrentStatus('approved')
                    }
                    if(response.data['0'].rejected)
                    {
                        setCurrentStatus('rejected')
                    }
                }
            }
        })()
    },[])
    return(
        <>
        <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
            <Process />
            <Divider />
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Accredited Investor Verification Status
          </Title> 
        <div hidden={currentStatus!=='approved'}>
        <Result 
         status="success"
         title="Successfully verified!"
         subTitle="Your Accredited Investor verification documents are verified successfully."
         extra={[
             <span style={{color:'green'}}><b>*Note:</b>Your verification will be valid for current financial year or 6 month whichever is earlier.</span>,<br/>,<br/>,
             <Button type="primary" onClick={()=>history.push('/investor/home')}>Home</Button>
         ]}
        >
        </Result>
        </div>
        <div hidden={currentStatus!=='rejected'}>
        <Result
         status="error"
         title="Rejected!"
         subTitle="your Accredited Investor Verification documents are rejected."
         extras={[
             <Button type="primary" onClick={()=>history.push('/investor/home')}>Home</Button>
         ]}
        >
        </Result>
        </div>
        <div hidden={currentStatus!=='submitted'}>
        <Result
         title="Successfully Submitted!"
         subTitle="your Accredited Investor Verification documents are successfully submitted please wait for the result."
         extra={[
             <Button type="primary" onClick={()=>history.push('/investor/home')}>Home</Button>
         ]}
        >
        </Result>
        
        </div>
        <div hidden={currentStatus!=='notSubmitted'}>
        <Result
         status="warning"
         title="Not Submitted!"
         subTitle="you have not submitted Accredited Investor Verification documents."
         extra={[
             <Button type="primary" onClick={()=>history.push('/investor/home')}>Home</Button>
         ]}
        >
        </Result>
        </div>
        </Card>
        </Col>
        </Row>
        </>
    )
}