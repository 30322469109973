import React, {useState, useEffect} from 'react';
import { AccountService } from '../Account.service';
import { Card, Row, Col, Result, Button, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';



const accountService = new AccountService();


export default () => {
  const history = useHistory();
  const { code } = useParams<{code: string}>();

  const [verifying, setVerifying] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    (async () => {
      const response = await accountService.verifyEmail({code});
      if (!response.success) setErrorMessage(response.error.message || 'A ocurrido un Error');
      
      setVerifying(false);
    })();
  }, [code]);


    return (
      <Row justify="center" style={{marginTop:"60px"}}>
        <Col span={16}>
          <Card>
            {verifying && 
            <div style={{textAlign:'center'}}>
              <Spin className="spin-verif" size='large' /> 
            </div>
            }
            {!verifying && 
              <Result
                status={!errorMessage? 'success' : 'error'}
                title={!errorMessage? 'Email verified successfully' : errorMessage}
                // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                extra={[
                  <div key="console">
                    {!errorMessage && 
                      <Button type="primary" size='large' onClick={() => history.push('/account/login')}>
                        Click here to login
                      </Button>
                    }
                    {errorMessage && 
                      <Button size='large' onClick={() => history.push('/account/resend-email-verification-code')}>
                        Resend email verification link
                      </Button>
                    }
                  </div>
                ]}
              />
            }
          </Card>
        </Col>
      </Row>
    )
}