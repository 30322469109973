import React from 'react';
// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import VestingSchedule from './VestingSchedule';
import FileNotice from './FileNotice';
import KYCUpload from './KYCUpload'

export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/vesting-schedule`}>
        <VestingSchedule/>
      </Route>
      <Route path={`${path}/file-notice-with-sec`}>
        <FileNotice/>
      </Route>
      <Route path={`${path}/KYCUpload`}>
        <KYCUpload/>
      </Route>
    </Switch>
  );
}