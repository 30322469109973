import React, { useState, useEffect } from 'react';
import {
  TokenConfigurationProcess,
  VestingScheduleTemplate,
  SymbolDetailsAndSTData,
} from '../../../../Shared/interfaces';
import { AuthService } from '../../../../Shared/Auth.service';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Spin, Card, Typography, Table, Result } from 'antd';
import { SecurityTokenRegistryService } from '../../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { MetamaskService } from '../../../../Shared/Metamask.service';
import { SharedService } from '../../../../Shared/Shared.service';
import { TokenConfigurationService } from '../../../../TokenConfigurations/TokenConfiguration.service';

import VestingFacet from '../../../../Shared/SecurityToken/Facets/VestingFacet/index';
import WrongMetamaskWalletWarning from '../../../../Shared/WrongMetamaskWalletWarning';

const { Title } = Typography;
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const vestingFacet = new VestingFacet();

const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () =>
  new MetamaskService().useSelectedWalletContext();

export default () => {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();

  const history = useHistory();

  const [vestingScheduleTemplates, setVestingScheduleTemplates] =
    useState<VestingScheduleTemplate[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] =
    useState<SymbolDetailsAndSTData>();

  useEffect(() => {
    (async () => {
      if (!userInfo) return;

      const _tokenConfigurationProcess: TokenConfigurationProcess = (
        await tokenConfigurationService.getLastTokenConfigurationProcess()
      ).data;

      if (!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const [_symbolDetailsAndSTData] = await Promise.all([
        securityTokenRegisteryService.getSymbolDetailsAndSTData(
          _tokenConfigurationProcess.tokenSymbol
        ),
      ]);

      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if (!_symbolDetailsAndSTData?.symbolDetails.isDeployed)
        return setLoading(false);

      const _vestingScheduleTemplates =
        await vestingFacet.getVestingScheduleTemplates(
          _symbolDetailsAndSTData.securityTokenData.contractAddress
        );

      // _vestingScheduleTemplates.forEach(template => template['key'] = template.index);

      setLoading(false);
      setVestingScheduleTemplates(_vestingScheduleTemplates);

      if (!_vestingScheduleTemplates.length)
        history.push(
          `/issuer-token-admin/token-managment/create-vesting-schedule/new`
        );
    })();
  }, [userInfo, history]);

  const colums = [
    {
      title: 'Vesting',
      dataIndex: 'name',
      render: (value: string) => sharedService.bytes32ToString(value),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      render: (value: number) => `${value} months`,
    },
    {
      title: 'Total Schedules',
      dataIndex: 'totalSchedules',
      render: (value, record: VestingScheduleTemplate) => record.vesting.length,
    },
    {
      title: 'Details',
      render: (value, record: VestingScheduleTemplate) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                history.push(
                  `/issuer-token-admin/token-managment/create-vesting-schedule/details/${record.index}`
                );
              }}
            >
              View
            </Button>
            <Button
              type="link"
              onClick={() => {
                history.push(
                  `/issuer-token-admin/token-managment/create-vesting-schedule/edit/${record.index}`
                );
              }}
            >
              Edit
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          {loading && (
            <div style={{ textAlign: 'center' }}>
              <br />
              <Spin size="large" />
            </div>
          )}

          {!loading && (
            <Card>
              <Title level={1} style={{ textAlign: 'center' }}>
                List of Vesting Schedules
              </Title>
              {!symbolDetailsAndSTData?.symbolDetails.isDeployed && (
                <Result
                  title={`Security Token not deployed`}
                  subTitle="You have to deploy your Security Token first"
                  extra={
                    <Button
                      key="console"
                      onClick={() => history.push('/token-configurations')}
                    >
                      Create Token
                    </Button>
                  }
                />
              )}

              {symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                selectedWallet?.toLowerCase() !==
                  symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && (
                  <WrongMetamaskWalletWarning
                    address={symbolDetailsAndSTData.symbolDetails.owner}
                  />
                )}

              {symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                selectedWallet?.toLowerCase() ===
                  symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && (
                  <>
                    <Table
                      columns={colums}
                      dataSource={vestingScheduleTemplates}
                      pagination={false}
                      rowKey="index"
                    />
                    <div style={{ textAlign: 'right' }}>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        onClick={() =>
                          history.push(
                            `/issuer-token-admin/token-managment/create-vesting-schedule/new`
                          )
                        }
                      >
                        NEW VESTING SCHEDULE
                      </Button>
                    </div>
                  </>
                )}
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};
