import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PendingWhitelistedWallets from './PendingWhitelistedWallets';
import WhitelistedWallets from './WhitelistedWallets';


export default () => {
    const { path } = useRouteMatch();
  
    return (
      <Switch>
        <Route path={`${path}/pending-whitelisted-wallets`}>
          <PendingWhitelistedWallets/>
        </Route>
        <Route path={`${path}/whitelisted-wallets`}>
          <WhitelistedWallets/>
        </Route>
      </Switch>
    );
  }