import { MetamaskService } from '../../../Metamask.service';


export default class ERC20Facet {
  contract = {
    ABI: require('./ABI.json'),
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async name(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.name().call()) as string;
  }

  async symbol(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.symbol().call()) as string;
  }

  async decimals(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.decimals().call()) as string;
  }

  async totalSupply(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.totalSupply().call()) as string;
  }

  async balanceOf(tokenAddress: string, account: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.balanceOf(account).call()) as string;
  }

  async transfer(tokenAddress: string, owner: string, to: string, value: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return contractInstance.methods
      .transfer(to, value)
      .send({ from: owner });
  }

}