import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse, TokenPurchaseTrx } from '../Shared/interfaces';
// import { SecurityTokenService } from '../Shared/SecurityToken/SecurityToken.service';
import { SharedService } from '../Shared/Shared.service';

const sharedService = new SharedService();
// const securityTokenService = new SecurityTokenService();

export class IssuerTokenAdminService {

  private APIURL = environment.APIURL;
  async getQuestions()
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/getQuestions`,{headers:await sharedService.getAuthHeader()});
  }
  async setAnswer(data:any)
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/setAnswer`,{params:data,headers:await sharedService.getAuthHeader()});
  }
  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);

    sharedService.uploadDocument(`${this.APIURL}/issuerTokenAdmin/uploadDocument`, req, progressCb, errorCb, completeCb)
  }
  async getAIVerificationRequest(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/getAIVerificationRequest`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getInvestorsList(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/getInvestorsList`,data,{headers:await sharedService.getAuthHeader()});
  }
  async acceptAI(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/acceptAI`,data,{headers:await sharedService.getAuthHeader()});
  }
  async rejectAI(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/rejectAI`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getPaymentDetails(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/getPaymentDetails`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getPaymentStatus(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/getPaymentStatus`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getCompletedPaymentDetails(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/getCompletedPaymentDetails`,data,{headers:await sharedService.getAuthHeader()});
  }
  async acceptPayment(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/acceptPayment`,data,{headers:await sharedService.getAuthHeader()});
  }
  async rejectPayment(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/rejectPayment`,data,{headers:await sharedService.getAuthHeader()});
  }

  async getTokensToIssue(){
    return axios.get<any, APIResponse>(`${this.APIURL}/issuerTokenAdmin/getTokensToIssue`, {headers: await sharedService.getAuthHeader()});
  }

  async markTokensAsIssued(req: {investorPaymentDetailsIds: string[]}){
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerTokenAdmin/markTokensAsIssued`, req, {headers:await sharedService.getAuthHeader()});
  }

  async getUsersByWallet(addresses: string[]){
    // const addresses = !_addresses.length? [''] : _addresses;
    return axios.get<any, APIResponse>(`${this.APIURL}/issuerTokenAdmin/getUsersByWallet`, {params: {addresses: addresses.map(address => address.toLowerCase())}, headers: await sharedService.getAuthHeader()});
  }


  async getETHTokensToIssue(tokenAddress: string) {
    const tokenPurchaseTrxs = [] as any[];
    // const tokenPurchaseTrxs = (await securityTokenService.getTokensToIssue(tokenAddress)).map(tx => ({
    //   ETHAmount: tx.ETHAmount,
    //   amount: tx.amount,
    //   creationDate: tx.creationDate,
    //   index: tx.index,
    //   investorIndex: tx.investorIndex,
    //   issued: tx.issued,
    //   wallet: tx.wallet,
    // }));

    
  
    // const response = await this.getUsersByWallet(tokenPurchaseTrxs.map(tx => tx.wallet.toLowerCase()));

    // tokenPurchaseTrxs.forEach(tx => {
    //   const userFound = (response.data as any[]).find(user => user.searchAbleWallet === tx.wallet.toLowerCase());
      
    //   tx['firstName'] = userFound.firstName;
    //   tx['searchAbleWallet'] = userFound.searchAbleWallet;
    //   tx['_id'] = userFound._id;
    //   tx['lastName'] = userFound.lastName;
    //   tx['email'] = userFound.email;
    //   tx['creationTS'] = userFound.creationTS;
    // });

    return tokenPurchaseTrxs as TokenPurchaseTrx[];
  }


  async getAllowedCountries(data: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerTokenAdmin/getAllowedCountries`, data , { headers: await sharedService.getAuthHeader() });
  }

  async setAllowedCountries(data:any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerTokenAdmin/setAllowedCountries`, data , { headers: await sharedService.getAuthHeader() });
  }
  
}
