import { SharedService } from '../Shared/Shared.service';
import { APIResponse} from '../Shared/interfaces';
import { environment } from '../../environments/environment';
import axios from 'axios';


const sharedService = new SharedService();


export class TeamMemberService {
  private APIURL = environment.APIURL;
  
  public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);

    sharedService.uploadDocument(`${this.APIURL}/teamMember/uploadDocument`, req, progressCb, errorCb, completeCb)
  }

  // async getTokenConfigurationProcess(){
  //   return axios.get<any, APIResponse>(`${this.APIURL}/teamMember/getTokenConfigurationProcess`, {headers: await sharedService.getAuthHeader()});    
  // }

  async submitKYCDetails(formData:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/teamMember/submitKYCDetails`,formData,{headers:await sharedService.getAuthHeader()});
  }
  
  async getKYCDetails(investorId:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/teamMember/getKYCDetails`,investorId,{headers:await sharedService.getAuthHeader()});
  }
  
  async generateLinkForKYC(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/teamMember/generateLinkForKYC`,data,{headers:await sharedService.getAuthHeader()});
  }

  async fileNotice(req: {
    referenceNumber: string,
    noticeFile: {
      name: string;
      URL: string;
      type: string;
    }
  }) {
    return axios.post<any, APIResponse>(`${this.APIURL}/teamMember/fileNotice`, req, {headers: await sharedService.getAuthHeader()});
  }

  async getFiledNotice(){
    return axios.get<any, APIResponse>(`${this.APIURL}/teamMember/getFiledNotice`, {headers: await sharedService.getAuthHeader()});    
  }


}