import React, { useEffect, useState } from "react";
import { Result, Button, Descriptions, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import CardHOC from "../../CardHOC";
import { InvestorService } from "../Investor.service";
import { AuthService } from "../../Shared/Auth.service";
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();

const PaymentSuccess = () => {
  const [paymentData, setPaymentData] = useState(null);
  const history = useHistory();
  const { userInfo } = useUserContext();
  const { paymentId } = useParams();
  const completePayment = async (data) => {
    const dataToSend = {
      agent: "coinbase",
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      email: userInfo?.email,
      internalTransactionId: paymentId,
      coinbaseTransactionId: data?.id,
      coinbaseCode: data?.code,
      amount: data?.price?.local?.amount + data?.price?.local?.currency,
      amountToInvest: Number(data?.pricing?.local?.amount),
      modeOfFundTransfer: "cryptocurrency",
      investorId: userInfo._id,
      phoneNumber: userInfo?.contactNumber,
      accept: false,
      reject: false,
    };
    console.log("Complete Payment", dataToSend);
    const response = await investorService.completePayment(dataToSend);
    if (!response.success) {
      setPaymentData(-1);
    }
  };
  useEffect(() => {
    (async () => {
      const response = await investorService.getCoinbasePaymentDetails({
        paymentId,
      });
      if (response.success && response.data) {
        console.log("Data", response.data);
        setPaymentData(response.data);
        await completePayment(response.data);
      } else {
        message.error("Something went wrong. Please try again");
        setPaymentData(-1);
      }
    })();
  }, []);
  return (
    <>
      {paymentData && paymentData !== -1 && (
        <Result
          loading={!paymentData}
          status="success"
          title="Payment Completed"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/investor/home")}
            >
              Home
            </Button>,
          ]}
        >
          <div>
            <Descriptions
              title="Payment Details"
              extra={[<Button>Download Reciept</Button>]}
              bordered
            >
              <Descriptions.Item label="First Name">
                {userInfo?.firstName}
              </Descriptions.Item>
              <Descriptions.Item label="Last Name">
                {userInfo?.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Mode of payment">
                Crypto Currency
              </Descriptions.Item>
              <Descriptions.Item label="Agent">Coinbase</Descriptions.Item>
              <Descriptions.Item label="Transaction ID">
                {paymentId}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">{`${paymentData?.pricing?.local?.amount} ${paymentData?.pricing?.local?.currency}`}</Descriptions.Item>
            </Descriptions>
          </div>
        </Result>
      )}
      {paymentData === -1 && (
        <Result
          loading={!paymentData}
          status="error"
          title="Invalid Payment"
          subTitle="you are reached here because your payment is invalid."
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/investor/home")}
            >
              Home
            </Button>,
          ]}
        ></Result>
      )}
    </>
  );
};

export default () => {
  return <CardHOC heading="Payment Completed" component={<PaymentSuccess />} />;
};
