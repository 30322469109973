import React, { useEffect, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {Row, Col, Card, Form, Input, Button, message, Result, Select} from 'antd';
import { AccountService } from '../../Account.service';
import queryString from 'query-string';
import { MetamaskService } from '../../../Shared/Metamask.service';
import { SharedService } from '../../../Shared/Shared.service';
import ConnectToMetamask from '../../../ConnectToMetamask';
import {Country} from "../../../Shared/interfaces";

const { Option } = Select;

const accountService = new AccountService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};
const metamaskService = new MetamaskService();
const sharedService = new SharedService();

const useSelectedWallet = (userId: string) => metamaskService.useSelectedWallet(userId);


export default () => {
  const history = useHistory();
  const location = useLocation();
  const {code} = queryString.parse(location.search);
  const [form] = Form.useForm();
  
  const [submitting, setSubmitting] = useState(false);
  const [screenState, setScreenState] = useState<'register' | 'success'>('register');

  const [countries, setCountries] = useState<Country[]>();

  const [selectedWallet, networkId, isMetamaskAuthenticated, setSelectedWallet, setNetworkId, setIsMetamaskAuthenticated] = useSelectedWallet('REGISTRATION');
  const isNotConnectedToMetamask = (!metamaskService.isMetamaskInstalled || isMetamaskAuthenticated===false || (networkId && !sharedService.allowedNetworks.includes(networkId)));

  useEffect(() => {
    (async ()=>{
      const response = await accountService.getTeamMemberDetails({ code });
      if (response.success) {
        form.setFieldsValue({
          email: response.data.email
        })
      } else {
        message.error("Unable to fetch details !");
      }
    })();
    
  },[]);

  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      setCountries(response.data);

    })();
  }, []);

  useEffect(() => {
    form.setFieldsValue({wallet: selectedWallet});
  }, [selectedWallet, form]);

  
  const register = async (formValue) => {
    const req = {...formValue, code};

    setSubmitting(true);

    const response = await accountService.registerTeamMember(req);
    console.log(response);

    if (response.success) {
      setScreenState('success');
    } else {
      message.error(response.error.message);
    }

    setSubmitting(false);
  }


  return (
    <>
    {isNotConnectedToMetamask && 
      <ConnectToMetamask
        isMetamaskInstalled={metamaskService.isMetamaskInstalled}
        isMetamaskAuthenticated={isMetamaskAuthenticated}
        setSelectedWallet={setSelectedWallet}
        setNetworkId={setNetworkId}
        setIsMetamaskAuthenticated={setIsMetamaskAuthenticated}
      />
    }


    
    {!isNotConnectedToMetamask && 
      <Row justify="center" style={{margin:"60px 0"}}>
        <Col span={16} style={{textAlign:'center'}}>
          {screenState === 'register' && 
            <Card>
              <h2 style={{textAlign:'center'}}>Sign up!</h2>
              <Form form={form}
              {...formItemLayout}
              onFinish={register}
              style={{ margin: '2% 10% 0 10%', width: '60%' }}>
                <Form.Item 
                  label='First name'
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your First Name!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>
          
                <Form.Item 
                  label='Last name'
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Last Name!',
                      whitespace: true
                    }
                  ]}>
                  <Input />
                </Form.Item>
              <Form.Item
                label='Email'
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'if it is not autofilled please refersh the page!'
                  }
                ]}>
                <Input style={{color: 'green'}} type="email" disabled/>
              </Form.Item>

              <Form.Item
                label="Country"
                name="country"
                validateStatus={countries ? "" : "validating"}
                //hasFeedback
                help={countries ? "" : "Loading Countries..."}
                rules={[
                  {
                    required: true,
                    message: "Select Country",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Country"
                >
                  {countries?.map((country) => (
                    <Option key={country._id} value={country.name["en"]}>
                      {country.name["en"]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
  
                <Form.Item 
                  label='Password'
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!'
                    },
                    {
                      validator: (rule, value) => {
                        const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                        if(strongRegex.test(value)) return Promise.resolve();
                        return Promise.reject('The password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long');
                      }
                    }
                  ]}>
                  <Input.Password />
                </Form.Item>
          
                <Form.Item 
                  label='Confirm Password'
                  name="confirm"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!'
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) return Promise.resolve();
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                  ]}>
                  <Input.Password />
                </Form.Item>
  
                <Form.Item 
                  label='Ethereum Wallet'
                  name="wallet"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the wallet!',
                      whitespace: true
                    }
                  ]}>
                  <Input disabled style={{color:'blue', fontWeight: 'bold'}}/>
                </Form.Item>
  
                <Form.Item {...tailFormItemLayout}>
                  <Button type='primary' htmlType='submit' loading={submitting}>Register</Button>
                  <br />
                  <>Or </><Link to='/account/login'>login now!</Link>
                </Form.Item>
              </Form>
            </Card>
          
          }
  
          {screenState === 'success' && 
            <Card>
              <h2 style={{textAlign:'center'}}>Account created</h2>
              <Result
                title={`Your account has been created successfully`}
                extra={
                  <Button key="console" onClick={() => history.push('/account/login')}>
                    Click here to login
                  </Button>
                }/>
            </Card>
          }
  
        </Col>
      </Row>

    }
    </>
 );
}