// import external modules
import {
  faAccessibleIcon,
  faAccusoft,
  faAdn,
  //faAdobe,
  faAdversal,
  faAffiliatetheme,
  faAlgolia,
  faAlipay,
  faAmazon,
  faAmazonPay,
  faAmilia,
  faAndroid,
  faAngellist,
  faAngrycreative,
  faAngular,
  faAppStore,
  faAppStoreIos,
  faApper,
  faApple,
  faApplePay,
  faArtstation,
  faAsymmetrik,
  faAtlassian,
  faAudible,
  faAutoprefixer,
  faAvianex,
  faAviato,
  faAws,
  faBandcamp,
  faBattleNet,
  faBehance,
  faBehanceSquare,
  faBimobject,
  faBitbucket,
  faBitcoin,
  faBity,
  faBlackTie,
  faBlogger,
  faBloggerB,
  faBluetooth,
  faBluetoothB,
  faBootstrap,
  faBtc,
  faBuffer,
  faBuromobelexperte,
  faBuysellads,
  faCanadianMapleLeaf,
  faCcAmazonPay,
  faCcAmex,
  faCcApplePay,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
  faCcVisa,
  faCentercode,
  faCentos,
  faChrome,
  faChromecast,
  faCloudscale,
  faCloudsmith,
  faCloudversify,
  faCodepen,
  faCodiepie,
  faConfluence,
  faConnectdevelop,
  faContao,
  faCpanel,
  faCreativeCommons,
  faCreativeCommonsBy,
  faCreativeCommonsNc,
  faCreativeCommonsNcEu,
  faCreativeCommonsNcJp,
  faCreativeCommonsNd,
  faCreativeCommonsPd,
  faCreativeCommonsPdAlt,
  faCreativeCommonsRemix,
  faCreativeCommonsSa,
  faCreativeCommonsSampling,
  faCreativeCommonsSamplingPlus,
  faCreativeCommonsShare,
  faCreativeCommonsZero,
  faCriticalRole,
  faCss3,
  faCss3Alt,
  faDAndD,
  faCuttlefish,
  faDAndDBeyond,
  faDashcube,
  faDelicious,
  faDeploydog,
  faDeskpro,
  faDev,
  faDeviantart,
  faDhl,
  faDiaspora,
  faDigg,
  faDigitalOcean,
  faDiscord,
  faDiscourse,
  faDochub,
  faDocker,
  faDraft2digital,
  faDribbble,
  faDribbbleSquare,
  faDropbox,
  faDrupal,
  faDyalog,
  faEarlybirds,
  faEbay,
  faEdge,
  faElementor,
  faEllo,
  faEmber,
  faEmpire,
  faEnvira,
  faErlang,
  faEthereum,
  faEtsy,
  faEvernote,
  faExpeditedssl,
  faFacebook,
  faFacebookF,
  faFacebookMessenger,
  faFacebookSquare,
  faFantasyFlightGames,
  faFedex,
  faFedora,
  faFigma,
  faFirefox,
  faFirstOrder,
  faFirstOrderAlt,
  faFirstdraft,
  faFlickr,
  faFlipboard,
  faFly,
  faFontAwesome,
  faFontAwesomeAlt,
  faFontAwesomeFlag,
  faFonticons,
  faFonticonsFi,
  faFortAwesome,
  faFortAwesomeAlt,
  faForumbee,
  faFoursquare,
  faFreeCodeCamp,
  faFreebsd,
  faFulcrum,
  faGalacticRepublic,
  faGalacticSenate,
  faGetPocket,
  faGg,
  faGgCircle,
  faGit,
  faGitAlt,
  faGitSquare,
  faGithub,
  faGithubAlt,
  faGithubSquare,
  faGitkraken,
  faGitlab,
  faGitter,
  faGlide,
  faGlideG,
  faGofore,
  faGoodreads,
  faGoodreadsG,
  faGoogle,
  faGoogleDrive,
  faGooglePlay,
  faGooglePlus,
  faGooglePlusG,
  faGooglePlusSquare,
  faGoogleWallet,
  faGratipay,
  faGrav,
  faGripfire,
  faGrunt,
  faGulp,
  faHackerNews,
  faHackerNewsSquare,
  faHackerrank,
  faHips,
  faHireAHelper,
  faHornbill,
  faHooli,
  faHotjar,
  faHouzz,
  faHtml5,
  faHubspot,
  faImdb,
  faInstagram,
  faIntercom,
  faInternetExplorer,
  faInvision,
  faIoxhost,
  faItchIo,
  faItunes,
  faItunesNote,
  faJava,
  faJediOrder,
  faJenkins,
  faJira,
  faJoomla,
  faJoget,
  faJs,
  faJsSquare,
  faJsfiddle,
  faKaggle,
  faKeybase,
  faKeycdn,
  faKickstarter,
  faKickstarterK,
  faKorvue,
  faLaravel,
  faLastfm,
  faLastfmSquare
} from "@fortawesome/free-brands-svg-icons";
import {
  faAd,
  faAddressBook,
  faAddressCard,
  faAdjust,
  faAirFreshener,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAllergies,
  faAmbulance,
  faAmericanSignLanguageInterpreting,
  faAnchor,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAnkh,
  faAppleAlt,
  faArchive,
  faArchway,
  faArrowAltCircleLeft,
  faArrowCircleDown,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAlt,
  faArrowsAltH,
  faArrowsAltV,
  faAssistiveListeningSystems,
  faAsterisk,
  faAt,
  faAtlas,
  faAtom,
  faAudioDescription,
  faAward,
  faBaby,
  faBabyCarriage,
  faBackspace,
  faBackward,
  faBacon,
  faBalanceScale,
  faBan,
  faBandAid,
  faBars,
  faBasketballBall,
  faBath,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBed,
  faBeer,
  faBell,
  faBezierCurve,
  faBible,
  faBicycle,
  faBinoculars,
  faBiohazard,
  faBirthdayCake,
  faBlender,
  faBlenderPhone,
  faBlind,
  faBlog,
  faBold,
  faBolt,
  faBomb,
  faBone,
  faBong,
  faBook,
  faBookDead,
  faBookMedical,
  faBookOpen,
  faBookReader,
  faBookmark,
  faBox,
  faBowlingBall,
  faBoxOpen,
  faBoxes,
  faBraille,
  faBrain,
  faBreadSlice,
  faBriefcase,
  faBriefcaseMedical,
  faBroadcastTower,
  faBroom,
  faBrush,
  faBug,
  faBullhorn,
  faBullseye,
  faBurn,
  faBus,
  faBusinessTime,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarDay,
  faCalendarMinus,
  faCalendarWeek,
  faCamera,
  faCameraRetro,
  faCampground,
  faCandyCane,
  faCapsules,
  faCannabis,
  faCar,
  faCarAlt,
  faCarBattery,
  faCarSide,
  faCarCrash,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCarrot,
  faCartArrowDown,
  faCartPlus,
  faCashRegister,
  faCat,
  faCertificate,
  faChair,
  faChalkboard,
  faChalkboardTeacher,
  faChargingStation,
  faChartArea,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckDouble,
  faCheese,
  faChess,
  faChessBishop,
  faChessBoard,
  faChessKing,
  faChessKnight,
  faChessQueen,
  faChessPawn,
  faChessRook,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronCircleUp,
  faChevronLeft,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChild,
  faChurch,
  faCircleNotch,
  faCity,
  faClinicMedical,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faClosedCaptioning,
  faCloud,
  faCloudDownloadAlt,
  faCloudMeatball,
  faCloudSun,
  faCloudSunRain,
  faCloudUploadAlt,
  faCocktail,
  faCode,
  faCodeBranch,
  faCoffee,
  faCog,
  faCogs,
  faColumns,
  faCommentAlt,
  faCommentDollar,
  faCommentDots,
  faCommentSlash,
  faCommentMedical,
  faCommentsDollar,
  faCompactDisc,
  faCompass,
  faCompress,
  faCompressArrowsAlt,
  faConciergeBell,
  faCookieBite,
  faCookie,
  faCopyright,
  faCouch,
  faCrop,
  faCropAlt,
  faCrosshairs,
  faCross,
  faCrow,
  faCrown,
  faCrutch,
  faCube,
  faCubes,
  faCut,
  faDatabase,
  faDeaf,
  faDemocrat,
  faDesktop,
  faDharmachakra,
  faDiagnoses,
  faDiceD20,
  faDice,
  faDiceD6,
  faDiceFive,
  faDiceFour,
  faDiceOne,
  faDiceSix,
  faDiceTwo,
  faDiceThree,
  faDigitalTachograph,
  faDirections,
  faDivide,
  faDizzy,
  faDna,
  faDog,
  faDollarSign,
  faDolly,
  faDollyFlatbed,
  faDonate,
  faDoorClosed,
  faDoorOpen,
  faDove,
  faDownload,
  faDraftingCompass,
  faDragon,
  faDrawPolygon,
  faDrum,
  faDrumSteelpan,
  faDrumstickBite,
  faDumbbell,
  faDumpster,
  faDumpsterFire,
  faDungeon,
  faEject,
  faEgg,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpenText,
  faEnvelopeSquare,
  faEquals,
  faEraser,
  faEthernet,
  faEuroSign,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExpandArrowsAlt,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faEye,
  faEyeDropper,
  faEyeSlash,
  faFastBackward,
  faFastForward,
  faFax,
  faFeather,
  faFighterJet,
  faFemale,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileContract,
  faFileCsv,
  faFileDownload,
  faFileExport,
  faFileImport,
  faFileInvoiceDollar,
  faFileInvoice,
  faFileMedical,
  faFileMedicalAlt,
  faFilePdf,
  faFilePowerpoint,
  faFilePrescription,
  faFileSignature,
  faFileUpload,
  faFileWord,
  faFill,
  faFillDrip,
  faFilm,
  faFilter,
  faFingerprint,
  faFire,
  faFireAlt,
  faFireExtinguisher,
  faFirstAid,
  faFish,
  faFistRaised,
  faFlagCheckered,
  faFlag,
  faFlagUsa,
  faFlask,
  faFlushed,
  faFolderMinus,
  faFolderPlus,
  faFont,
  faFootballBall,
  faForward,
  faFrog,
  faFrown,
  faFunnelDollar,
  faFutbol,
  faGamepad,
  faGasPump,
  faGavel,
  faGenderless,
  faGem,
  faGhost,
  faGift,
  faGifts,
  faGlassCheers,
  faGlassMartini,
  faGlassMartiniAlt,
  faGlassWhiskey,
  faGlasses,
  faGlobe,
  faGlobeAfrica,
  faGlobeAmericas,
  faGlobeAsia,
  faGlobeEurope,
  faGolfBall,
  faGopuram,
  faGraduationCap,
  faGreaterThan,
  faGreaterThanEqual,
  faGrimace,
  faGrin,
  faGrinHearts,
  faGrinSquint,
  faGrinSquintTears,
  faGrinTears,
  faGrinTongueWink,
  faGripHorizontal,
  faGripLines,
  faGripLinesVertical,
  faGripVertical,
  faGuitar,
  faHSquare,
  faHamburger,
  faHamsa,
  faHammer,
  faHandHolding,
  faHandHoldingHeart,
  faHandHoldingUsd,
  faHandMiddleFinger,
  faHandPaper,
  faHandPointLeft,
  faHandPointRight,
  faHandPointUp,
  faHandScissors,
  faHandSpock,
  faHands,
  faHandsHelping,
  faHanukiah,
  faHardHat,
  faHashtag,
  faHatWizard,
  //faHaykal,
  faHeading,
  faHeadphones,
  faHeadphonesAlt,
  faHeadset,
  faHeartBroken,
  faHeartbeat,
  faHelicopter,
  faHighlighter,
  faHiking,
  faHippo,
  faHistory,
  faHockeyPuck,
  faHollyBerry,
  faHome,
  faHorse,
  faHorseHead,
  faHospital,
  faHospitalAlt,
  faHospitalSymbol,
  faHotTub,
  faHotel,
  faHotdog,
  faHourglassEnd,
  faHourglass,
  faHourglassHalf,
  faHourglassStart,
  faHouseDamage,
  faHryvnia,
  faICursor,
  faIceCream,
  faIcicles,
  faIdCard,
  faIdCardAlt,
  faIgloo,
  faInbox,
  faIndent,
  faIndustry,
  faInfinity,
  faInfo,
  faInfoCircle,
  faItalic,
  faJedi,
  faJoint,
  faJournalWhills,
  faKaaba,
  faKey,
  faKhanda,
  faKiwiBird,
  faLandmark,
  faLanguage,
  faLaptopCode,
  faLaptop,
  faLaptopMedical,
  faLaugh,
  faLaughBeam,
  faLayerGroup,
  faLeaf
} from "@fortawesome/free-solid-svg-icons";
import {
  faAngry,
  faArrowAltCircleDown,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faBellSlash,
  faBuilding,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarTimes,
  faCaretSquareDown,
  faCaretSquareLeft,
  faCaretSquareUp,
  faChartBar,
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faClipboard,
  faClone,
  faComment,
  faComments,
  faCopy,
  faCreditCard,
  faDotCircle,
  faEdit,
  faEnvelopeOpen,
  faFile,
  faFileExcel,
  faFileImage,
  faFileVideo,
  faFolder,
  faFolderOpen,
  faFrownOpen,
  faGrinBeam,
  faGrinAlt,
  faGrinBeamSweat,
  faGrinStars,
  faGrinTongue,
  faGrinTongueSquint,
  faGrinWink,
  faHandLizard,
  faHandPeace,
  faHandPointDown,
  faHandPointer,
  faHandRock,
  faHandshake,
  faHdd,
  faHeart,
  faIdBadge,
  faImage,
  faImages,
  faKeyboard,
  faKiss,
  faKissBeam,
  faKissWinkHeart,
  faLaughSquint,
  faLaughWink
} from "@fortawesome/free-regular-svg-icons";

const fontAwesomeIcon = [
  faAccessibleIcon,
  faAccusoft,
  faAd,
  faAddressBook,
  faAddressCard,
  faAdjust,
  faAdn,
  //faAdobe,
  faAdversal,
  faAffiliatetheme,
  faAirFreshener,
  faAlgolia,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAlipay,
  faAllergies,
  faAmazon,
  faAmazonPay,
  faAmbulance,
  faAmericanSignLanguageInterpreting,
  faAmilia,
  faAnchor,
  faAndroid,
  faAngellist,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngry,
  faAngrycreative,
  faAngular,
  faAnkh,
  faAppStore,
  faAppStoreIos,
  faApper,
  faApple,
  faAppleAlt,
  faApplePay,
  faArchive,
  faArchway,
  faArrowAltCircleDown,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faArrowCircleDown,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAlt,
  faArrowsAltH,
  faArrowsAltV,
  faArtstation,
  faAssistiveListeningSystems,
  faAsterisk,
  faAsymmetrik,
  faAt,
  faAtlas,
  faAtlassian,
  faAtom,
  faAudible,
  faAudioDescription,
  faAutoprefixer,
  faAvianex,
  faAviato,
  faAward,
  faAws,
  faBaby,
  faBabyCarriage,
  faBackspace,
  faBackward,
  faBacon,
  faBalanceScale,
  faBan,
  faBandAid,
  faBandcamp,
  faBars,
  faBasketballBall,
  faBath,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBattleNet,
  faBed,
  faBeer,
  faBehance,
  faBehanceSquare,
  faBell,
  faBellSlash,
  faBezierCurve,
  faBible,
  faBicycle,
  faBimobject,
  faBinoculars,
  faBiohazard,
  faBirthdayCake,
  faBitbucket,
  faBitcoin,
  faBity,
  faBlackTie,
  faBlender,
  faBlenderPhone,
  faBlind,
  faBlog,
  faBlogger,
  faBloggerB,
  faBluetooth,
  faBluetoothB,
  faBold,
  faBolt,
  faBomb,
  faBone,
  faBong,
  faBook,
  faBookDead,
  faBookMedical,
  faBookOpen,
  faBookReader,
  faBookmark,
  faBootstrap,
  faBox,
  faBowlingBall,
  faBoxOpen,
  faBoxes,
  faBraille,
  faBrain,
  faBreadSlice,
  faBriefcase,
  faBriefcaseMedical,
  faBroadcastTower,
  faBroom,
  faBrush,
  faBtc,
  faBuffer,
  faBug,
  faBuilding,
  faBullhorn,
  faBullseye,
  faBurn,
  faBuromobelexperte,
  faBus,
  faBusinessTime,
  faBuysellads,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarMinus,
  faCalendarPlus,
  faCalendarTimes,
  faCalendarWeek,
  faCamera,
  faCameraRetro,
  faCampground,
  faCanadianMapleLeaf,
  faCandyCane,
  faCannabis,
  faCapsules,
  faCar,
  faCarAlt,
  faCarBattery,
  faCarCrash,
  faCarSide,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretSquareDown,
  faCaretSquareLeft,
  faCaretRight,
  faCaretUp,
  faCaretSquareUp,
  faCarrot,
  faCartArrowDown,
  faCartPlus,
  faCashRegister,
  faCat,
  faCcAmazonPay,
  faCcAmex,
  faCcApplePay,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
  faCcVisa,
  faCentercode,
  faCentos,
  faCertificate,
  faChair,
  faChalkboard,
  faChalkboardTeacher,
  faChargingStation,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faCheese,
  faChess,
  faChessBishop,
  faChessBoard,
  faChessKing,
  faChessKnight,
  faChessPawn,
  faChessQueen,
  faChessRook,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronCircleUp,
  faChevronLeft,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChild,
  faChrome,
  faChromecast,
  faChurch,
  faCircle,
  faCircleNotch,
  faCity,
  faClinicMedical,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faClone,
  faClosedCaptioning,
  faCloud,
  faCloudDownloadAlt,
  faCloudMeatball,
  faCloudSun,
  faCloudSunRain,
  faCloudUploadAlt,
  faCloudscale,
  faCloudsmith,
  faCloudversify,
  faCocktail,
  faCode,
  faCodeBranch,
  faCodepen,
  faCodiepie,
  faCoffee,
  faCog,
  faCogs,
  faColumns,
  faComment,
  faCommentAlt,
  faCommentDollar,
  faCommentDots,
  faCommentMedical,
  faCommentSlash,
  faComments,
  faCommentsDollar,
  faCompactDisc,
  faCompass,
  faCompress,
  faCompressArrowsAlt,
  faConciergeBell,
  faConfluence,
  faConnectdevelop,
  faContao,
  faCookie,
  faCookieBite,
  faCopy,
  faCopyright,
  faCouch,
  faCpanel,
  faCreativeCommons,
  faCreativeCommonsBy,
  faCreativeCommonsNc,
  faCreativeCommonsNcEu,
  faCreativeCommonsNcJp,
  faCreativeCommonsNd,
  faCreativeCommonsPd,
  faCreativeCommonsPdAlt,
  faCreativeCommonsRemix,
  faCreativeCommonsSa,
  faCreativeCommonsSampling,
  faCreativeCommonsSamplingPlus,
  faCreativeCommonsShare,
  faCreativeCommonsZero,
  faCreditCard,
  faCriticalRole,
  faCrop,
  faCropAlt,
  faCross,
  faCrosshairs,
  faCrow,
  faCrown,
  faCrutch,
  faCss3,
  faCss3Alt,
  faCube,
  faCubes,
  faCut,
  faCuttlefish,
  faDAndD,
  faDAndDBeyond,
  faDashcube,
  faDatabase,
  faDeaf,
  faDelicious,
  faDemocrat,
  faDeploydog,
  faDeskpro,
  faDesktop,
  faDev,
  faDeviantart,
  faDharmachakra,
  faDhl,
  faDiagnoses,
  faDiaspora,
  faDice,
  faDiceD20,
  faDiceD6,
  faDiceFive,
  faDiceFour,
  faDiceOne,
  faDiceSix,
  faDiceTwo,
  faDiceThree,
  faDigg,
  faDigitalOcean,
  faDigitalTachograph,
  faDirections,
  faDiscord,
  faDiscourse,
  faDivide,
  faDizzy,
  faDna,
  faDochub,
  faDocker,
  faDog,
  faDollarSign,
  faDolly,
  faDollyFlatbed,
  faDonate,
  faDoorClosed,
  faDoorOpen,
  faDotCircle,
  faDove,
  faDownload,
  faDraft2digital,
  faDraftingCompass,
  faDragon,
  faDrawPolygon,
  faDribbble,
  faDribbbleSquare,
  faDropbox,
  faDrum,
  faDrumSteelpan,
  faDrumstickBite,
  faDrupal,
  faDumbbell,
  faDumpster,
  faDumpsterFire,
  faDungeon,
  faDyalog,
  faEarlybirds,
  faEbay,
  faEdge,
  faEdit,
  faEgg,
  faEject,
  faElementor,
  faEllipsisH,
  faEllipsisV,
  faEllo,
  faEmber,
  faEmpire,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopeSquare,
  faEnvira,
  faEquals,
  faEraser,
  faErlang,
  faEthereum,
  faEthernet,
  faEtsy,
  faEuroSign,
  faEvernote,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExpandArrowsAlt,
  faExpeditedssl,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faEye,
  faEyeDropper,
  faEyeSlash,
  faFacebook,
  faFacebookF,
  faFacebookMessenger,
  faFacebookSquare,
  faFantasyFlightGames,
  faFastBackward,
  faFastForward,
  faFax,
  faFeather,
  faFedex,
  faFedora,
  faFemale,
  faFighterJet,
  faFigma,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileContract,
  faFileCsv,
  faFileDownload,
  faFileExcel,
  faFileExport,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileMedical,
  faFileMedicalAlt,
  faFilePdf,
  faFilePowerpoint,
  faFilePrescription,
  faFileSignature,
  faFileUpload,
  faFileVideo,
  faFileWord,
  faFill,
  faFillDrip,
  faFilm,
  faFilter,
  faFingerprint,
  faFire,
  faFireAlt,
  faFireExtinguisher,
  faFirefox,
  faFirstAid,
  faFirstOrder,
  faFirstOrderAlt,
  faFirstdraft,
  faFish,
  faFistRaised,
  faFlag,
  faFlagCheckered,
  faFlagUsa,
  faFlask,
  faFlickr,
  faFlipboard,
  faFlushed,
  faFly,
  faFolder,
  faFolderMinus,
  faFolderOpen,
  faFolderPlus,
  faFont,
  faFontAwesome,
  faFontAwesomeAlt,
  faFontAwesomeFlag,
  faFonticons,
  faFonticonsFi,
  faFootballBall,
  faFortAwesome,
  faFortAwesomeAlt,
  faForumbee,
  faForward,
  faFoursquare,
  faFreeCodeCamp,
  faFreebsd,
  faFrog,
  faFrown,
  faFrownOpen,
  faFulcrum,
  faFunnelDollar,
  faFutbol,
  faGalacticRepublic,
  faGalacticSenate,
  faGamepad,
  faGasPump,
  faGavel,
  faGem,
  faGenderless,
  faGetPocket,
  faGg,
  faGgCircle,
  faGhost,
  faGift,
  faGifts,
  faGit,
  faGitAlt,
  faGitSquare,
  faGithub,
  faGithubAlt,
  faGithubSquare,
  faGitkraken,
  faGitlab,
  faGitter,
  faGlassCheers,
  faGlassMartini,
  faGlassMartiniAlt,
  faGlassWhiskey,
  faGlasses,
  faGlide,
  faGlideG,
  faGlobe,
  faGlobeAfrica,
  faGlobeAmericas,
  faGlobeAsia,
  faGlobeEurope,
  faGofore,
  faGolfBall,
  faGoodreads,
  faGoodreadsG,
  faGoogle,
  faGoogleDrive,
  faGooglePlay,
  faGooglePlus,
  faGooglePlusG,
  faGooglePlusSquare,
  faGoogleWallet,
  faGopuram,
  faGraduationCap,
  faGratipay,
  faGrav,
  faGreaterThan,
  faGreaterThanEqual,
  faGrimace,
  faGrin,
  faGrinAlt,
  faGrinBeam,
  faGrinBeamSweat,
  faGrinHearts,
  faGrinSquint,
  faGrinSquintTears,
  faGrinStars,
  faGrinTears,
  faGrinTongue,
  faGrinTongueSquint,
  faGrinTongueWink,
  faGrinWink,
  faGripHorizontal,
  faGripLines,
  faGripLinesVertical,
  faGripVertical,
  faGripfire,
  faGrunt,
  faGuitar,
  faGulp,
  faHSquare,
  faHackerNews,
  faHackerNewsSquare,
  faHackerrank,
  faHamburger,
  faHammer,
  faHamsa,
  faHandHolding,
  faHandHoldingHeart,
  faHandHoldingUsd,
  faHandLizard,
  faHandMiddleFinger,
  faHandPaper,
  faHandPeace,
  faHandPointDown,
  faHandPointLeft,
  faHandPointRight,
  faHandPointUp,
  faHandPointer,
  faHandRock,
  faHandScissors,
  faHandSpock,
  faHands,
  faHandsHelping,
  faHandshake,
  faHanukiah,
  faHardHat,
  faHashtag,
  faHatWizard,
  //faHaykal,
  faHdd,
  faHeading,
  faHeadphones,
  faHeadphonesAlt,
  faHeadset,
  faHeart,
  faHeartBroken,
  faHeartbeat,
  faHelicopter,
  faHighlighter,
  faHiking,
  faHippo,
  faHips,
  faHireAHelper,
  faHistory,
  faHockeyPuck,
  faHollyBerry,
  faHome,
  faHooli,
  faHornbill,
  faHorse,
  faHorseHead,
  faHospital,
  faHospitalAlt,
  faHospitalSymbol,
  faHotTub,
  faHotdog,
  faHotel,
  faHotjar,
  faHourglass,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faHouseDamage,
  faHouzz,
  faHryvnia,
  faHtml5,
  faHubspot,
  faICursor,
  faIceCream,
  faIcicles,
  faIdBadge,
  faIdCard,
  faIdCardAlt,
  faIgloo,
  faImage,
  faImages,
  faImdb,
  faInbox,
  faIndent,
  faIndustry,
  faInfinity,
  faInfo,
  faInfoCircle,
  faInstagram,
  faIntercom,
  faInternetExplorer,
  faInvision,
  faIoxhost,
  faItalic,
  faItchIo,
  faItunes,
  faItunesNote,
  faJava,
  faJedi,
  faJediOrder,
  faJenkins,
  faJira,
  faJoget,
  faJoint,
  faJoomla,
  faJournalWhills,
  faJs,
  faJsSquare,
  faJsfiddle,
  faKaaba,
  faKaggle,
  faKey,
  faKeybase,
  faKeyboard,
  faKeycdn,
  faKhanda,
  faKickstarter,
  faKickstarterK,
  faKiss,
  faKissBeam,
  faKissWinkHeart,
  faKiwiBird,
  faKorvue,
  faLandmark,
  faLanguage,
  faLaptop,
  faLaptopCode,
  faLaptopMedical,
  faLaravel,
  faLastfm,
  faLastfmSquare,
  faLaugh,
  faLaughBeam,
  faLaughSquint,
  faLaughWink,
  faLayerGroup,
  faLeaf,





];


export default fontAwesomeIcon;
