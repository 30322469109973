import React from "react";
import { Button, Form, Input } from "antd";
const { Item } = Form;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 10,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 6,
  },
};
const validationRules = {
  username: [
    {
      required: true,
      message: "Please enter the username",
    },
  ],
  password: [
    {
      required: true,
      message: "Please enter the password",
    },
  ],
};

export default ({ onSubmit, submitting, APIConfiguration }) => {
  const [acuantForm] = Form.useForm();
  const submit = async (data) => {
    await onSubmit(data);
    acuantForm.resetFields();
  };
  return (
    <>
      <Form {...layout} form={acuantForm} onFinish={submit}>
        <Item
          label="Username"
          name="acuantUsername"
          rules={validationRules.username}
        >
          <Input disabled={submitting}></Input>
        </Item>
        <Item
          label="password"
          name="acuantPassword"
          rules={validationRules.password}
        >
          <Input disabled={submitting}></Input>
        </Item>
        <Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Submit
          </Button>
        </Item>
      </Form>
      <div style={{ textAlign: "left" }}>
        <b>Current username:</b>&nbsp; {APIConfiguration?.acuantUsername}
        <br />
        <b>Current password:</b>&nbsp; {APIConfiguration?.acuantPassword}
      </div>
    </>
  );
};
