import React from 'react';
import { Result, Card } from 'antd';
import CardHOC from '../../CardHOC';

export default () => {
  return (
    <>
      <CardHOC component={<Result
        title="Please verifiy your email"
      />}
      heading={''}
      />
    </>
  )
}