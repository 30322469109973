import React, { useState, useEffect } from 'react';
import { Col, Card, Descriptions, Typography, Row, Input, Button, Spin, Result, message, notification } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { IssuerCounselService } from '../../IssuerCounsel.service';
import { SecurityTokenRegistryService } from '../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { AuthService } from '../../../Shared/Auth.service';
import { MetamaskService } from '../../../Shared/Metamask.service';
import { SymbolDetailsAndSTData, FiledNotice } from '../../../Shared/interfaces';
import { SharedService } from '../../../Shared/Shared.service';
import TransactionModal from '../../../Shared/TransactionModal';
import { TokenConfigurationService } from '../../../TokenConfigurations/TokenConfiguration.service';

// import { SecurityTokenService } from '../../../Shared/SecurityToken/SecurityToken.service';
import MainFacet from '../../../Shared/SecurityToken/Facets/MainFacet/index';
import WhitelistFacet from '../../../Shared/SecurityToken/Facets/WhitelistFacet/index';

const {Title} = Typography;
const {TextArea} = Input;
const issuerCounselService = new IssuerCounselService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();
const sharedService = new SharedService();

// const securityTokenService = new SecurityTokenService();
const mainFacet = new MainFacet();
const whitelistFacet = new WhitelistFacet();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default function Details() {

  const history = useHistory();
  const {filedNoticeId} = useParams<{filedNoticeId: string}>();

  const {userInfo} = useUserContext();
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();

  const [filedNotice, setFiledNotice] = useState<FiledNotice>();
  const [loading, setLoading] = useState(true);

  const [comments, setComments] = useState('');
  const [rejecting, setRejecting] = useState<boolean>();

  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [issuerCounselAdminWallet, setIssuerCounselAdmin] = useState('');


  useEffect(() => {
    (async () => {
      if(!userInfo || !selectedWallet) return;

      let _tokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      // const _issuerComplianceAdminWallet = await securityTokenService.issuerCounsel(_symbolDetailsAndSTData.securityTokenData.contractAddress);
      const _issuerComplianceAdminWallet = await mainFacet.issuerCounsel(_symbolDetailsAndSTData.securityTokenData.contractAddress);

      setIssuerCounselAdmin(_issuerComplianceAdminWallet);

      const response = await issuerCounselService.getFiledNotice(filedNoticeId);

      setFiledNotice(response.data);
      setLoading(false);

    })();
  }, [userInfo, selectedWallet]);


  const rejectFiledNotice = async() => {
    setRejecting(true);
      
    const response = await issuerCounselService.rejectFiledNotice({filedNoticeId, comments});

    if (response.success) {
      notification.success({
        message: 'Request rejected successfully'
      });

      history.push('/issuer-counsel/review-affiliate-form-144');

    } else {
      notification.error({
        message: response.error.message,
        // description
      });
    }

    setRejecting(false);
  }


  const approveFiledNotice = async() => {
    setIsModalVisible(true);

    setTransactions([
      {details: 'Approving Filed Notice', submitting: true}
    ]);

    try {

      // const receipt = await securityTokenService.approveFiledNoticeWithSEC(
      //   symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
      //   selectedWallet as string, 
      //   filedNotice?.wallet as string
      // );

      const receipt = await whitelistFacet.approveFiledNoticeWithSEC(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string, 
        filedNotice?.wallet as string
      );

      if(receipt?.status) {
        notification.success({
          message: 'Request approved successfully'
        });
  
        history.push('/issuer-counsel/review-affiliate-form-144');
      }
      
      setTransactions(prev => {
        const current = JSON.parse(JSON.stringify(prev));
        current[0].receipt = receipt;
        return current;
      });

    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = JSON.parse(JSON.stringify(prev));
      current[0].submitting = false;
      return current;
    });

  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }
          {!loading && filedNotice &&
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Filed Notice Details</Title>
              
              {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
                <Result
                  title={`Security Token not deployed`}
                />
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() !== issuerCounselAdminWallet.toLowerCase() && 
                  <>
                    <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                    <Result
                      status="error"
                      title = {
                        <p>
                          Select the wallet {' '}
                          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${issuerCounselAdminWallet}`}>
                            {sharedService.minifyAddress(issuerCounselAdminWallet.toLowerCase())}
                          </a> 
                          {' '} in order to proceed
                        </p>
                      }
                    />
                  </>
              }

              {symbolDetailsAndSTData?.symbolDetails.isDeployed && selectedWallet?.toLowerCase() === issuerCounselAdminWallet.toLowerCase() && 
                <>
                  <Descriptions bordered column={2}>
                    <Descriptions.Item span={2} label="First Name">{filedNotice['firstName']}</Descriptions.Item>
                    <Descriptions.Item span={2} label="Last Name">{filedNotice['lastName']}</Descriptions.Item>
                    <Descriptions.Item span={2} label="Email">{filedNotice['email']}</Descriptions.Item>
                    <Descriptions.Item span={2} label="Filed Notice">
                      <a 
                        href={filedNotice.noticeFile.URL} 
                        download={filedNotice.noticeFile.name} 
                        target="_blank" rel="noopener noreferrer"> {filedNotice.noticeFile.name}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Reference N°">{filedNotice.referenceNumber}</Descriptions.Item>
                  </Descriptions>

                  <Row justify="center">
                    <Col span={16}>
                      <br/>
                      <h2>Comments</h2>
                      <TextArea value={comments} onChange={e => setComments(e.target.value)}></TextArea>
                      <br/><br/>
                      <Button key="reject" danger loading={rejecting} onClick={rejectFiledNotice}>
                        Reject
                      </Button>,
                      <Button key="approve" type="primary" disabled={rejecting} onClick={() => approveFiledNotice()}>
                        Approve
                      </Button>
                    </Col>
                  </Row>

                  <div style={{textAlign:'right'}}>
                    <Button style={{marginRight:'5px'}} size='large' onClick={() => history.push('/issuer-counsel/review-affiliate-form-144')}>
                      BACK
                    </Button>
                  </div>
                </>
              }
            </Card>
          }
          <br/>
        </Col>
      </Row>

      <TransactionModal
        title = {'Affiliate filed Notice Approval'}
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />
    </>
  );
}