import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Table, Button, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { TokenConfigurationProcess, VestingScheduleTemplate } from '../../../../Shared/interfaces';
import { AuthService } from '../../../../Shared/Auth.service';
import { SecurityTokenRegistryService } from '../../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import BigNumber from 'bignumber.js';
import { SharedService } from '../../../../Shared/Shared.service';
import { TokenConfigurationService } from '../../../../TokenConfigurations/TokenConfiguration.service';

import { SecurityTokenService } from '../../../../Shared/SecurityToken/SecurityToken.service';
// import { VestingService } from '../../../../Shared/Vesting/Vesting.service';
import VestingFacet from '../../../../Shared/SecurityToken/Facets/VestingFacet/index';

const {Title} = Typography;
const useUserContext = () => new AuthService().useUserContext();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();

const securityTokenService = new SecurityTokenService();
// const vestingService = new VestingService();
const vestingFacet = new VestingFacet();

export default () => {
  const {userInfo} = useUserContext();

  const precision = securityTokenService.precision;

  const history = useHistory();
  const {vestingScheduleIndex} = useParams<{vestingScheduleIndex: string}>();
  // const [loading, setLoading] = useState<boolean>(true);
  // const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();

  const [selectedVestingScheduleTemplate, setSelectedVestingScheduleTemplate] = useState<VestingScheduleTemplate>();

  // useEffect(() => {
  //   (async () => {
  //     if(!vestingScheduleIndex) return;

  //     const response = await issuerSuperAdminService.getVestingSchedule(vestingScheduleId);
  //     const _selectedVestingSchedule: VestingSchedule = response.data;
  //     _selectedVestingSchedule.vesting.forEach((schedule, index) => schedule['key'] = index);
  //     setSelectedVestingSchedule(_selectedVestingSchedule);

  //   })();
  // }, [vestingScheduleIndex]);


  useEffect(() => {
    (async () => {
      if(!userInfo) return;
      if(!vestingScheduleIndex) return;

      const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;
      // setTokenConfigurationProcess(_tokenConfigurationProcess);

      // if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const [_symbolDetailsAndSTData] = await Promise.all([
        securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess?.tokenSymbol as string),
      ]);

      // setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      // if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      //ASSUMIING MODULE EXISTS ALREADY

      
      const _selectedVestingScheduleTemplate = await vestingFacet.getVestingScheduleTemplate(_symbolDetailsAndSTData.securityTokenData.contractAddress, vestingScheduleIndex);

    
      console.log(_selectedVestingScheduleTemplate);

      const __selectedVestingScheduleTemplate = {..._selectedVestingScheduleTemplate};
      __selectedVestingScheduleTemplate.vesting = _selectedVestingScheduleTemplate.vesting.map(schedule => ({endOfMonth: schedule.endOfMonth, isFixed: schedule.isFixed, lockPeriod: schedule.lockPeriod, percent: schedule.percent}));
      __selectedVestingScheduleTemplate.vesting.forEach((schedule, index) => schedule['key'] = index);

      setSelectedVestingScheduleTemplate(__selectedVestingScheduleTemplate);

      // setLoading(false);

    })();
    
  },[userInfo, vestingScheduleIndex]);



  const previousVestingDuration = (record: VestingScheduleTemplate['vesting'][0]) => {
    const index = selectedVestingScheduleTemplate?.vesting.findIndex(schedule => schedule['key'] === record['key']);
    return +((index? selectedVestingScheduleTemplate?.vesting[index-1].endOfMonth : 0) as string);
  };

  const columns = [
    {
      title: 'Vesting Schedule',
      dataIndex: 'vestingScheduleNumber',
      render: (value: string, record) => `Vesting ${record['key'] + 1}`
    },
    {
      title: <>Fixed or divide equaly</>,
      dataIndex: 'isFixed',
      render: (value: boolean, record) => {
        return (
          <div>
            {value? 'Fixed' : 'Divide Equaly'}
          </div>
        );
      }
    },
    {
      title: 'End of vesting month',
      dataIndex: 'endOfMonth',
      render: (value: number, record) => {
        return (
          <>
            {!record.isFixed && `${previousVestingDuration(record) as number + 1} - `} {value}
          </>
        );
 
      }
    },
    {
      title: 'Vesting %',
      dataIndex: 'percent',
      render: (value: string, record) => new BigNumber(record.percent).div(new BigNumber(10).pow(precision)).toFixed(0)
    },
    {
      title: 'Lock Period',
      dataIndex: 'lockPeriod',
    }
  ];


  return (
    <>
    <br/><br/>
    <Row justify="center">
      <Col span={20}>
        {!selectedVestingScheduleTemplate &&  
            <div style={{textAlign:'center'}}>
              <br/>
              <Spin size='large'/>
            </div>
        }
        {selectedVestingScheduleTemplate &&
          <Card>
            <Title level={1} style={{textAlign:'center'}}>{sharedService.bytes32ToString(selectedVestingScheduleTemplate.name)}</Title>
            {/* <Title level={3}>{selectedVestingSchedule.description}</Title> */}
            <Title level={4}>Duration: {selectedVestingScheduleTemplate.duration} months</Title>

            <Table
              columns={columns}
              dataSource={selectedVestingScheduleTemplate.vesting}
              pagination={false}
              />
            <br/><br/>
            <div style={{textAlign:'right'}}>
              <Button style={{marginRight:'5px'}} size='large' onClick={() => history.push(`/issuer-token-admin/token-managment/create-vesting-schedule`)}>
                BACK
              </Button>
              <Button size='large' type='primary' htmlType="submit">
                EDIT
              </Button>
            </div>
          </Card>
        }
      </Col>
    </Row>
    </>
  );
}