import React from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import List from './List';
import Details from './Details';




export default () => {
  const { path } = useRouteMatch();


  return (
    <Switch>
      <Route path={`${path}`} exact>
        <List/>
      </Route>
      <Route path={`${path}/:filedNoticeId`}>
        <Details/>
      </Route>
    </Switch>
  );
}