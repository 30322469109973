import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Table, Spin, Descriptions, Button } from 'antd';
import {
  OfferingModelScreenAction,
  TokenPhase,
  User,
} from '../../../../Shared/interfaces';
import moment from 'moment';
import { TokenConfigurationService } from '../../../TokenConfiguration.service';

const { Title } = Typography;

const tokenConfigurationService = new TokenConfigurationService();

export default (props: {
  userInfo: User;
  screen: OfferingModelScreenAction;
  setScreen: (action: OfferingModelScreenAction) => void;
}) => {
  const { userInfo, screen, setScreen } = props;

  const [tokenPhase, setTokenPhase] = useState<TokenPhase>();

  useEffect(() => {
    (async () => {
      if (!screen) return; //status !== 'phase'

      const response = await tokenConfigurationService.getPhase(
        screen.tokenPhaseId as string
      );
      setTokenPhase(response.data);
    })();
  }, [screen]);

  const phaseColumns = [
    {
      title: 'Minimum Investment Amount',
      dataIndex: 'minInvestmentAmount',
      render: (value) => `${value.min} - ${value.max}`,
    },
    {
      title: '% Bonus',
      dataIndex: 'bonusPercent',
    },
  ];

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          {!tokenPhase && (
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </div>
          )}
          {tokenPhase && (
            <>
              <Title level={2} style={{ textAlign: 'center' }}>
                Phase Information
              </Title>
              <Descriptions bordered column={2}>
                <Descriptions.Item span={2} label="Name of the phase">
                  {tokenPhase.phaseName}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Bonus Floor">
                  {tokenPhase.bonusFloor}
                </Descriptions.Item>
                <Descriptions.Item label="Start date">
                  {moment(tokenPhase.startDate).format('LLL')}
                </Descriptions.Item>
                <Descriptions.Item label="End date">
                  {moment(tokenPhase.endDate).format('LLL')}
                </Descriptions.Item>
              </Descriptions>

              <Table
                title={() => <Title level={3}>Offer Bonus</Title>}
                columns={phaseColumns}
                dataSource={tokenPhase.bonusOffers.map((offers, i) => ({
                  ...offers,
                  key: i,
                }))}
                pagination={false}
              ></Table>
              <br />
              <br />
              <div style={{ textAlign: 'right' }}>
                <Button
                  style={{ marginRight: '5px' }}
                  size="large"
                  onClick={() =>
                    setScreen({
                      ...screen,
                      component: 'offering-models-details',
                    })
                  }
                >
                  BACK
                </Button>
                {(userInfo.role === 'issuer super admin' ||
                  userInfo.role === 'issuer token admin') && (
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    onClick={() =>
                      setScreen({
                        ...screen,
                        component: 'phases-edit',
                      })
                    }
                  >
                    EDIT
                  </Button>
                )}
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
