import { MetamaskService } from '../../../Metamask.service';
import { WhitelistedWallet } from '../../../interfaces';
import {TrustedForwarderService} from "../../../TrustedForwarder.service";

const trustedForwarderService = new TrustedForwarderService();

export default class WhitelistFacet {
  contract = {
    ABI: require('./ABI.json')
  };
  
  private get web3() {
    return MetamaskService.web3;
  }
  
  async addWhitelistedWallet(
    tokenAddress: string,
    owner: string,
    _from: string,
    _isAffiliate: boolean,
    _country: string,
    _isAccredetedInvestor: boolean,
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.addWhitelistedWallet(_from, _isAffiliate, _country, _isAccredetedInvestor);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async addWhitelistedWalletMulti(
    tokenAddress: string,
    owner: string,
    _from: string[],
    _isAffiliate: boolean[],
    _country: string[],
    _isAccredetedInvestor: boolean[],
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.addWhitelistedWalletMulti(_from, _isAffiliate, _country, _isAccredetedInvestor);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async removeWhitelistedWallet(
    tokenAddress: string,
    owner: string,
    _from: string,
    extra: {delegate: boolean} = {delegate: false}
    ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.removeWhitelistedWallet(_from);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async getWhitelist(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getWhitelist().call()) as WhitelistedWallet[];
  }

  async getWhitelistedWallet(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getWhitelistedWallet(_from).call()) as WhitelistedWallet;
  }

  async getTotalWhitelist(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getTotalWhitelist().call()) as string;
  }

  async getAffiliatesWithApprovedFileNotice(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getAffiliatesWithApprovedFileNotice().call()) as string[];
  }

  async approveFiledNoticeWithSEC(tokenAddress: string, owner: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return contractInstance.methods.approveFiledNoticeWithSEC(_from).send({ from: owner });
  }

};

// Not Implemented
// function getTotalAffiliatesWithApprovedFileNotice() external view returns (uint256) {
// }
// function removeFiledNoticeWithSECApproval(address _from, uint256 _months) external onlyFacet {
// }
