import { Button, Col, Row } from "antd";
import React, { useEffect , useState} from "react";
import { Link } from "react-feather";
import { Container } from "reactstrap";
import sign from "../../assets/images/icons/tick.png";
import { PlatformComplianceAdminService } from "../PlatformComplianceAdmin/PlatformComplianceAdmin.service";





const SubscriptionAgreement = () => {
   
    const visibility = "public";
    const [DueDiligenceList, setDueDiligenceList] = useState([]);
  useEffect(() => {
    const fetchIssuerQuestions = async (visibility) => {
      const issuerQuestionListResponse =
        await PlatformComplianceAdminService.getDueDiligenceQuestionListForIssuer(
          { visiblity: visibility }
        );
      if (issuerQuestionListResponse.success) {
        if (issuerQuestionListResponse.data.length > 0) {
          console.log(issuerQuestionListResponse.data)
          await setDueDiligenceList(issuerQuestionListResponse.data);
        } else {
          await setDueDiligenceList(null);
          // message.error("No Data to display!");
        }
      } else {
        // message.error("Error in loading due diligence!");
      }
    };
  
  }, []);

  return (
    <div id="disclaimer" style={{ paddingTop: "150px", textAlign: "justify" }}>
      <div style={{ padding: "20px" }}>
        <a href="/">
          <Button
            shape="round"
            size="large"
            style={{
              float: "left",
              color: "#386fd0",
              fontWeight: "bold",
              marginTop: "10px",
              border: "2px solid #2fbaca",
            }}
          >
            <i className="fa fa-arrow-left"></i>&nbsp;&nbsp;BACK TO HOMEPAGE
          </Button>
        </a>
      </div>
      <Container>
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "#141838", fontSize: "55px", fontWeight: "bold" }}>
            <span className="underline">Subscription Agreement</span>
          </p>
        </div>
        <Row>
          <Col className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

              <div style={{ padding: "15px" }}></div>

              <h3 style={{ color: "#00b6e3" }}>
                Subscription Agreement FOR CRYPTO ASSET RATING WEBSITE
              </h3>
          

             {DueDiligenceList.map(question => 
                <div>
                    console.log(question)
                </div>
                )}
 
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SubscriptionAgreement;
