import React, { useState } from 'react';
import { Input, Button, Row, Col, Form, Typography, InputNumber } from 'antd';
import {
  APIResponse,
  OfferingModelScreenAction,
  TokenConfigurationProcess,
} from '../../../../Shared/interfaces';
import { SharedService } from '../../../../Shared/Shared.service';

const { Title } = Typography;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const sharedService = new SharedService();

export default function OfferingModelsNew(props: {
  tokenConfigurationProcess: TokenConfigurationProcess | undefined;
  saveOfferingModel: (req: any) => Promise<APIResponse>;
  setScreen: (action: OfferingModelScreenAction) => void;
}) {
  const { tokenConfigurationProcess, saveOfferingModel, setScreen } = props;
  const [submitting, setSubmitting] = useState<boolean>();

  const _saveOfferingModel = async (formValue) => {
    const req = sharedService.clone(formValue);
    req.tokenConfigurationProcessId = tokenConfigurationProcess?._id;
    req.investmentAmounts = {
      min: req.minInvestmentAmount,
    };

    delete req.minInvestmentAmount;

    setSubmitting(true);
    await saveOfferingModel(req);
    setScreen({ component: 'offering-models-list' });
    setSubmitting(false);
  };

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          <Title level={4} style={{ textAlign: 'center' }}>
            New Offering Model
          </Title>
          <Form
            {...formItemLayout}
            labelAlign="left"
            onFinish={_saveOfferingModel}
          >
            <br />
            <br />

            <Form.Item
              label={`Enter the name of the Model`}
              name="name"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input placeholder="Enter the name of the model" />
            </Form.Item>

            <Form.Item
              name="minInvestmentAmount"
              label="Min Investment Amount"
              rules={[
                {
                  required: true,
                  message: 'Please select amount',
                },
              ]}
            >
              <InputNumber min={0} placeholder={'Min Investment Amount'} />
            </Form.Item>

            <br />
            <br />

            <div style={{ textAlign: 'right' }}>
              <Button
                size="large"
                style={{ marginRight: '5px' }}
                htmlType="button"
                onClick={() => setScreen({ component: 'offering-models-list' })}
              >
                BACK
              </Button>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={submitting}
              >
                SAVE THE OFFERING MODEL
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
}
