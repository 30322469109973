import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
// import List from './List';
// import New from './New';
// import Details from './Details';
// import AddRole from './AddRole';
import EditRole from './EditRole';



export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* <Route path={`${path}`} exact>
        <List/>
      </Route>
      <Route path={`${path}/new`}>
        <New/>
      </Route>
      <Route path={`${path}/details/:id`}>
        <Details/>
      </Route>
      <Route path={`${path}/add-role`}>
        <AddRole/>
      </Route> */}
      <Route path={`${path}/edit-role`}>
        <EditRole/>
      </Route>
    </Switch>
  );
}