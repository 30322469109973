import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { User } from '../../Shared/interfaces';



export default (prop) => {
  const {data, loading} = prop;

  const columns = [
    {
      title: '#',
      dataIndex: 'columNumber'
    },
    {
      title: 'FIRST NAME',
      dataIndex: 'firstName'
    },
    {
      title: 'LAST NAME',
      dataIndex: 'lastName'
    },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email'
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS', 
      render: (value) => moment(value).format('LLL')
    }
  ];

  (data as User[])?.forEach((req, index) => {
    req['columNumber'] = index + 1;
  });

  return (
    <Table columns={columns} dataSource={data} rowKey="_id" pagination={false} loading={loading}/>
  );
}