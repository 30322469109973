import React, { useEffect, useState } from 'react';
import {
  Input,
  Button,
  Row,
  Col,
  Form,
  Typography,
  InputNumber,
  DatePicker,
  Table,
  Popconfirm,
  Spin,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  APIResponse,
  OfferingModelScreenAction,
  TokenConfigurationProcess,
  TokenOfferingModel,
} from '../../../../Shared/interfaces';
import BigNumber from 'bignumber.js';
import { TokenConfigurationService } from '../../../TokenConfiguration.service';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const tokenConfigurationService = new TokenConfigurationService();

export default (props: {
  tokenConfigurationProcess: TokenConfigurationProcess | undefined;
  screen: OfferingModelScreenAction;
  savePhase: (req: any) => Promise<APIResponse>;
  setScreen: (action: OfferingModelScreenAction) => void;
}) => {
  const { tokenConfigurationProcess, screen, savePhase, setScreen } = props;

  const [submitting, setSubmitting] = useState<boolean>();
  const [bonusOffers, setBonusOffers] = useState([
    { key: 0, minInvestmentAmount: { min: 0, max: 1 }, bonusPercent: 0 },
  ]);
  const [count, setCount] = useState(1);
  const [offeringModel, setOfferingModel] = useState<TokenOfferingModel>();

  useEffect(() => {
    (async () => {
      if (!screen.modelId) return;

      const response = await tokenConfigurationService.getOfferingModelById({
        modelId: screen.modelId,
      });

      const _offeringModel = response.data as TokenOfferingModel;

      setBonusOffers([
        {
          key: 0,
          minInvestmentAmount: {
            min: _offeringModel.investmentAmounts.min,
            max: _offeringModel.investmentAmounts.min + 1,
          },
          bonusPercent: 0,
        },
      ]);

      setOfferingModel(_offeringModel);
    })();
  }, [screen]);

  const columns = [
    {
      title: 'Minimum Investment Amount',
      dataIndex: 'minInvestmentAmount',
      render: (value, record) => {
        const bonusOfferIndex = bonusOffers.findIndex(
          (offer) => offer.key === record.key
        );
        return (
          <>
            <InputNumber
              min={
                bonusOfferIndex > 0
                  ? bonusOffers[bonusOfferIndex - 1].minInvestmentAmount.max + 1
                  : offeringModel?.investmentAmounts.min
              }
              value={value.min}
              onKeyDown={(e) => {
                e.key === 'Enter' && e.target['blur']();
              }}
              onChange={(e) =>
                editBonusOffer(
                  e as number,
                  record,
                  'minInvestmentAmount',
                  'min'
                )
              }
            />
            {' - '}
            <InputNumber
              min={value.min + 1}
              value={value.max}
              onKeyDown={(e) => {
                e.key === 'Enter' && e.target['blur']();
              }}
              onChange={(e) =>
                editBonusOffer(
                  e as number,
                  record,
                  'minInvestmentAmount',
                  'max'
                )
              }
            />
          </>
        );
      },
    },
    {
      title: '% Bonus',
      dataIndex: 'bonusPercent',
      render: (value, record) => {
        return (
          <InputNumber
            min={0}
            max={100}
            value={value}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.target['blur']();
            }}
            onChange={(e) =>
              editBonusOffer(e as number, record, 'bonusPercent')
            }
          />
        );
      },
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (text, record) =>
        bonusOffers.length > 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteBonusOffer(record.key)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const addBonusOffer = () => {
    setCount((prev) => prev + 1);
    const min =
      bonusOffers.length > 0
        ? bonusOffers[bonusOffers.length - 1].minInvestmentAmount.max + 1
        : 0;
    setBonusOffers((prev) => [
      ...prev,
      {
        key: count,
        minInvestmentAmount: { min, max: min + 1 },
        bonusPercent: 0,
      },
    ]);
  };

  const deleteBonusOffer = (key: number) => {
    setBonusOffers((prev) => [...prev.filter((item) => item.key !== key)]);
  };

  const editBonusOffer = (
    value: number,
    record,
    dataIndex: 'minInvestmentAmount' | 'bonusPercent',
    range?: 'min' | 'max'
  ) => {
    // if(!value && value !== 0) return;
    if (!new BigNumber(value).isGreaterThanOrEqualTo(0)) return;

    const newData = [...bonusOffers];
    const index = newData.findIndex((offer) => offer.key === record.key);

    if (dataIndex === 'minInvestmentAmount') {
      newData[index] = {
        ...newData[index],
        [dataIndex]: { ...newData[index][dataIndex], [range as string]: value },
      };
    } else {
      newData[index] = { ...newData[index], [dataIndex]: value };
    }
    setBonusOffers(newData);
  };

  const _savePhase = async (formValue) => {
    const req = { ...formValue };
    req.startDate = (req.datesOfPhase[0].toDate() as Date).getTime();
    req.endDate = (req.datesOfPhase[1].toDate() as Date).getTime();
    req.bonusOffers = [...bonusOffers].map((offer) => ({
      minInvestmentAmount: offer.minInvestmentAmount,
      bonusPercent: offer.bonusPercent,
    }));
    req.tokenConfigurationProcessId = tokenConfigurationProcess?._id;
    req.tokenOfferingModelId = screen.modelId;


    setSubmitting(true);
    console.log(req);
    const response = await savePhase(req);

    if (response.success) {
      setScreen({ ...screen, component: 'offering-models-details' });
    }
    setSubmitting(false);
  };

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          {!offeringModel && (
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </div>
          )}

          {offeringModel && (
            <>
              <Title level={2} style={{ textAlign: 'center' }}>
                New Phase
              </Title>
              <Form {...formItemLayout} labelAlign="left" onFinish={_savePhase}>
                <br />
                <br />

                <Form.Item
                  label={`Enter the name of the phase`}
                  name="phaseName"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input placeholder="Enter the name of the phase" />
                </Form.Item>

                <Form.Item
                  name="datesOfPhase"
                  label="Dates of Phase"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      message: 'Please select time!',
                    },
                  ]}
                >
                  <RangePicker
                    allowClear={false}
                    showTime
                    format="MMMM DD, YYYY HH:mm:ss"
                  />
                </Form.Item>

                <Form.Item
                  name="bonusFloor"
                  label="Bonus Floor"
                  rules={[
                    {
                      required: true,
                      message: 'Please select bonus',
                    },
                  ]}
                >
                  <InputNumber min={0} placeholder={'Bonus Floor'} />
                </Form.Item>

                <Table
                  title={() => <Title level={3}>Offer Bonus</Title>}
                  columns={columns}
                  dataSource={bonusOffers}
                  pagination={false}
                  footer={() => (
                    <Button onClick={addBonusOffer}>
                      <PlusOutlined /> Click here to add additional Bonus
                      offerings
                    </Button>
                  )}
                ></Table>

                <br />
                <br />
                <div style={{ textAlign: 'right' }}>
                  <Button
                    size="large"
                    style={{ marginRight: '5px' }}
                    htmlType="button"
                    onClick={() =>
                      setScreen({
                        ...screen,
                        component: 'offering-models-details',
                      })
                    }
                  >
                    BACK
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                  >
                    SAVE THE PHASE AND BONUS OFFERING
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
