import React,{useEffect,useState} from 'react'
import {Form, Select, Input, Button, Alert} from 'antd'
import {PlatformSuperAdminService} from '../PlatformSuperAdmin.service'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const platformSuperAdminService=new PlatformSuperAdminService();

export default ()=>{
  const [roles,setRoles]=useState([]);
  const [disabled,setDisabled]=useState(true)
  const [phoneNumber,setPhoneNumber]=useState('')
  const [addedView,setAddedView]=useState(false)
  const [errorView,setErrorView]=useState(false)
  const [companyField,setCompanyField]=useState(true)
  const [form]=Form.useForm()

  useEffect(
    ()=>{
      (async ()=>{
          const response=await platformSuperAdminService.getRoles();
          setRoles(response.data['0'].roles);
      })();
    },[]
  )
  const enableFields=(selected)=>{
    if(selected===-1)
    {
      setCompanyField(true)
      setDisabled(true)
      return;
    }
    
    if(selected.search("Issuer")!==-1)
    {
      setCompanyField(false)  
    }
    else
    {
      setCompanyField(true)
    }
    setDisabled(false)
    setAddedView(false)
    setErrorView(false)
  }
  const onFinished=(values)=>{
    (async()=>{
      const response=await platformSuperAdminService.setUserRole(values);
      console.log(response)
      if(response.success && response.data===405)
      {
        setErrorView(true)
      }
      if(response.success && response.data===406)
      {
        setErrorView(true)
      }
      if(response.success && response.data===200)
      {
        setAddedView(true);
        reset();
      }
    })()
  }
  const reset=()=>{
    setDisabled(true)
    form.resetFields();
  }

  return(
    <>
    {addedView && <Alert message="Role added" type="success"></Alert>}
    {errorView && <Alert message="not able to send email" type="error" ></Alert>}
    
    <Form
    onFinish={onFinished}
    form={form}
    >
      <Form.Item
      name="role"
      initialValue={-1}
      label="Select the Admin Role you wish to create"
      rules={
        [
          {
            required:true,
            message:"required"
          }
        ]
      }
      >
      <Select loading={!roles}  style={{width:280}} onChange={enableFields}>
        <Select.Option key={-1} value={-1}>&lt;select&gt;</Select.Option>
        {roles?.map((role,index)=><Select.Option key={index} value={role}>{role}</Select.Option>)}
      </Select>
      </Form.Item>
      <Form.Item
      name="firstName"
      label='First Name'
      rules={
        [
          {
            required:true,
            message:"required"
          }
        ]
      }
      >
      <Input style={{width:280,marginLeft:185}}  placeholder='First Name'disabled={disabled}></Input>
      
      </Form.Item>
      <Form.Item
      label='Last Name'
      name='lastName'
      rules={
        [
          {
            required:true,
            message:"required"
          }
        ]
      }
      >
      <Input style={{width:280,marginLeft:185}} placeholder='Last Name' disabled={disabled}></Input>
      </Form.Item>
      <Form.Item
      name="email"
      label='Email Address'
      rules={
        [
          {
            required:true,
            message:"required"
          }
        ]
      }
      >
      <Input  style={{width:280,marginLeft:165}} placeholder='Email' disabled={disabled} ></Input>
      </Form.Item>
      <Form.Item
      name="contactNumber"
      label='Contact Number'
      rules={[{
        required:true,
        message:'Required!!'
      }]}
      > 
      <PhoneInput
      
        style={{width:310,marginLeft:310}}
        disabled={disabled}
      />

      

      </Form.Item>
      <div hidden={companyField}>
      <Form.Item
      name="companyName"
      label='Company Name'
      rules={
        [
          {
            message:"required"
          }
        ]
      }
      >
      <Input  style={{width:280,marginLeft:165}} placeholder='Company Name' disabled={disabled} ></Input>
      </Form.Item>
      </div>
      <Form.Item>
        <Button type='primary' htmlType='submit'>CREATE ROLE</Button>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={reset}>CANCEL</Button>
      </Form.Item>
      
    </Form>
    </>
  )
}