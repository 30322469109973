import React,{useState} from 'react'
import {Card,Col,Row,Typography,Input,Select,Button,Form} from 'antd'
import { PlatformSuperAdminService } from '../PlatformSuperAdmin.service'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const {Title}=Typography

const platformSuperAdminService=new PlatformSuperAdminService()
export default ()=>{
  const [disabled,setDisabled]=useState(true)
  const [addedView,setAddedView]=useState(false)
  const [errorView,setErrorView]=useState(false)
  const [form]=Form.useForm()
  const [submit,setSubmit]=useState(false)

  const onFinished=async (values)=>{
    setSubmit(true)
    const response=await platformSuperAdminService.addServiceProvider(values)
    if(response.success)
    {
      reset()
      setAddedView(true)
      
    }
    else
    {
      setErrorView(true)
    }
    setSubmit(false)
  }

  const onChanged=()=>{
    setAddedView(false)
    setErrorView(false)
    setDisabled(false)
  }
  const reset=()=>{
    form.resetFields()
    setAddedView(false)
    setErrorView(false)
    setDisabled(true)
  }
  return(
    <>
    <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            {addedView && <h3 style={{color:'green',textAlign:'center'}}>Service Provider Added</h3>}
            {errorView && <h3 style={{color:'red',textAlign:'center'}}>Some error occured!</h3>}
            Add Service Providers
          </Title>
          <Form
          form={form}
          onFinish={onFinished}
          >
            <Form.Item
            name="type"
            label="Type of service Provider"
            rules={[{
              required:true,
              message:"Required!!"
            }]}
            >
              <Select defaultValue={-1} onChange={onChanged} style={{width:320}}>
                <Select.Option key={-1} value={-1}>&lt;select&gt;</Select.Option>
                <Select.Option key={0} value='Complinace'>Complinace</Select.Option>
                <Select.Option key={1} value='Exchange'>Exchange</Select.Option>
                <Select.Option key={2} value='Broker Dealer'>Broker Dealer</Select.Option>
                <Select.Option key={3} value='Custodian'>Custodian</Select.Option>
                <Select.Option key={4} value='Bank'>Bank</Select.Option>
              </Select>

            </Form.Item>
            <Form.Item
            name="companyName"
            label="Company Name"
            rules={[{
              required:true,
              message:"Required!!"
            }]}
            
            >
            <Input style={{width:320,marginLeft:50}} disabled={disabled}></Input>
            </Form.Item>
            <Form.Item
            name="country"
            label="Company Registered Country"
            rules={[{
              required:true,
              message:"Required!!"
            }]}
            
            >
              <Input style={{width:320,marginRight:35}} disabled={disabled}></Input>
            </Form.Item>
            <Form.Item
            name="description"
            label="Company Description"
            rules={[{
              required:true,
              message:"Required!!"
            }]}
            
            >
              <Input.TextArea disabled={disabled} style={{width:320,marginLeft:20}}></Input.TextArea>
            </Form.Item>
            <Form.Item
            name="firstName"
            label="First Name"
            rules={[{
              required:true,
              message:"Required!!"
            }]}
            
            >
            <Input disabled={disabled} style={{width:320,marginLeft:80}}></Input>
            </Form.Item>
            <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{
              required:true,
              message:"Required!!"
            }]}
            
            >
            <Input disabled={disabled} style={{width:320,marginLeft:80}}></Input>
            </Form.Item>
            <Form.Item
            name="contactNumber"
            label="Contact Number"
            rules={[{
              required:true,
              message:"Required!!"
            }]}
            
            >
            <PhoneInput disabled={disabled} style={{width:320,marginLeft:270}}></PhoneInput>
            </Form.Item>
            <Form.Item
            name="email"
            label="Email"
            rules={[{
              required:true,
              message:"Required!!"
            }]}
            
            >
            <Input disabled={disabled} style={{width:320,marginLeft:100}}></Input>
            </Form.Item>
            <Form.Item
            name="address"
            label="Address"
            rules={[{
              required:true,
              message:"Required!!"
            }]}
            
            >
            <Input.TextArea disabled={disabled} style={{width:320,marginLeft:90}}></Input.TextArea>
            </Form.Item>
            <Form.Item>
            <Button htmlType='submit' type='primary' disabled={disabled} loading={submit}>ADD</Button>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={reset} disabled={disabled}>CANCEL</Button>
            </Form.Item>
          </Form>
      </Card>
      </Col>
     </Row>
    </>
  )
}