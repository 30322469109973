import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import List from './List';
import New from './New';
import Details from './Details';
import Edit from './Edit';



export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} exact>
        <List/>
      </Route>
      <Route path={`${path}/new`}>
        <New/>
      </Route>
      <Route path={`${path}/details/:vestingScheduleIndex`}>
        <Details/>
      </Route>
      <Route path={`${path}/edit/:vestingScheduleIndex`}>
        <Edit/>
      </Route>
    </Switch>
  );
}