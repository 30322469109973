import React, { useState, useEffect } from "react";
import { Result, Button, Card, Col, Row, Typography, Table } from "antd";
import { InvestorService } from "../Investor.service";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../Shared/Auth.service";
import moment from 'moment';

const { Title } = Typography;
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();

const columns = [
  {
    title: "ISSUER",
    dataIndex: "company",
    key: "issuer",
  },
  {
    title: "TOKEN",
    dataIndex: "tokenName",
    key: "token",
    render: (key, value) => <span>{value.token.tokenName}</span>,
  },
  {
    title: "PAYMENT MODE",
    dataIndex: "modeOfFundTransfer",
    key: "modeOfFundTransfer",
  },
  {
    title: "PAYMENT DATE & TIME",
    dataIndex: "creationTS",
    key: "creationTS",
    render: (text) => <span>{moment(text).format('LLL')}</span>
  },
  {
    title: "AMOUNT",
    dataIndex: "amountToInvest",
    key: "amountToInvest",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    render: (key, obj) => {
      return (
        <>
          {obj.accept && <span>Accepted</span>}
          {obj.reject && <span>Rejected</span>}
          {obj.accept == false && obj.reject == false && (
            <span>In Progress</span>
          )}
        </>
      );
    },
  },
  {
    title: "TOKEN TO BE RECEIVED",
    dataIndex: "no_of_tokens",
    key: "no_of_tokens",
    render: (key, obj) => {
      return (
        <>
          {obj.accept && <span>{key}</span>}
          {obj.reject && <span>0</span>}
          {obj.accept == false && obj.reject == false && (
            <span>In Progress</span>
          )}
        </>
      );
    },
  },
];
export default () => {
  const history = useHistory();
  const { userInfo } = useUserContext();
  const [currentStatus, setCurrentStatus] = useState(null);
  const [paymentList, setPaymentList] = useState(null);
  useEffect(() => {
    (async () => {
      console.log(userInfo._id);
      const response = await investorService.getPayments({
        investorId: userInfo._id,
      });
      if (response.success && response.data) {
        console.log(response);
        if (response.data.length <= 0) {
          setCurrentStatus("notSubmitted");
        } else {
          setCurrentStatus("submitted");
          setPaymentList(response.data);
        }
      }
    })();
  }, [userInfo._id]);
  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Payments
            </Title>
            <div hidden={currentStatus !== "notSubmitted"}>
              <Result
                status="warning"
                title="No payments Found!"
                subtitle="you have not done any payment."
                extra={
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => history.push("/investor/home")}
                  >
                    Home
                  </Button>
                }
              ></Result>
            </div>
            <div hidden={currentStatus !== "submitted"}>
              <Table columns={columns} dataSource={paymentList}></Table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
