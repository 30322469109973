import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Table,
  Spin,
  Button,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Popconfirm,
  message,
} from 'antd';
import {
  APIResponse,
  OfferingModelScreenAction,
  TokenOfferingModel,
  TokenPhase,
} from '../../../../Shared/interfaces';
import moment from 'moment';
import { TokenConfigurationService } from '../../../TokenConfiguration.service';
import { PlusOutlined } from '@ant-design/icons';
import BigNumber from 'bignumber.js';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const tokenConfigurationService = new TokenConfigurationService();

export default (props: {
  screen: OfferingModelScreenAction;
  setScreen: (action: OfferingModelScreenAction) => void;
  editPhase: (req: any) => Promise<APIResponse>;
}) => {
  const { screen, setScreen, editPhase } = props;

  const [tokenPhase, setTokenPhase] = useState<TokenPhase>();

  const [submitting, setSubmitting] = useState<boolean>();
  const [bonusOffers, setBonusOffers] = useState([
    { key: 0, minInvestmentAmount: { min: 0, max: 0 }, bonusPercent: 0 },
  ]);
  const [count, setCount] = useState(1);
  const [offeringModel, setOfferingModel] = useState<TokenOfferingModel>();

  useEffect(() => {
    (async () => {
      if (!screen) return; //status !== 'phase'

      const [phaseRes, OferingModelRes] = await Promise.all([
        tokenConfigurationService.getPhase(screen.tokenPhaseId as string),
        tokenConfigurationService.getOfferingModelById({
          modelId: screen.modelId as string,
        }),
      ]);

      const _tokenPhase: TokenPhase = phaseRes.data;
      const _offeringModel = OferingModelRes.data as TokenOfferingModel;

      setTokenPhase(_tokenPhase);
      setOfferingModel(_offeringModel);

      setBonusOffers(
        _tokenPhase.bonusOffers.map((offer, index) => ({
          key: 1 + index,
          minInvestmentAmount: offer.minInvestmentAmount,
          bonusPercent: offer.bonusPercent,
        }))
      );

      setCount((c) => c + _tokenPhase.bonusOffers.length);
    })();
  }, [screen]);

  const columns = [
    {
      title: 'Minimum Investment Amount',
      dataIndex: 'minInvestmentAmount',
      render: (value, record) => {
        return (
          <>
            {(tokenPhase?.startDate as number) <= Date.now() ? (
              value.min
            ) : (
              <InputNumber
                min={
                  bonusOffers.findIndex((offer) => offer.key === record.key) > 0
                    ? bonusOffers[
                        bonusOffers.findIndex(
                          (offer) => offer.key === record.key
                        ) - 1
                      ].minInvestmentAmount.max + 1
                    : 0
                }
                value={value.min}
                onKeyDown={(e) => {
                  e.key === 'Enter' && e.target['blur']();
                }}
                onChange={(e) =>
                  editBonusOffer(
                    e as number,
                    record,
                    'minInvestmentAmount',
                    'min'
                  )
                }
              />
            )}
            {' - '}
            {(tokenPhase?.startDate as number) <= Date.now() ? (
              value.max
            ) : (
              <InputNumber
                min={value.min + 1}
                value={value.max}
                onKeyDown={(e) => {
                  e.key === 'Enter' && e.target['blur']();
                }}
                onChange={(e) =>
                  editBonusOffer(
                    e as number,
                    record,
                    'minInvestmentAmount',
                    'max'
                  )
                }
              />
            )}
          </>
        );
      },
    },
    {
      title: '% Bonus',
      dataIndex: 'bonusPercent',
      render: (value, record) => {
        return (
          <>
            {(tokenPhase?.startDate as number) <= Date.now() ? (
              value
            ) : (
              <InputNumber
                disabled={(tokenPhase?.startDate as number) <= Date.now()}
                min={0}
                max={100}
                value={value}
                onKeyDown={(e) => {
                  e.key === 'Enter' && e.target['blur']();
                }}
                onChange={(e) =>
                  editBonusOffer(e as number, record, 'bonusPercent')
                }
              />
            )}
          </>
        );
      },
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (text, record) =>
        bonusOffers.length > 1 &&
        bonusOffers.findIndex((offer) => offer.key === record.key) >
          (tokenPhase?.bonusOffers.length as number) - 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteBonusOffer(record.key)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const addBonusOffer = () => {
    setCount((prev) => prev + 1);
    const min =
      bonusOffers.length > 0
        ? bonusOffers[bonusOffers.length - 1].minInvestmentAmount.max + 1
        : 0;
    setBonusOffers((prev) => [
      ...prev,
      {
        key: count,
        minInvestmentAmount: { min, max: min + 1 },
        bonusPercent: 0,
      },
    ]);
  };

  const deleteBonusOffer = (key: number) => {
    setBonusOffers((prev) => [...prev.filter((item) => item.key !== key)]);
  };

  const editBonusOffer = (
    value: number,
    record,
    dataIndex: 'minInvestmentAmount' | 'bonusPercent',
    range?: 'min' | 'max'
  ) => {
    // if(!value && value !== 0) return;
    if (!new BigNumber(value).isGreaterThanOrEqualTo(0)) return;

    const newData = [...bonusOffers];
    const index = newData.findIndex((offer) => offer.key === record.key);

    if (dataIndex === 'minInvestmentAmount') {
      newData[index] = {
        ...newData[index],
        [dataIndex]: { ...newData[index][dataIndex], [range as string]: value },
      };
    } else {
      newData[index] = { ...newData[index], [dataIndex]: value };
    }
    setBonusOffers(newData);
  };

  const _editPhase = async (formValue) => {
    const req = { ...formValue };
    req.tokenPhaseId = screen.tokenPhaseId;
    req.startDate = (req.datesOfPhase[0].toDate() as Date).getTime();
    req.endDate = (req.datesOfPhase[1].toDate() as Date).getTime();
    req.bonusOffers = [...bonusOffers].map((offer) => ({
      minInvestmentAmount: offer.minInvestmentAmount,
      bonusPercent: offer.bonusPercent,
    }));

    if (
      !(
        (req.bonusOffers as TokenPhase['bonusOffers'])[0].minInvestmentAmount
          .min >= (offeringModel?.investmentAmounts.min as number)
      )
    ) {
      return message.error(
        'Min Bonus Amount should be greather than or equal to Min Investment Amount of the model'
      );
    }

    setSubmitting(true);
    await editPhase(req);
    // console.log(req);
    setScreen({ ...screen, component: 'offering-models-details' });
    setSubmitting(false);
  };

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          {!tokenPhase && (
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </div>
          )}
          {tokenPhase && (
            <>
              <Title level={2} style={{ textAlign: 'center' }}>
                Edit Phase
              </Title>
              <Form
                {...formItemLayout}
                initialValues={{
                  phaseName: tokenPhase.phaseName,
                  datesOfPhase: [
                    moment(tokenPhase.startDate),
                    moment(tokenPhase.endDate),
                  ],
                  bonusFloor: tokenPhase.bonusFloor,
                }}
                labelAlign="left"
                onFinish={_editPhase}
              >
                <br />
                <br />

                <Form.Item
                  label={`Enter the name of the phase`}
                  name="phaseName"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input placeholder="Enter the name of the phase" />
                </Form.Item>

                <Form.Item
                  name="datesOfPhase"
                  label="Dates of Phase"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      message: 'Please select time!',
                    },
                  ]}
                >
                  <RangePicker
                    allowClear={false}
                    disabled={[
                      tokenPhase.startDate <= Date.now(),
                      tokenPhase.endDate <= Date.now(),
                    ]}
                    showTime
                    format="MMMM DD, YYYY HH:mm:ss"
                  />
                </Form.Item>

                <Form.Item
                  name="bonusFloor"
                  label="Bonus Floor"
                  rules={[
                    {
                      required: true,
                      message: 'Please select bonus',
                    },
                  ]}
                >
                  <InputNumber min={0} placeholder={'Bonus Floor'} />
                </Form.Item>

                <Table
                  title={() => <Title level={3}>Offer Bonus</Title>}
                  columns={columns}
                  dataSource={bonusOffers}
                  pagination={false}
                  footer={() => (
                    <Button
                      disabled={(tokenPhase?.startDate as number) <= Date.now()}
                      onClick={addBonusOffer}
                    >
                      <PlusOutlined /> Click here to add additional Bonus
                      offerings
                    </Button>
                  )}
                ></Table>

                <br />
                <br />
                <div style={{ textAlign: 'right' }}>
                  <Button
                    style={{ marginRight: '5px' }}
                    size="large"
                    onClick={() =>
                      setScreen({
                        ...screen,
                        component: 'offering-models-details',
                      })
                    }
                  >
                    BACK
                  </Button>
                  <Button
                    size="large"
                    loading={submitting}
                    type="primary"
                    htmlType="submit"
                  >
                    SAVE
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
