import React from 'react';
import {Modal, Typography, Row, Col, Form, Radio} from 'antd';


const {Title} = Typography;

const typeOfSecurityRadioStyle = {
  display: "block",
  // height: '30px',
  lineHeight: "30px",
};

export default function TxFeeDelegationModal(prop: {
  isVisible: boolean,
  onSubmit: (fields: {delegate: boolean}) => void,
  onCancel: () => void
  submitting?: boolean,
  cancelling?: boolean
}) {

  const [form] = Form.useForm();

  return (
    <Modal
      title={`Transaction forwarding`}
      okText={'Submit'}
      visible={prop.isVisible}
      onOk={() => form.submit()}
      cancelText={'Cancel'}
      onCancel={prop.onCancel}
      width={'700px'}
    >
      <Row>
        {prop.isVisible &&
          <>
            <Col span={24}>
              <Title level={4}>Do you want to forward this Transaction?</Title>
              <br/>
            </Col>

            <Col span={24}>
              <Form
                form={form}
                onFinish={e => {
                  prop.onSubmit(e);
                  form.resetFields();
                }}
              >

                <Form.Item
                  // labelCol={{ push: 2 }}
                  // wrapperCol={{ push: 2 }}
                  style={{ marginBottom: 0 }}
                  // label={`Select one option`}
                  name="delegate"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true} style={typeOfSecurityRadioStyle}>
                      YES (Company will cover Transaction fee. Your signature is required).
                    </Radio>
                    <Radio value={false} style={typeOfSecurityRadioStyle}>
                      NO (You'll cover Transaction Fee).
                    </Radio>

                  </Radio.Group>

                </Form.Item>

              </Form>

            </Col>
          </>
        }
      </Row>

    </Modal>
  )
    ;

}