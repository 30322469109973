import { Button, Card, Col, Descriptions, Input, Modal, notification, Radio, Result, Row, Spin, Table, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { CryptoAccount, WithdrawalRequest, User, TokenConfigurationProcess, SymbolDetailsAndSTData } from '../../../Shared/interfaces';
import { WithdrawalRequestsService } from '../WithdrawalRequests.service';
import moment from 'moment'
import { ApproversAndAccountsService } from '../../ApproversAndAccounts/ApproversAndAccounts.service';
import { SharedService } from '../../../Shared/Shared.service';
import { AuthService } from '../../../Shared/Auth.service';
import { TokenConfigurationService } from '../../../TokenConfigurations/TokenConfiguration.service';
import { SecurityTokenRegistryService } from '../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { SecurityTokenService } from '../../../Shared/SecurityToken/SecurityToken.service';
import { MetamaskService } from '../../../Shared/Metamask.service';
import BigNumber from 'bignumber.js';
import TransactionModal from '../../../Shared/TransactionModal';


const {Title} = Typography;

const withdrawalRequestsService = new WithdrawalRequestsService();
const approversAndAccountsService = new ApproversAndAccountsService();
const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const securityTokenService = new SecurityTokenService();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};


export default function ListWithdrawalRequests() {

  const [loading, setLoading] = useState(true);
  const [withdrawalRequests, setWithdrawalRequests] = useState<WithdrawalRequest[]>();
  const [cryptoAccounts, setCryptoAccounts] = useState<CryptoAccount[]>();
  const [selectedDestAccount, setSelectedDestAccount] = useState<string>();
  const [amount, setAmount] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [approvers, setApprovers] = useState<User[]>();
  const {userInfo} = useUserContext();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [availableToWithdraw, setAvailableToWithdraw] = useState('');
  const [pendingWithdrawalAmount, setPendingWithdrawalAmount] = useState('');
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [actionOption, setActionOption] = useState<'request' | 'approve' | 'reject' | 'withdraw'>();
  const [selectedRecord, setSelectedRecord] = useState<WithdrawalRequest>();


  const totalAvailable = new BigNumber(availableToWithdraw).times(new BigNumber(10).pow(-18)).minus(pendingWithdrawalAmount).decimalPlaces(18).toString(10)

  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;
      
      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);
  

      const [resWithdrawalRequests, resAmountUnderReview, resCryptoAccounts, resApprovers, _availableToWithdraw] =await Promise.all([
        withdrawalRequestsService.getWithdrawalRequests(),
        withdrawalRequestsService.getPendingWithdrawalAmount(),
        approversAndAccountsService.getCryptoAccounts(),
        approversAndAccountsService.getApprovers(),
        securityTokenService.availableToWithdraw(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      ]);

      const _withdrawalRequests = resWithdrawalRequests.data;
      const _amountUnderReview = resAmountUnderReview.data;
      const _cryptoAccounts = resCryptoAccounts.data;
      const _approvers = resApprovers.data;

      setWithdrawalRequests(_withdrawalRequests);
      setPendingWithdrawalAmount(_amountUnderReview);
      setCryptoAccounts(_cryptoAccounts);
      setApprovers(_approvers);
      setAvailableToWithdraw(_availableToWithdraw);
      setLoading(false);

    })();
  }, [userInfo]);


  const columns = [
    {
      title: 'TRANSFER ID',
      dataIndex: '_id'
    },
    {
      title: 'DATE OF REQUEST',
      dataIndex: 'creationTS',
      render: (value: string) => moment(value).format('lll')
    },
    {
      title: 'AMOUNT TO WITHDRAW (ETH)',
      dataIndex: 'amount'
    },
    {
      title: 'DESTINATION ACCOUNT',
      dataIndex: 'wallet',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (value: WithdrawalRequest['status']) => {
        if(value === 'pending') return 'Under Review';
        if(value === 'rejected') return 'Rejected';
        if(value === 'verified') return 'Approved';
      }
    },
    // {
    //   title: 'ACTION',
    //   render: (value, record: User) => {
    //     return (
    //       <Button danger onClick={() => {
    //        setSubmitting(false);
    //        setProviderToRemove(record);
    //       }}>
    //         Remove
    //       </Button>
    //     );
    //   }
    // }
  ];

  const isApprover = approvers?.find(approver => approver._id === userInfo?._id);

  if(isApprover || userInfo?.role === 'issuer token admin') {
    columns.push(
      {
      title: 'ACTION',
      render: (value, record: WithdrawalRequest) => {
        return (
          <>
          {userInfo?.role === 'issuer token admin' && 
            <>
              <Button disabled={record.status !== 'verified'} type='link' onClick={() => {
                setSelectedRecord(record);
                openConfirmationModal('withdraw');
                // withdrawETH(record.wallet, record._id, record.amount)
              }
                }>
                Withdraw
              </Button>
            </>
          }

          {isApprover && 
            <>
              <Button disabled={record.approvedBy.includes(userInfo?._id as string) || record.rejectedBy.includes(userInfo?._id as string) || record.status !== 'pending'} type='link' onClick={() => {
                setSelectedRecord(record);
                openConfirmationModal('approve');
                // approveWithdrawalRequest(record._id)
              }}>
                Approve
              </Button>
              <Button disabled={record.approvedBy.includes(userInfo?._id as string) || record.rejectedBy.includes(userInfo?._id as string) || record.status !== 'pending'} type='link' danger onClick={() => {
                setSelectedRecord(record);
                openConfirmationModal('reject');
                // rejectWithdrawalRequest(record._id)
              }}>
                Reject
              </Button>
            </>
          }
          </>
        );
      }
    } as any
    );
  }


  const updateAmount = (value) => {
    if(new BigNumber(value).isGreaterThanOrEqualTo(0)) return setAmount(value);
    if(!value) return setAmount('');
  }


  const approveWithdrawalRequest = async(requestId: string) => {
    const response = await withdrawalRequestsService.approveWithdrawalRequest({requestId});

    if (response.success) {
      notification.success({message: 'Success', description: 'Request Approved succesfully'});

      const _withdrawalRequests = sharedService.clone(withdrawalRequests);

      const withdrawalRequest = _withdrawalRequests?.find(request => request._id === requestId) as WithdrawalRequest ;

      // withdrawalRequest.approvedBy.push(userInfo?._id as string);

      const approvedBy = [...new Set([...withdrawalRequest.approvedBy, userInfo?._id as string])];
      
      const approversIds = approvers?.map(approver => approver._id) as string[];
      
      const isPending = approversIds.find(approverId => !approvedBy.includes(approverId));
      
      withdrawalRequest.approvedBy = approvedBy;
      withdrawalRequest.status = isPending? 'pending' : 'verified';

      setWithdrawalRequests(_withdrawalRequests);
      setIsConfirmationModalVisible(false);
    
    } else {
      notification.error({message: 'Error', description: response.error.message});
    }
  }


  const rejectWithdrawalRequest = async(requestId: string) => {
    const response = await withdrawalRequestsService.rejectWithdrawalRequest({requestId});

    if (response.success) {
      notification.success({message: 'Success', description: 'Request Rejected succesfully'});

      const _withdrawalRequests = sharedService.clone(withdrawalRequests);
      const withdrawalRequest = _withdrawalRequests?.find(request => request._id === requestId) as WithdrawalRequest;

      const rejectedBy = [...new Set([...withdrawalRequest.rejectedBy, userInfo?._id as string])];

      withdrawalRequest.rejectedBy = rejectedBy;
      withdrawalRequest.status = 'rejected';

      setWithdrawalRequests(_withdrawalRequests);
      setPendingWithdrawalAmount(prev => new BigNumber(prev).minus(withdrawalRequest.amount).toString(10));
      setIsConfirmationModalVisible(false);
    
    } else {
      notification.error({message: 'Error', description: response.error.message});
    }
  }



  
  const newWithdrawalRequest = async() => {
    if(!selectedDestAccount || !amount) return notification.error({message: 'Error', description: 'There are empty fields'});
    if(new BigNumber(amount).isEqualTo(0)) return notification.error({message: 'Error', description: 'Amount has to be greather than zero'});
    if(new BigNumber(amount).isGreaterThan(totalAvailable)) return notification.error({message: 'Error', description: 'Invalid amount'});

    setSubmitting(true);

    const response = await withdrawalRequestsService.newWithdrawalRequest({amount, destAccount: selectedDestAccount});

    if (response.success) {
      notification.success({message: 'Success', description: 'Request sent succesfully'});

      const requestId = response.data;

      const _withdrawalRequests = sharedService.clone(withdrawalRequests);

      _withdrawalRequests?.push({
        _id: requestId,
        status: 'pending',
        creationTS: Date.now(),
        wallet: selectedDestAccount,
        searchAbleWallet: selectedDestAccount.toLowerCase(),
        companyId: '',
        approvedBy: [],
        rejectedBy: [],
        apiKey: '',
        amount
      });

      setWithdrawalRequests(_withdrawalRequests);
      setPendingWithdrawalAmount(prev => new BigNumber(prev).plus(amount).toString(10));
      setIsConfirmationModalVisible(false);
      setAmount('');
      
    } else {
      notification.error({message: 'Error', description: response.error.message});
      
    }

    setSubmitting(false);
  }



  const withdrawETH = async(receiverAccount: string, requestId: string, amount: string) => {
    setIsModalVisible(true);
    setTransactions([
      {details: 'Withdrawing ETH', submitting: true}
    ]);

    try {

      // console.log(receiverAccount);
      // console.log(new BigNumber(amount).times(new BigNumber(10).pow(18)).decimalPlaces(0).toString(10)) 

      const _amount = new BigNumber(amount).times(new BigNumber(10).pow(18)).decimalPlaces(0).toString(10);

      const receipt = await securityTokenService.withdrawFromContract(
        symbolDetailsAndSTData?.securityTokenData.contractAddress as string, 
        selectedWallet as string,
        receiverAccount,
        _amount
      );

      setTransactions(prev => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
        return current;
      });

      if(receipt?.status) {
        withdrawalRequestsService.markRequestAsIssued({requestId}).catch(console.error);

        const _withdrawalRequests = sharedService.clone(withdrawalRequests);
        setWithdrawalRequests(_withdrawalRequests?.filter(request => request._id !== requestId));
        
        setAvailableToWithdraw(prev => new BigNumber(prev).minus(_amount).toString(10));
        setPendingWithdrawalAmount(prev => new BigNumber(prev).minus(amount).toString(10));
        setIsConfirmationModalVisible(false);

      }

      
    } catch (err) {
      console.error(err);
    }

    setTransactions(prev => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });
  }

  const openConfirmationModal = async(option: "request" | "approve" | "reject" | "withdraw") => {
    if(option === 'request') {
      if(!selectedDestAccount || !amount) return notification.error({message: 'Error', description: 'There are empty fields'});
      if(new BigNumber(amount).isEqualTo(0)) return notification.error({message: 'Error', description: 'Amount has to be greather than zero'});
      if(new BigNumber(amount).isGreaterThan(totalAvailable)) return notification.error({message: 'Error', description: 'Invalid amount'});
    }

    setActionOption(option);
    setIsConfirmationModalVisible(true);
  }


  const submitAction = async() => {
    if(actionOption === 'request') return newWithdrawalRequest();
    
    const record = selectedRecord;
    if(!record) return;

    if(actionOption === 'approve') approveWithdrawalRequest(record._id);
    else if(actionOption === 'reject') rejectWithdrawalRequest(record._id);
    else withdrawETH(record.wallet, record._id, record.amount);
  }



  
  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={23}>
        {loading &&  
          <div style={{textAlign:'center'}}>
            <br/>
            <Spin size='large'/>
          </div>
        }
        {!loading && 
          <Card>
            <Title level={1} style={{textAlign:'center'}}>Withdrawal and Transfer Request Approvers</Title>

            {!symbolDetailsAndSTData?.symbolDetails.isDeployed && 
              <>
                <Result
                  title={`Security Token not deployed`}/>
              </>
            }

            {symbolDetailsAndSTData?.symbolDetails.isDeployed && 
              <>
                {userInfo?.role === 'issuer token admin' && selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() &&
                  <>
                    <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                    <Result
                      status="error"
                      title = {
                        <p>
                          Select the wallet {' '}
                          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}>
                            {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner.toLowerCase())}
                          </a> 
                          {' '} in order to Withdraw your Tokens
                        </p>
                      }
                    />
                  </>
                }

                {(userInfo?.role !== 'issuer token admin' || selectedWallet?.toLowerCase() === symbolDetailsAndSTData.symbolDetails.owner.toLowerCase()) &&
                  <>
                    <Table
                      columns={columns}
                      dataSource={withdrawalRequests}
                      rowKey='_id'
                      pagination={false}
                    />

                    <br/>
                    {userInfo?.role === 'issuer token admin' && 
                      <>
                      <Descriptions column={2} bordered>
                        <Descriptions.Item label="Available balance in Smart Contract">
                          {new BigNumber(availableToWithdraw).times(new BigNumber(10).pow(-18)).toString(10)} ETH
                        </Descriptions.Item>

                        <Descriptions.Item label="Pending withdrawal">
                        {pendingWithdrawalAmount} ETH
                        </Descriptions.Item>

                        <Descriptions.Item span={2} label=" Available to Withdraw">
                          {totalAvailable} ETH
                        </Descriptions.Item>
                        
                      </Descriptions>
                      <br/><br/>
                        {/* <p>
                          Available balance in Smart Contract: {new BigNumber(availableToWithdraw).times(new BigNumber(10).pow(-18)).toString(10)} ETH
                        </p>
                        <p>
                          Pending withdrawal: {pendingWithdrawalAmount} ETH
                        </p>
                        <p>
                          Available to Withdraw: {totalAvailable} ETH
                        </p> */}

                        {isApprover && 
                          <> 
                          <div style={{textAlign: 'center'}}>
                            <p style={{fontWeight: 'bold'}}>
                              How much do you want to withdraw (ETH)
                            </p>
                            <Input value={amount} onChange={e => updateAmount(e.target.value)} style={{width: '300px'}}/>

                            <br/><br/>
                            
                            <p style={{fontWeight: 'bold'}}>
                              Send to Account
                            </p>

                            <Radio.Group onChange={e => setSelectedDestAccount(e.target.value)} value={selectedDestAccount}>
                              {cryptoAccounts?.map((cryptoAccount, index) => 
                                <Radio key={index} style={radioStyle} value={cryptoAccount.wallet}>
                                  ({cryptoAccount.name}) {cryptoAccount.wallet}
                                </Radio>
                              )}
                            </Radio.Group>

                            <br/><br/>
                            <Button loading={submitting} onClick={() => {
                              openConfirmationModal('request');
                            }} type="primary">
                              Withdraw
                            </Button>
                          </div>
                          </>
                        }
                      </>
                    }
                    <br/><br/>
                  </>
                }
              </>
            }

          </Card>
        }

        </Col>
      </Row>

      <TransactionModal
        title = {'Withdrawal'}
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />

      <Modal
        visible={isConfirmationModalVisible}
        okText={'YES'}
        onOk={submitAction}
        okButtonProps={{loading: submitting}}
        onCancel={()=> setIsConfirmationModalVisible(false)}
        cancelText={'NO'}
        title={'Confirm'}>
          {actionOption === 'request' && 
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Are you sure you want to perform this request?</p>
          }
          {actionOption === 'approve' && 
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Are you sure you want to approve this request?</p>
          }
          {actionOption === 'reject' &&
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Are you sure you want to reject this request?</p>
          }
          {actionOption === 'withdraw' && 
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Are you sure you want to perform this withdrawal?</p>
          }
      </Modal>
    </>
  );
}