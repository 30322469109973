import React from 'react' 
import { Row, Col ,Card,Typography} from 'antd'
import CreateAdminRoleForm from './CreateAdminRoleForm'

const {Title}=Typography


export default ()=>{

  return(
    <>
    <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Create Admin Role
          </Title>
          <CreateAdminRoleForm/>
      </Card>
      </Col>
     </Row>
  </>
  )
}