import { MetamaskService } from '../../../Metamask.service';
import { UserLockedAmount } from '../../../interfaces';
import {TrustedForwarderService} from "../../../TrustedForwarder.service";

const trustedForwarderService = new TrustedForwarderService();

export default class GeneralTransferManagerFacet {
  contract = {
    ABI: require('./ABI.json'),
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async USDPrice(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.USDPrice().call()) as string;
  }

  async lastUpdateUSDPrice(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .lastUpdateUSDPrice()
      .call()) as string;
  }

  async calculateTotalLockedAmount(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .calculateTotalLockedAmount(_from)
      .call()) as string;
  }

  async calculateTotalLockedAmountAccredited(
    tokenAddress: string,
    _from: string
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .calculateTotalLockedAmountAccredited(_from)
      .call()) as string;
  }

  async getUserLockedAmountList(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getUserLockedAmountList(_from)
      .call()) as UserLockedAmount[];
  }

  async calculateAffiliateAmountSentIn3Months(
    tokenAddress: string,
    _from: string
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .calculateAffiliateAmountSentIn3Months(_from)
      .call()) as string;
  }

  async calculateAffiliateSellLimit(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .calculateAffiliateSellLimit()
      .call()) as string;
  }

  async calculateAffiliateTokenAmountSent(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .calculateAffiliateTokenAmountSent(_from)
      .call()) as string;
  }

  async calculateAffiliateUSDAmountSent(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .calculateAffiliateUSDAmountSent(_from)
      .call()) as string;
  }

  updateUSDPrice(
    tokenAddress: string,
    owner: string,
    _USDPrice: string,
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.updateUSDPrice(_USDPrice);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async allowedCountries(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.allowedCountries().call()) as string[];
  }

  async addAllowedCountries(
    tokenAddress: string,
    owner: string,
    _countries: string[],
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.addAllowedCountries(_countries);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async removeAllowedCountries(
    tokenAddress: string,
    owner: string,
    _countries: string[],
    extra: {delegate: boolean} = {delegate: false}
    ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.removeAllowedCountries(_countries);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }


  // async canTransfer(
  //   tokenAddress: string,
  //   _from: string,
  //   _to: string,
  //   _value: string,
  //   // _launchIndex: number,
  //   // _isDefaultTransfer: boolean
  // ) {
  //   const contractInstance = new this.web3.eth.Contract(
  //     this.contract.ABI,
  //     tokenAddress
  //   );
  //   return (await contractInstance.methods
  //     .canTransfer(_from, _to, _value)
  //     .call()) as [boolean, string];
  // }
}
