import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AdminRoles from './AdminRoles';
import AddAdmin from './AddAdmin';
import TeamManagment from './TeamManagment';
import AssignAdminRole from './AssignAdminRole';
import RoleManagment from './RoleManagment';
import AddAuthorizers from './AddAuthorizers';
import AssignAuthorizers from './AssignAuthorizers';


export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/admin-roles`}>
        <AdminRoles/>
      </Route>
      <Route path={`${path}/add-admin`}>
        <AddAdmin/>
      </Route>
      <Route path={`${path}/team-managment`}>
        <TeamManagment/>
      </Route>
      <Route path={`${path}/assign-admin-role`}>
        <AssignAdminRole/>
      </Route>
      <Route path={`${path}/role-managment`}>
        <RoleManagment/>
      </Route>
      <Route path={`${path}/add-authorizers`}>
        <AddAuthorizers/>
      </Route>
      <Route path={`${path}/assign-authorizers`}>
        <AssignAuthorizers/>
      </Route>
    </Switch>
  );
}