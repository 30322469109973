import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Radio,
  Input,
  Space,
  Progress,
  List,
  Popconfirm,
} from "antd";
import { IssuerCounselService } from "../IssuerCounsel.service";
import { SharedService } from "../../Shared/Shared.service";
const sharedService = new SharedService();
const issuerCounselService = new IssuerCounselService();
const { TextArea } = Input;
export default ({ currentQuestionToAnswer, onSubmit, onBack, submitting }) => {
  const [dueDiligenceForm] = Form.useForm();
  const [documentsToSubmit, setDocumentsToSubmit] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [aiDocument, setAiDocument] = useState({
    answer: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });

  const initialFileObj = {
    answer: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  };

  useEffect(() => {
    if (currentQuestionToAnswer?.answerType === "attachment") {
      setDocumentsToSubmit(currentQuestionToAnswer?.answer);
      let arr = currentQuestionToAnswer?.answer.map((data) => data.name);
      setDocuments(arr);
      setAiDocument({
        answer: {
          uploading: false,
          uploadFilePercentage: 100,
          URL: "abc",
          name: "abc",
          type: "abc",
        },
      });
      dueDiligenceForm.setFieldsValue({
        answer: currentQuestionToAnswer.answer[0],
      });
    }
  }, []);

  const submitAnswerForDueDiligence = async (data) => {
    if (currentQuestionToAnswer.answerType === "attachment") {
      data.answer = documentsToSubmit;
    }
    let dataToSend = await { ...currentQuestionToAnswer, ...data };
    await onSubmit(dataToSend);
    dueDiligenceForm.resetFields();
    setDocuments([]);
    setDocumentsToSubmit([]);
    setAiDocument(initialFileObj);
  };

  const uploadFile = (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    const fileType = sharedService.getFileType(file.name);
    setFilesObj((prev) => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    issuerCounselService.uploadFile(
      file,
      (uploadFilePercent) => {
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = true;
          current[key].uploadFilePercentage = uploadFilePercent;
          return current;
        });
      },
      (err) => {
        console.error(err);
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      },
      async (response) => {
        if (response.success) {
          const URL = response.data.url;
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].URL = URL;
            current[key].uploading = false;
            current[key].name = file.name;
            current[key].type = fileType.toLowerCase();
            return current;
          });
          let fileDetails = { [key]: { URL, name: file.name } };
          form.setFieldsValue(fileDetails);
          setDocuments((prev) => prev.concat(file.name));
          setDocumentsToSubmit((prev) => prev.concat({ URL, name: file.name }));
        } else {
          console.error(response.error);
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].uploading = false;
            return current;
          });
        }
      }
    );
  };

  const deleteItem = (index) => {
    setDocuments(documents.filter((document) => document !== documents[index]));
    setDocumentsToSubmit(
      documentsToSubmit.filter(
        (document) => document !== documentsToSubmit[index]
      )
    );
  };

  return (
    <>
      <br />
      <Form
        key="dueDiligenceAnswerForm"
        form={dueDiligenceForm}
        onFinish={submitAnswerForDueDiligence}
      >
        <Form.Item
          label={currentQuestionToAnswer?.question}
          key={currentQuestionToAnswer?.question}
        ></Form.Item>

        {currentQuestionToAnswer &&
          currentQuestionToAnswer?.answerType == "option" && (
            <Form.Item
              key="optionAnswer"
              label="Answer:"
              name="answer"
              rules={[
                {
                  required: true,
                  message: "Please select a option",
                },
              ]}
            >
              <Radio.Group disabled={submitting}>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          )}

        {currentQuestionToAnswer &&
          currentQuestionToAnswer?.answerType === "textArea" && (
            <Form.Item
              key="optionAnswer"
              label="Answer:"
              name="answer"
              rules={[
                {
                  required: true,
                  message: "Please select a option",
                },
              ]}
            >
              <TextArea disabled={submitting}></TextArea>
            </Form.Item>
          )}

        {currentQuestionToAnswer &&
          currentQuestionToAnswer?.answerType === "attachment" && (
            <Form.Item
              key="attachmentAnswer"
              label="Answer:"
              name="answer"
              rules={[
                {
                  required: true,
                  message: "Please select a option",
                },
              ]}
            >
              <Input
                type="file"
                onChange={(e) => {
                  uploadFile(e, "answer", setAiDocument, dueDiligenceForm);
                }}
                disabled={submitting}
              />
              {aiDocument.answer.uploadFilePercentage > 0 && (
                <>
                  <Progress percent={aiDocument.answer.uploadFilePercentage} />
                  <br />
                  <div align="left">
                    Files Uploaded:
                    <List itemLayout="horizontal" style={{ textAlign: "left" }}>
                      {documents?.map((obj, index) => (
                        <List.Item
                          textalign="left"
                          style={{ cursor: "pointer" }}
                          key={index}
                          actions={[
                            <Popconfirm
                              title="This item will be deleted!"
                              onConfirm={() => deleteItem(index)}
                            >
                              <Button type="primary" danger>
                                X
                              </Button>
                            </Popconfirm>,
                          ]}
                        >
                          <List.Item.Meta title={obj} />
                        </List.Item>
                      ))}
                    </List>
                  </div>
                </>
              )}
            </Form.Item>
          )}

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Submit
            </Button>
            <Button
              onClick={() => {
                onBack();
              }}
              loading={submitting}
            >
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};
