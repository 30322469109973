import { Button, Card, Spin, Form, message, Input, Row, Col, Select, Modal } from "antd"
import React, {useState, useEffect, useRef} from "react"
import ServiceProviderService from "./ServiceProvider.service"

const { Option } = Select


export default () => { 

    const [serviceProviders, setServiceProviders] = useState([])
    const [loading, setLoading] = useState([])
    const [currentServiceProvider, setCurrentServiceProvider] = useState(null)

    useEffect(() => {
        fetchServiceProviders()
    }, [])

    const fetchServiceProviders = async () => {
        setLoading(true)
        try {
            const { data } = await ServiceProviderService.listServiceProviders()
            setServiceProviders(data)
        } catch (e) {
            console.log(e)
            message.error("Something Went Wrong")
        }
        setLoading(false)
    }

    const handleEdit = async serviceProvider => {  //use same for add
        try {
            await  ServiceProviderService.editServiceProvider(serviceProvider)
            setCurrentServiceProvider(null)
            fetchServiceProviders()
        } catch (e) {
            console.log(e)
            message.error("Something Went Wrong")
        }
    }

    const handleDelete = async serviceProviderId => {
        try {
            await ServiceProviderService.deleteServiceProvider(serviceProviderId)
            fetchServiceProviders()
        } catch (e) {
            console.log(e)
            message.error("Something Went Wrong")
        }
    }

    return (
        <>
            <h3><b>Service Providers</b></h3>
		    <Row><Col offset={10}><Button
                        onClick={() => setCurrentServiceProvider({
                            phoneNo: "", 
                            email: "", 
                            accountNumber: "", 
                            accountId: ""
                        })}
                    >
                        Add Service Provider
                    </Button></Col></Row>
                    <Card><Row style={{ textColor:'white', color:'black' }}>
                        <Col span={5}><b>Associated Phone Number</b></Col>
                        <Col span={5}><b>Associated Email IDFC</b></Col>
                        <Col span={5}><b>Accont Number</b></Col>
                        <Col span={5}><b>Account ID</b></Col>
                        <Col span={4}><b>Edit</b></Col>
                    </Row></Card> 
                {loading ? <Spin /> : 
                <>
                {serviceProviders.map(serviceProvider => {
                    const {_id, phoneNo, email, accountNumber, accountId} = serviceProvider
                    return <Card key={_id}><Row> 
                        <Col span={5}>{phoneNo}</Col>
                        <Col span={5}>{email}</Col>
                        <Col span={5}>{accountNumber}</Col>
                        <Col span={5}>{accountId}</Col>
                        <Col span={4}>
                            <Button
                                onClick={() => setCurrentServiceProvider(serviceProvider)}
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={() => handleDelete(_id)}
                            >
                                Delete
                            </Button>
                        </Col>
                    </Row></Card>
                })}
                </>
                }
            
            <Modal
		closable={false}
                footer={null}
            	title={"Edit Service Provider"}
            	//onOk={handleEdit} 
            	//onCancel={() => setCurrentServiceProvider(null)}
            	visible={currentServiceProvider !== null}
            >
                <EditServiceProvider
		    onSubmit={handleEdit}
		    onCancel={() => setCurrentServiceProvider(null)}
                    serviceProvider={currentServiceProvider}
                />
            </Modal>
        </>
    )
}

const { Item } = Form

const EditServiceProvider = (
    {
        serviceProvider: {_id, type, phoneNo, email, accountNumber, accountId}, 
        onSubmit, 
        onCancel
    }) => {

    const [form] = Form.useForm()

    const formRef = useRef()

    useEffect(() => {
        if (formRef && formRef !== null) {
 	    formRef.current.setFieldsValue({type, phoneNo, email, accountNumber, accountId})
        }
    }, [])

    // const [serviceProvider, setServiceProvider] = 
    //     useState({_id, phoneNo, email, accountNumber, accountId})
    const [category, setCategory] = useState(type)

    return (
        <>
        <Form
	    form={form}
            ref={formRef}
            layout="vertical"
            onFinish={formData => onSubmit({...formData, _id})}
        >
            <Item
                name="type"
                label="Service Provider Type"
                rules={[
                    {
                        required: true,
                        message: "Please Select Provider Type"
                    }
                ]}
            >
                <Select defaultValue={type} onChange={setCategory}>
                    <Option value="other">Other</Option>
                    <Option value="wire">Wire</Option>
                </Select>
            </Item>
            <Item
                name="phoneNo"
                label="Phone Number"
                rules={[
                    {
                        required: category!=="wire",
                        message: "Please Enter the Associated Phone Number"
                    }
                ]}
            >
                <Input defaultValue={phoneNo} type="number" />
            </Item>
            <Item
                name="email"
                label="Email"
                rules={[
                    {
                        required: category!=="wire",
                        message: "Please Enter the Associated Email Address"
                    }
                ]}
            >
                <Input defaultValue={email} type="email" />
            </Item>
            <Item
                name="accountNumber"
                label="Account Number"
            >
                <Input defaultValue={accountNumber} />
            </Item>
            <Item
                name="accountId"
                label="Account ID"
		rules={[
                    {
			required: true,
                        message: "Please Enter the  Service Provider ID"
                    }
                ]}
            >
                <Input defaultValue={accountId} />
            </Item>
            <Item>
                <Row>
                    <Col>
                        <Button
                            type="secondary"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            htmlType="submit"
                            type="primary"
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Item>
        </Form>
        </>
    )
}
