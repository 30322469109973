import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Input, Button, message, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { IssuerSuperAdminService } from '../../../IssuerSuperAdmin.service';
import { TeamRole } from '../../../../Shared/interfaces';

const {Title} = Typography;


const issuerSuperAdminService = new IssuerSuperAdminService();


export default () => {
  const history = useHistory();

  const [form] = Form.useForm();
  
  const [submitting, setSubmitting] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState<TeamRole>();
  const [loading, setLoading] = useState(true);

  const {roleToDeleteId} = useParams<{roleToDeleteId: string}>();


  useEffect(() => {
    (async () => {

      const _roleToDelete = (await issuerSuperAdminService.getTeamRole(roleToDeleteId)).data;
      setRoleToDelete(_roleToDelete);
      setLoading(false);

    })();
  }, [roleToDeleteId]);





  const editTeamRole = async (formValue) => {
    const req = {roleToDeleteId, roleToAdd: formValue.roleToAdd};
    console.log(req);
    setSubmitting(true);
    const response = await issuerSuperAdminService.editTeamRole(req);

    if (response.success) {
      message.success('Role Edited successfully');
      history.push(`/issuer-super-admin/admin/role-managment`);
    } else {
      message.error(response.error.message);
    }
    setSubmitting(false);
  }

  return (
    <>
      <br/><br/>
      <Row justify="center">
         <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <Spin size='large'/>
            </div>
          }
          {!loading && 
            <Card>
              <Title level={1} style={{textAlign:'center'}}>Edit Role</Title>
              <Row justify="center">
                <Col span={20}>
                  <Card bordered={false} className='card-shadow'>
                    <Form labelAlign="left" form={form} onFinish={editTeamRole}>

                      <h3 style={{textAlign: 'center'}}>
                        <span style={{fontWeight: 'bold'}}>
                          Previews Role: {' '}
                        </span>
                        {roleToDelete?.name}
                      </h3>

                      <br/>

                      <Form.Item 
                        labelCol={{xs: { span: 24 }, sm: { span: 7, offset:3 }}}
                        wrapperCol={{xs: { span: 24 }, sm: { span: 10 }}}
                        label={`Enter the Role`}
                        name="roleToAdd"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input/>
                      </Form.Item>


                      <div style={{textAlign:'right'}}>
                        <Button style={{marginRight:'5px'}} htmlType='button' size='large' onClick={()=> history.push(`/issuer-super-admin/admin/role-managment`)}>
                          BACK
                        </Button>
                        <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                          EDIT
                        </Button>
                      </div>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Card>
          }
         </Col>

      </Row>
    </>
  );
};