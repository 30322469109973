import React from 'react'
import {Row,Col,Card,Typography} from 'antd'
import QuestionsToAnswerList from './QuestionsToAnswerList'
const {Title}=Typography

export default ()=>{
  
return(
    <>
    <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Information Questions
          </Title>
          <QuestionsToAnswerList/>
        </Card>
      </Col>
     </Row>
    </>
  )
}
