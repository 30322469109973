import { MetamaskService } from '../../../Metamask.service';
import {
  VestingScheduleFromContract,
  VestingScheduleTemplate,
} from '../../../interfaces';
import {TrustedForwarderService} from "../../../TrustedForwarder.service";

const trustedForwarderService = new TrustedForwarderService();

export default class VestingFacet {
  contract = {
    ABI: require('./ABI.json'),
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async getVestingScheduleTemplates(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getVestingScheduleTemplates()
      .call()) as VestingScheduleTemplate[];
  }

  async getVestingScheduleTemplate(tokenAddress: string, _index: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getVestingScheduleTemplate(_index)
      .call()) as VestingScheduleTemplate;
  }

  async createVestingScheduleTemplate(
    tokenAddress: string,
    owner: string,
    _name: string,
    duration: number,
    isFixedArr: boolean[],
    endOfMonthArr: number[],
    percentArr: string[],
    lockPeriodArr: number[],
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods
      .createVestingScheduleTemplate(
        this.web3.utils.utf8ToHex(_name),
        duration,
        isFixedArr,
        endOfMonthArr,
        percentArr,
        lockPeriodArr
      );

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async assignVestingScheduleTemplateMulti(
    tokenAddress: string,
    owner: string,
    _from: string[],
    _amount: string[],
    _index: string[],
    _creationTS: number[],
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.assignVestingScheduleTemplateMulti(_from, _amount, _index, _creationTS);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });

  }

  // async addVestingScheduleMulti(
  //   tokenAddress: string,
  //   owner: string,
  //   _from: string[],
  //   duration: number[],
  //   amount: string[],
  //   isFixedArr: boolean[][],
  //   endOfMonthArr: number[][],
  //   percentArr: string[][],
  //   lockPeriodArr: number[][]
  // ) {
  //   const contractInstance = new this.web3.eth.Contract(
  //     this.contract.ABI,
  //     tokenAddress
  //   );
  //   return contractInstance.methods
  //     .addVestingScheduleMulti(
  //       _from,
  //       duration,
  //       amount,
  //       isFixedArr,
  //       endOfMonthArr,
  //       percentArr,
  //       lockPeriodArr
  //     )
  //     .send({ from: owner });
  // }

  async getAllVestingScheduleOwnerAddresses(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getAllVestingScheduleOwnerAddresses()
      .call()) as string[];
  }

  async getVestingSchedule(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getVestingSchedule(_from)
      .call()) as VestingScheduleFromContract;
  }

  async hasVestingSchedule(tokenAddress: string, _from: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .hasVestingSchedule(_from)
      .call()) as boolean;
  }

  async deleteVestingSchedule(
    tokenAddress: string,
    owner: string,
    _from: string,
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.deleteVestingSchedule(_from);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async withdrawFromVesting(
    tokenAddress: string,
    owner: string
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return contractInstance.methods.withdrawFromVesting().send({ from: owner });
  }

  async getPercentageToWithdrawFromVesting(
    tokenAddress: string,
    _from: string
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods
      .getPercentageToWithdrawFromVesting(_from)
      .call()) as string;
  }
}
