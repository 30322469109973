import { APIResponse, User} from '../../Shared/interfaces';
import { environment } from '../../../environments/environment';
import axios from 'axios';
import { SharedService } from '../../Shared/Shared.service';



const sharedService = new SharedService();


export class WithdrawalRequestsService {
  private APIURL = environment.APIURL;
  

  async newWithdrawalRequest(req: {amount: string, destAccount: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/withdrawalRequests/newWithdrawalRequest`, req, {headers: await sharedService.getAuthHeader()});
  }

  async getWithdrawalRequests() {
    return axios.get<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/withdrawalRequests/getWithdrawalRequests`, {params: {}, headers: await sharedService.getAuthHeader()});
  }


  async approveWithdrawalRequest(req: {requestId: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/withdrawalRequests/approveWithdrawalRequest`, req, {headers: await sharedService.getAuthHeader()});
  }

  async rejectWithdrawalRequest(req: {requestId: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/withdrawalRequests/rejectWithdrawalRequest`, req, {headers: await sharedService.getAuthHeader()});
  }

  async getPendingWithdrawalAmount() {
    return axios.get<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/withdrawalRequests/getPendingWithdrawalAmount`, {params: {}, headers: await sharedService.getAuthHeader()});
  }

  async markRequestAsIssued(req: {requestId: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/issuerSuperAdmin/withdrawalRequests/markRequestAsIssued`, req, {headers: await sharedService.getAuthHeader()});
  }




}