import React from 'react';
import { Card, Result, Row, Col } from 'antd';
import { AuthService } from '../../Shared/Auth.service';

const useUserContext = () => new AuthService().useUserContext();


export default () => {
  const {userInfo} = useUserContext();


  return (
    <>
    <br/>
    <Row justify="center">
      <Col span={16} style={{textAlign:'center'}}>
        {userInfo?.status.registrationRequest === 'pending' && 
          <Card>
            <h2 style={{textAlign:'center'}}>Registration request submitted</h2>
            <Result
              title={`TAP team is reviewing your request`}
              // subTitle="CCAP team is reviewing your request"
              // extra={
              //   <Button key="console">
              //     Email didn't arrive?
              //   </Button>
              // }
              />
          </Card>
        }
        {userInfo?.status.registrationRequest === 'rejected' && 
          <Card>
            <h2 style={{textAlign:'center'}}>Registration request rejected</h2>
            <Result
              status="error"
              title={`You're request has been rejected`}
              />
          </Card>
        }
      </Col>
    </Row>
    </>
  );
};