import { APIResponse, User } from '../../Shared/interfaces'
import { environment } from '../../../environments/environment'
import { SharedService } from '../../Shared/Shared.service'
import axios from 'axios'

const sharedService = new SharedService();

class ServiceProviderService {

    constructor () {
        this.APIURL =  environment.APIURL
    }

    static Instance() {
        return new ServiceProviderService()
    }

    async listServiceProviders() {
        return axios.get(
            `${this.APIURL}/serviceProvider`,
            { headers: await sharedService.getAuthHeader() }
        )
    }

    async editServiceProvider(form) {
        return axios.put(
            `${this.APIURL}/serviceProvider`,
            form,
            { headers: await sharedService.getAuthHeader() }
        )
    }

    async deleteServiceProvider(id) {
        return axios.delete(
            `${this.APIURL}/serviceProvider/${id}`,
            { headers: await sharedService.getAuthHeader() }
        )
    }

}

export default ServiceProviderService.Instance()
