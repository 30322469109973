import React, { useEffect, useState } from 'react';
import {
  APIResponse,
  OfferingModelScreenAction,
  TokenConfigurationProcess,
  TokenOfferingModel,
  User,
} from '../../../../Shared/interfaces';
import { Row, Col, Typography, Spin, Descriptions } from 'antd';
import { TokenConfigurationService } from '../../../TokenConfiguration.service';

const { Title } = Typography;
const tokenConfigurationService = new TokenConfigurationService();

export default function OfferingModelsDetails(props: {
  tokenConfigurationProcess: TokenConfigurationProcess | undefined;
  userInfo: User;
  screen: OfferingModelScreenAction;
  setScreen: (action: OfferingModelScreenAction) => void;
  savePhase: (req: any) => Promise<APIResponse>;
  editPhase: (req: any) => Promise<APIResponse>;
}) {
  const [offeringModel, setOfferingModel] = useState<TokenOfferingModel>();

  const { screen } = props;

  useEffect(() => {
    (async () => {
      if (!screen.modelId) return;

      const response = await tokenConfigurationService.getOfferingModelById({
        modelId: screen.modelId,
      });

      setOfferingModel(response.data);
    })();
  }, [screen]);

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          {!offeringModel && (
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </div>
          )}

          {offeringModel && (
            <>
              <Title level={1} style={{ textAlign: 'center' }}>
                Offering Model Information
              </Title>
              <Descriptions bordered column={2}>
                <Descriptions.Item span={2} label="Name of the model">
                  {offeringModel.name}
                </Descriptions.Item>
                <Descriptions.Item label="Min Investment Amount">
                  {offeringModel.investmentAmounts.min}
                </Descriptions.Item>
                <Descriptions.Item label="Total Phases">
                  {offeringModel['totalPhases']}
                  {/* {moment(tokenPhase.endDate).format('LLL')} */}
                </Descriptions.Item>
              </Descriptions>

              {/* <PhasesList {...props} /> */}

              {/* <Phases {...props} /> */}

              <br />
              <hr style={{ backgroundColor: 'black', height: '2px' }} />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
