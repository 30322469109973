import { MetamaskService } from '../../../Metamask.service';
import {TrustedForwarderService} from "../../../TrustedForwarder.service";


const trustedForwarderService = new TrustedForwarderService();

export default class ERC1594Facet {
  contract = {
    ABI: require('./ABI.json'),
  };

  private get web3() {
    return MetamaskService.web3;
  }


  async issue(
    tokenAddress: string,
    owner: string,
    tokenHolder: string,
    value: string,
    data: string = '0x',
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method = contractInstance.methods.issue(tokenHolder, value, data);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async canTransfer(
    tokenAddress: string,
    _from: string,
    _to: string,
    _value: string,
    _data: string = '0x'
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.canTransfer(_from, _to, _value, _data).call()) as [boolean, string, string];
  }

  async redeemFrom(
    tokenAddress: string,
    owner: string,
    from: string,
    value: string,
    data: string = '0x',
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method = contractInstance.methods.redeemFrom(from, value, data);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }



}