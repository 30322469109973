import React, { useState, useEffect, useContext } from 'react'
import Web3 from 'web3';
// import { User } from './interfaces';
// import { AuthService } from './Auth.service';



// const authService = new AuthService();
// const useUserContext = () => new AuthService().useUserContext();


export class MetamaskService {

  static web3: Web3;
  static ethereum;
  public static isMetamaskAuthenticated: boolean;

  public get isMetamaskInstalled() {
    // return typeof window['ethereum'] !== 'undefined';
    const { ethereum } = window as any;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  useSelectedWallet(userId: string) {
    const [selectedWallet, setSelectedWallet] = useState<string>();
    const [networkId, setNetworkId] = useState<string>();
    const [isMetamaskAuthenticated, setIsMetamaskAuthenticated] = useState<boolean>();


    useEffect(() => {
      (async () => {
        try {

          if(!userId) return;

          
          if (!this.isMetamaskInstalled) return;

          if(MetamaskService.isMetamaskAuthenticated) return; //METAMASK ALREADY INITIALIZED

          MetamaskService.ethereum = window['ethereum'];
          
          MetamaskService.web3 = new Web3(MetamaskService.ethereum);

          
          // const wallets: string[] = await this.ethereum.enable();
          await MetamaskService.ethereum.request({ method: 'eth_requestAccounts' });
          const wallets = await MetamaskService.ethereum.request({ method: 'eth_accounts' });

          console.log(wallets);
          
          MetamaskService.isMetamaskAuthenticated = true;
          
          setSelectedWallet(wallets[0]);
          
          MetamaskService.ethereum.on('accountsChanged', (accounts: string[]) => setSelectedWallet(accounts[0]));
          
          if(MetamaskService.ethereum.hasOwnProperty("autoRefreshOnNetworkChange")) MetamaskService.ethereum.autoRefreshOnNetworkChange = false; //COMMENT IN FUTURE
          
          setNetworkId(MetamaskService.ethereum.networkVersion);
          
          MetamaskService.ethereum.on('networkChanged', (networkId: string) => setNetworkId(networkId));
          
        } catch (err) {
          MetamaskService.isMetamaskAuthenticated = false;
          console.error(err);
          if (err.message === 'User rejected provider access' || err.message === 'User denied account authorization') {
            console.log("The user didn't want to sign in!");
          } else {
            // This shouldn't happen, so you might want to log this...
            console.log('There was an issue signing you in.');
          }
        }

        setIsMetamaskAuthenticated(MetamaskService.isMetamaskAuthenticated);

      })();
    }, [userId]);

  
    return [selectedWallet, networkId, isMetamaskAuthenticated, setSelectedWallet as any, setNetworkId, setIsMetamaskAuthenticated];
  }


  static SelectedWalletContext = React.createContext
  <{
    selectedWallet: string | undefined, 
    networkId: string | undefined
  }>
  ({
    selectedWallet: undefined, 
    networkId: undefined
  });

  
  useSelectedWalletContext() {
    return useContext(MetamaskService.SelectedWalletContext);
  }



}