import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';

import './index.scss';
import store from './redux/';
import App from './App';

/*FIREBASE*/
import { environment } from './environments/environment';
// import * as firebase from 'firebase/app';
// import "firebase/firestore";
// import "firebase/auth";
// import "firebase/storage";

/*AXIOS*/
import axios from 'axios';
axios.interceptors.response.use(response => response.data);
axios.defaults.headers.post['apiKey'] = environment.apiKey;








// firebase.initializeApp(environment.firebase);




ReactDOM.render(
<Provider store={store}>
    <App />
</Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
