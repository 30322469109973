import React, {Component} from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
 
class ExportToExcel extends Component {
 
    constructor(props) {
        super(props);
    }
 
    render() {
 
        return (
            <div>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename={this.props.fileName}
                    sheet="Investor Details"
                    buttonText="Export"/>
                <table id="table-to-xls" hidden={true}>
                  <tr>
                    <td>FIRST NAME</td>
                    <td>LAST NAME</td>
                    <td>EMAIL</td>
                    <td>CONTACT NUMBER</td>
                    <td>LICENCE FRONT SIDE</td>
                    <td>LICENCE BACK SIDE</td>
                    <td>PASSPORT</td>
                  </tr>
                    {

                          <tr>
                          <td>{this.props.data.firstName}</td>
                          <td>{this.props.data.lastName}</td>
                          <td>{this.props.data.email}</td>
                          <td>{this.props.data.contactNumber}</td>
                          <td>{this.props.data.licenceFrontSide.name}</td>
                          <td>{this.props.data.licenceBackSide.name}</td>
                          <td>{this.props.data.passport.name}</td>
                          </tr>
                    }
                </table>
 
            </div>
        );
    }
}
 
export default ExportToExcel