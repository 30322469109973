import { Button, Card, Col, Form, Modal, notification, Radio, Row, Spin, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TokenConfigurationProcess } from '../../Shared/interfaces';
import { useHistory } from 'react-router-dom';
import { TokenConfigurationService } from '../TokenConfiguration.service';
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';
import { AuthService } from '../../Shared/Auth.service';

const {Title} = Typography;

const regulationRadioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const tokenConfigurationService = new TokenConfigurationService();
const sharedService = new SharedService();
const useUserContext = () => new AuthService().useUserContext();

export default function TokenConfigurationList() {

  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [isRelaunchTokenModalVisible, setIsRelaunchTokenModalVisible] = useState<boolean>();
  const [relaunchTokenForm] = Form.useForm();
  // const [relaunchRegulation, setRelaunchRegulation] = useState('');

  const [tokenConfigurationProcesses, setTokenConfigurationProcesses] = useState<TokenConfigurationProcess[]>();

  const {userInfo} = useUserContext();

  console.log(tokenConfigurationProcesses)
  console.log(tokenConfigurationProcesses?.find(process => !process.isTokenSaleClosed));



  useEffect(() => {
    (async () => {
      const _tokenConfigurationProcesses = (await tokenConfigurationService.getTokenConfigurationProcesses()).data as TokenConfigurationProcess[];

      setTokenConfigurationProcesses(_tokenConfigurationProcesses);
      setLoading(false);

    })();
  }, []);


  const columns = [
    {
      title: 'REGULATION',
      dataIndex: 'regulation',
      render: (value: TokenConfigurationProcess['regulation']) => sharedService.regOptions.find(opt => opt.value === value)?.name
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')

    }, 
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (value: TokenConfigurationProcess['status']) => value
    },
    {
      title: 'TOKEN SALE ENDED ON',
      dataIndex: 'dateTokenSaleClosed',
      render: (value: number) => value? moment(value).format('lll') : '-'

    },
    {
      title: 'ACTION',
      render: (value, record: TokenConfigurationProcess) => {
        return (
          <>
            <Button size='small' type='primary' onClick={() => history.push(`/token-configurations/${record._id}/create`)}>
              View
            </Button>

            {userInfo?.role === 'issuer counsel' && 
              <>
                <Button size='small' style={{marginLeft: '5px'}} type='primary' onClick={() => history.push(`/issuer-counsel/create-legend/${record._id}`)}>
                  Create Legend
                </Button>
                <Button size='small' style={{marginLeft: '5px'}} type='primary' onClick={() => history.push(`/issuer-counsel/remove-legend/${record._id}`)}>
                  Remove Legend
                </Button>
              </>
            }

            {userInfo?.role === 'issuer token admin' && 
              <>
                <Button size='small' style={{marginLeft: '5px'}} type='primary' onClick={() => history.push(`/issuer-token-admin/remove-legend/${record._id}`)}>
                  Remove Legend
                </Button>
              </>
            }
          </>
        );
      }
    }
  ];

  // const availableRegs = sharedService.regOptions.filter(option => !tokenConfigurationProcesses?.find(process => process.regulation === option.value));


  const openRelaunchTokenModal = () => {
    if(!tokenConfigurationProcesses?.length) return history.push(`/token-configurations/new-config/type-and-details`);

    if(tokenConfigurationProcesses?.some(process => !process.isTokenSaleClosed)) {
      return notification.error({message: 'ERROR', description: 'You have to close the last Token Sale in order to relaunch the Token'});
    }
    setIsRelaunchTokenModalVisible(true);
  }


  return (

  <>
    <br/><br/>

    <Row justify="center">

      <Col span={23}>
        {loading &&  
          <div style={{textAlign:'center'}}>
            <br/>
            <Spin size='large'/>
          </div>
        }
        {!loading && 
          <Card>
            <Title level={1} style={{textAlign:'center'}}>Token Configurations</Title>

            {userInfo?.role === 'issuer token admin' && 
              <div style={{textAlign:'right', marginBottom: '10px'}}>
                {/* <Button style={{marginRight:'5px'}} size='large' onClick={()=> setCryptoAccountModalVisible(true)}>
                  ADD CRYPTO ACCOUNTS
                </Button> */}
                <Button type="primary" size='large' onClick={openRelaunchTokenModal}>
                  {tokenConfigurationProcesses?.length? 'RELAUNCH TOKEN': 'LAUNCH TOKEN'}
                </Button>
              </div>
            }

            <Table
              columns={columns}
              dataSource={tokenConfigurationProcesses}
              rowKey='_id'
              pagination={false}
            />
          </Card>
        }
      </Col>

    </Row>


    <Modal
      title={'Relanch token'}
      okText='Confirm'
      cancelText='Cancel'
      visible={isRelaunchTokenModalVisible}
      onOk={()=>relaunchTokenForm.submit()}
      onCancel={()=>{setIsRelaunchTokenModalVisible(false); relaunchTokenForm.resetFields()}}
    >


      {!tokenConfigurationProcesses?.find(process => !process.isTokenSaleClosed)?

        <>
          {/* {availableRegs.length &&  */}
            {/* <> */}
              <p>
                Select the new Regulation for your Token
              </p>

              <Form form={relaunchTokenForm} labelAlign='right' onFinish={(formValue)=>{history.push(`/token-configurations/new-config/type-and-details?relaunchRegulation=${formValue.relaunchRegulation}`)}}>
                <Form.Item
                  name="relaunchRegulation"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required'
                    }
                  ]}
                >
                  <Radio.Group>
                    {sharedService.regOptions.map(option => (
                      <React.Fragment key={option.value}>
                        <Radio value={option.value} style={regulationRadioStyle}>{option.name}</Radio>
                      </React.Fragment>
                    ))}
                  </Radio.Group>
                </Form.Item>

              </Form>
            {/* </> */}
          {/* } */}

          {/* {!availableRegs.length && 
            <h3>No available Regulations</h3>
          } */}

        </>

        : 

        <p>
          You have to close the Token Sale first
        </p>
      
      
      }



      
    </Modal>



  </>
  );

}