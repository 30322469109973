import React, { useEffect,useState } from 'react'
import {Modal,Form,Input,Button,message, Row, Col} from 'antd'
import {AccountService} from '../Account.service'
import {AuthService} from '../../Shared/Auth.service'
import { SharedService } from '../../Shared/Shared.service';
import {deviceDetect} from 'react-device-detect'
import { useHistory } from 'react-router-dom';
const accountService=new AccountService();
const authService=new AuthService();
const useUserContext = () => authService.useUserContext();
const deviceId=deviceDetect();
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};


export default ()=>{
  const history=useHistory();
  const [visible,setVisible]=useState(true)
  const {userInfo,setUserInfo}=useUserContext()
  const [form]=Form.useForm()
  const [submitting,setSubmitting]=useState(false)
  const submitOTP=()=>{
    setVisible(false)
  }
  useEffect(()=>{
    (async ()=>{
      const response=await accountService.sendDeviceRegistrationOTP(userInfo);
    })()
  },[])
  const verifyOTP=async (otp)=>{
    setSubmitting(true)
    const response=await accountService.verifyOTP({role:userInfo.role,email:userInfo.email,date:Date.now(),otp:otp,deviceId:deviceId});
    if(response.success && response.data)
    {
      if(response.data==true)
      {
        message.success("Succesfully registered this device");
        setVisible(false)
        history.push("/investor/home");
      }
      else
      {
        message.error("you have entered wrong otp.please enter again");  
      }
    }
    else
    {
      message.error("Something went wrong !,try refreshing the page");
    }
    setSubmitting(false)
  }
  return(
    <>
    <Modal
    title="Device Registration"
    visible={visible}
    footer={[]}
    >
    <Form
    form={form}
    onFinish={verifyOTP}
    >
      <Form.Item
      label="Enter the otp send to your registered email"
      >
        <Input></Input>
      </Form.Item>
      <Row justify="center">
        <Col>
      <Form.Item >
        <Button  type="primary" htmlType="submit" loading={submitting} >Submit</Button>
      </Form.Item>
      </Col>
      </Row>
    </Form>
    </Modal>
    </>
  )
}