import React from 'react';
// import { lazy } from '@loadable/component';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import TokenConfigurationList from './TokenConfigurationList';
import TokenConfigurationDetails from './TokenConfigurationDetails';


export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} exact>
        <TokenConfigurationList/>
      </Route>

      <Route path={`${path}/:tokenConfigurationProcessId/:configType`}>
        <TokenConfigurationDetails/>
      </Route>
    </Switch>
  );
}