import React from 'react';
import { Table, Card, Button } from 'antd';
import { IssuerBasicInfoVerificationRequest } from "../../Shared/interfaces"
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';

const { Column } = Table;


const sharedService = new SharedService();


export default (prop) => {
  const {data, loading, statuses, showDetails} = prop;

  (data as IssuerBasicInfoVerificationRequest[])?.forEach((req, index) => {
    req['columNumber'] = index + 1;
    req['registeredOn'] = moment(req.registrationTS).format('LLL');
    req['statusName'] = sharedService.statusName(req.status);
  });


  return (
    <Card style={{ margin: 'auto', marginTop: '4%' }}>
      <Table dataSource={data} rowKey="_id" pagination={false} loading={loading}>
        <Column title='#' dataIndex='columNumber'/>
        <Column title='COMPANY' dataIndex='companyName'/>
        <Column title='COUNTRY' dataIndex='displayableCountry'/>
        <Column title='STATE' dataIndex='companyState'/>
        <Column title='EMAIL ADDRESS' dataIndex='email'/>
        <Column title='REGISTERED ON' dataIndex='registeredOn'/>
        {!statuses.includes('pending') && 
          <Column title='STATUS' dataIndex='statusName' />
        }
        <Column
          title='ACTION'
          key='1'
          render={(text, issuerBasicInfoVerifReq: IssuerBasicInfoVerificationRequest) => (
            statuses.includes('pending')?
            <Button type='link' onClick={() => showDetails(issuerBasicInfoVerifReq)}>Approve/Reject</Button> : 
            <Button type='link' onClick={() => showDetails(issuerBasicInfoVerifReq)}>Details</Button>
          )}
        />
      </Table>
    </Card>
  );
}