import React from "react";
import { Form, Input, Button } from "antd";
const { Item } = Form;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 10,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 6,
  },
};

const validationRules = {
  eversignAPIKey: [
    {
      required: true,
      message: "Please enter the api key",
    },
  ],
  eversignBusinessId: [
    {
      required: true,
      message: "Please enter the business id",
    },
  ],
  regsTemplateId: [
    {
      required: true,
      message: "Please enter regs template id",
    },
  ],
  regdTemplateId: [
    {
      required: true,
      message: "Please enter regdTemplate id",
    },
  ],
};
export default ({ onSubmit, submitting, APIConfiguration }) => {
  const [eversignForm] = Form.useForm();
  const submit = async (data) => {
    await onSubmit(data);
    eversignForm.resetFields();
  };
  return (
    <>
      <Form {...layout} form={eversignForm} onFinish={submit}>
        <Item
          name="eversignAPIKey"
          label="Eversign API Key"
          rules={validationRules.eversignAPIKey}
        >
          <Input disabled={submitting} />
        </Item>

        <Item
          name="eversignBusinessId"
          label="Eversing business ID"
          rules={validationRules.eversignBusinessId}
        >
          <Input disabled={submitting} />
        </Item>
        <Item
          name="regsTemplateId"
          label="Reg-S template id"
          rules={validationRules.regsTemplateId}
        >
          <Input disabled={submitting} />
        </Item>
        <Item
          name="regdTemplateId"
          label="Reg-D template id"
          rules={validationRules.regdTemplateId}
        >
          <Input disabled={submitting} />
        </Item>
        <Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Item>
      </Form>
      <br />
      <div style={{ textAlign: "left" }}>
        <b>Current credentials:</b> <br />
        <b>Eversing business ID:</b> {APIConfiguration.eversignBusinessId}{" "}
        <br />
        <b>Reg-D template id:</b>
        {APIConfiguration.regdTemplateId}
        <br />
        <b>Reg-S template id:</b>
        {APIConfiguration.regsTemplateId}
      </div>
    </>
  );
};
