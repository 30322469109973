import React, { useEffect, useState } from "react";
import { Row, Col, Card, Typography, Select, message } from "antd";
import { PlatformSuperAdminService } from "../../PlatformSuperAdmin/PlatformSuperAdmin.service";
import AcuantCredentialForm from "./AcuantCredentialForm";
import EversingForm from "./Eversign";
import ApiKeyForm from "./APIKeyFrom";
const { Title } = Typography;
const platformSuperAdminService = new PlatformSuperAdminService();

export default () => {
  const [listOfAPIProviders, setListOfAPIProviders] = useState(null);
  const [view, setView] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [APIConfiguration, setAPIConfiguration] = useState({});
  useEffect(() => {
    (async () => {
      const response = await platformSuperAdminService.getAPIProviders();
      if (response.success && response.data) {
        setListOfAPIProviders(response.data.issuerAdminList);
      } else {
        setListOfAPIProviders([]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const apiData = await platformSuperAdminService.getAPIConfiguration();
      if (apiData.success && apiData.data) {
        setAPIConfiguration(apiData.data);
      }
    })();
  }, [submitting]);

  const submitCredentials = async (data) => {
    setSubmitting(true);
    const response = await platformSuperAdminService.submitCredentials(data);
    if (response.success && response.data) {
      message.success("Succefully saved the credentials");
    }
    setSubmitting(false);
  };
  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Manage Website credentials
            </Title>
            Select API Provider:&nbsp;&nbsp;
            <Select
              loading={!listOfAPIProviders}
              style={{ width: 200 }}
              onChange={setView}
            >
              {listOfAPIProviders?.map((data, index) => (
                <Select.Option value={data} key={index}>
                  {data}
                </Select.Option>
              ))}
            </Select>
            <br />
            <br />
            <div>
              {view === "Acuant" && (
                <>
                  <AcuantCredentialForm
                    onSubmit={submitCredentials}
                    submitting={submitting}
                    APIConfiguration={APIConfiguration}
                  ></AcuantCredentialForm>
                </>
              )}
              {view === "Eversign" && (
                <EversingForm
                  onSubmit={submitCredentials}
                  submitting={submitting}
                  APIConfiguration={APIConfiguration}
                ></EversingForm>
              )}
              {view && view !== "Eversign" && view !== "Acuant" && (
                <ApiKeyForm
                  onSubmit={submitCredentials}
                  submitting={submitting}
                  view={view}
                  APIConfiguration={APIConfiguration}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
