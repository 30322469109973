import React from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import RecoveryRequestsDetails from './RecoveryRequestsDetails';
import RecoveryRequestsList from './RecoveryRequestsList';
import RecoveryRequestsNew from './RecoveryRequestsNew';


export default () => {
    const { path } = useRouteMatch();
  
    return (
      <Switch>
        <Route path={`${path}`} exact>
          <RecoveryRequestsList/>
        </Route>
        <Route path={`${path}/new`}>
          <RecoveryRequestsNew/>
        </Route>
        <Route path={`${path}/:recoveryProcessReqId`}>
          <RecoveryRequestsDetails/>
        </Route>
      </Switch>
    )
  }
    