import React from 'react';
import { IssuerRegistrationRequest } from "../../Shared/interfaces"
import { Table, Card, Button } from 'antd';
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';

const { Column } = Table;

const sharedService = new SharedService();

export default (prop) => {
  console.log(prop)
  const {data, loading, statuses, showDetails} = prop;
  
  (data as IssuerRegistrationRequest[])?.forEach((req, index) => {
    req['columNumber'] = index + 1;
    req['createdOn'] = moment(req.creationTS).format('LLL');
    req['statusName'] = sharedService.statusName(req.status);
  });


  return (
    <Card style={{ margin: 'auto', marginTop: '4%' }}>
      <Table dataSource={data} rowKey="_id" pagination={false} loading={loading}>
        <Column title='#' dataIndex='columNumber'/>
        <Column title='FIRST NAME' dataIndex='firstName'/>
        <Column title='LAST NAME' dataIndex='lastName' />
        <Column title='COMPANY' dataIndex='companyName' />
        <Column title='EMAIL ADDRESS' dataIndex='email' />
        <Column title='PHONE' dataIndex='phone'  />
        <Column title='CREATED ON' dataIndex='createdOn' />
        {!statuses.includes('pending') && 
          <Column title='STATUS' dataIndex='statusName' />
        }
        <Column
          title='ACTION'
          key='1'
          render={(text, issuerRegReq: IssuerRegistrationRequest) => (
            statuses.includes('pending')?
            <Button type='link' onClick={() => showDetails(issuerRegReq)}>Approve/Reject</Button> : 
            <Button type='link' onClick={() => showDetails(issuerRegReq)}>Details</Button>
          )}
        />
      </Table>
    </Card>
  )
}