import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';
const sharedService = new SharedService();
export class AuthorizationService {
  private APIURL = environment.APIURL;
  async getInReviewAuthorizers(data : any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/getInReviewAuthorizers`,data,{headers:await sharedService.getAuthHeader()});
  }
  async reviewAuthorization(data : any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/reviewAuthorization`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getReviewedAuthorizers(data : any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/issuerSuperAdmin/admin/getReviewedAuthorizers`,data,{headers:await sharedService.getAuthHeader()});
  }
}