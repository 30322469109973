import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthService } from "./components/Shared/Auth.service";
import { SharedService } from "./components/Shared/Shared.service";
import { MetamaskService } from "./components/Shared/Metamask.service";
import AppRoutes from "./App.routes";
import "./App.scss";
import ConnectToMetamask from "./components/ConnectToMetamask";
import dotenv from "dotenv";

import Spinner from "./components/spinner/Spinner";
import "./assets/fonts/feather/css/feather.css";
import "./assets/fonts/fontawesome/scss/font-awesome.scss";
import "./assets/fonts/material-design-iconic-font/css/material-design-iconic-font.min.css";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

dotenv.config();
const metamaskService = new MetamaskService();
const sharedService = new SharedService();

const UserContext = AuthService.UserContext;
const useUserInfo = () => new AuthService().useUserInfo();

const SelectedWalletContext = MetamaskService.SelectedWalletContext;
const useSelectedWallet = (userId: string) =>
  new MetamaskService().useSelectedWallet(userId);

export default () => {
  const [userInfo, setUserInfo] = useUserInfo();
  const [
    selectedWallet,
    networkId,
    isMetamaskAuthenticated,
    setSelectedWallet,
    setNetworkId,
    setIsMetamaskAuthenticated,
  ] = useSelectedWallet(userInfo);

  const isNotConnectedToMetamask =
    !!userInfo &&
    (!metamaskService.isMetamaskInstalled ||
      isMetamaskAuthenticated === false ||
      (networkId && !sharedService.allowedNetworks.includes(networkId)));

  return (
    <Router>
      <UserContext.Provider value={{ userInfo, setUserInfo }}>
        <SelectedWalletContext.Provider value={{ selectedWallet, networkId }}>
          <Suspense fallback={Spinner}>
            {/* <Suspense fallback={<h1 className="text-center pt-5 mt-4">Loading...</h1>}> */}
            <div hidden={!isNotConnectedToMetamask}>
              <ConnectToMetamask
                isMetamaskInstalled={metamaskService.isMetamaskInstalled}
                isMetamaskAuthenticated={isMetamaskAuthenticated}
                setSelectedWallet={setSelectedWallet}
                setNetworkId={setNetworkId}
                setIsMetamaskAuthenticated={setIsMetamaskAuthenticated}
              />
            </div>
            <div hidden={!!isNotConnectedToMetamask}>
              <AppRoutes userInfo={userInfo} />
            </div>
          </Suspense>
        </SelectedWalletContext.Provider>
      </UserContext.Provider>
    </Router>
  );
};
