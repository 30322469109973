import { MetamaskService } from '../../../Metamask.service';
import {TrustedForwarderService} from "../../../TrustedForwarder.service";


const trustedForwarderService = new TrustedForwarderService();

export default class ERC1410Facet {
  contract = {
    ABI: require('./ABI.json'),
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async totalPartitions(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.totalPartitions().call()) as string[];
  }

  async totalSupplyByPartition(tokenAddress: string, partition: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.totalSupplyByPartition(partition).call()) as string;
  }

  async balanceOfByPartition(tokenAddress: string, partition: string, tokenHolder: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.balanceOfByPartition(partition, tokenHolder).call()) as string;
  }

  async partitionsOf(tokenAddress: string, tokenHolder: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.partitionsOf(tokenHolder).call()) as string[];
  }

  async transferByPartition(
    tokenAddress: string,
    owner: string,
    partition: string,
    to: string,
    value: string,
    data: string = '0x'
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method = contractInstance.methods.transferByPartition(partition, to, value, data);

    try {
      return method.send({ from: owner, gas: await method.estimateGas({from: owner}) });

    }catch (e) {
      return method.send({ from: owner });
    }

  }

  async operatorTransferByPartition(
    tokenAddress: string,
    owner: string,
    partition: string,
    from: string,
    to: string,
    value: string,
    data: string = '0x',
    operatorData: string = '0x'
    ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return contractInstance.methods
      .operatorTransferByPartition(partition, from, to, value, data, operatorData)
      .send({ from: owner });
  }

  async canTransferByPartition(
    tokenAddress: string,
    _from: string,
    _to: string,
    _partition: string,
    _value: string,
    _data: string = '0x'
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.canTransferByPartition(_from, _to, _partition, _value, _data).call()) as [string, string, string];
  }

  async issueByPartition(
    tokenAddress: string,
    owner: string,
    partition: string,
    tokenHolder: string,
    value: string,
    data: string = '0x',
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method = contractInstance.methods.issueByPartition(partition, tokenHolder, value, data);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async operatorRedeemByPartition(
    tokenAddress: string,
    owner: string,
    partition: string,
    tokenHolder: string,
    value: string,
    operatorData: string = '0x',
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    const method = contractInstance.methods.operatorRedeemByPartition(partition, tokenHolder, value, operatorData);

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }



}