import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CreateLegend from './CreateLegend';
import RemoveLegend from './RemoveLegend';
import FiledNoticesWithSEC from './FiledNoticesWithSEC';
import SafeHarbor from './Dashboard/SafeHarbor';
import Disclosures from './Dashboard/Disclosures';
import TransactionDocuments from './Dashboard/TransactionDocuments';

import ReviewDueDiligence from './ReviewDueDiligence';
export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/create-legend/:tokenConfigurationProcessId`}>
        <CreateLegend/>
      </Route>
      <Route path={`${path}/remove-legend/:tokenConfigurationProcessId`}>
        <RemoveLegend/>
      </Route>
      <Route path={`${path}/review-affiliate-form-144`}>
        <FiledNoticesWithSEC/>
      </Route>
      <Route path={`${path}/safe-harbor`}>
        <SafeHarbor/>
      </Route>
      <Route path={`${path}/disclosures`}>
        <Disclosures/>
      </Route>
      <Route path={`${path}/transaction-documents`}>
        <TransactionDocuments/>
      </Route>
      <Route path={`${path}/review-due-diligence`}>
        <ReviewDueDiligence/>
      </Route>
      
    </Switch>
  );
}