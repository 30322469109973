import axios from 'axios';
import {environment} from '../../environments/environment';
import {APIResponse} from './interfaces';
import {SharedService} from './Shared.service';
import {MetamaskService} from "./Metamask.service";
import {notification} from "antd";
import BigNumber from "bignumber.js";


const sharedService = new SharedService();


export class TrustedForwarderService {

  private APIURL = environment.APIURL;


  async createTrustedForwarder() {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/trustedForwarder/createTrustedForwarder`,
      {},
      { headers: await sharedService.getAuthHeader() }
    );
  }

  async getTrustedForwarder() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/trustedForwarder/getTrustedForwarder`,
      { headers: await sharedService.getAuthHeader() }
    );
  }


  async getTrustedForwarderTransactions(prop: {
    address: string,
    searchPage: number,
    limit: number
  }) {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/trustedForwarder/getTrustedForwarderTransactions`,
      { headers: await sharedService.getAuthHeader(), params: prop }
    );
  }

  async getWalletIssuers() {
    return axios.get<any, APIResponse>(
      `${this.APIURL}/trustedForwarder/getWalletIssuers`,
      { headers: await sharedService.getAuthHeader()}
    );
  }

  async delegateTransaction(prop: {
    contractAddress: string,
    encodedFunction: string,
    signerAddress: string
  }) {
    const trustedForwarderRes = await this.getTrustedForwarder();
    const _trustedForwarder = trustedForwarderRes.data;

    if(!_trustedForwarder) {
      notification.error({
        message: 'No Trusted Forwarder Created',
        description: 'Create one to enable Fee Delegation'
      });

      throw new Error('No Trusted Forwarder Created');
    }

    const _balance = await MetamaskService.web3.eth.getBalance(_trustedForwarder.address);

    if(new BigNumber(_balance).isEqualTo('0')) {
      notification.error({
        message: "Company wallet is out of funds",
      });

      throw new Error('Trusted Forwarder doesn\'t have funds');
    }

    const signature = await MetamaskService.web3.eth.personal.sign(
      MetamaskService.web3.utils.utf8ToHex(prop.encodedFunction),
      prop.signerAddress,
      ''
    );

    const response = await this.forwardTransactionCall({
      encodedFunction: prop.encodedFunction,
      contractAddress: prop.contractAddress,
      signature
    });

    if(!response.success) throw new Error(response.error.message);

    return this.waitTransactionReceipt({hash: response.data.hash});
  }


  waitTransactionReceipt(prop: {hash: string}) {
    return new Promise((resolve, reject) => {
      let i = 0;

      let transaction;

      const interval = setInterval(async () => {
        if(!transaction?.blockNumber) transaction = await MetamaskService.web3.eth.getTransaction(prop.hash);

        if(!transaction && i === 8) {
          reject();
          clearInterval(interval);
        }

        if(transaction?.blockNumber) {
          const receipt = await MetamaskService.web3.eth.getTransactionReceipt(prop.hash);
          if(receipt) {
            clearInterval(interval);
            resolve(receipt);
          }
        }

        i++;
      }, 3*1000);
    });
  }



  private async forwardTransactionCall(prop: {
    encodedFunction: string;
    contractAddress: string;
    signature: string;
  }) {
    return axios.post<any, APIResponse>(
      `${this.APIURL}/trustedForwarder/forwardTransactionCall`,
      prop,
      { headers: await sharedService.getAuthHeader() }
    );
  }

}