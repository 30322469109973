import React from 'react'
import QuestionsList from './QuestionsList.js'
import { Card, Row, Col, Typography } from 'antd';

const {Title}=Typography;


export default ()=>{
  return(
    <div>
     <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            View And Edit Questions
            </Title>
            <QuestionsList></QuestionsList>
            </Card>
            </Col>
            </Row>
             </div>
  )
}