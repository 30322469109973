import React, { useState } from 'react';
import {
  APIResponse,
  OfferingModelScreenAction,
  TokenConfigurationProcess,
  User,
} from '../../../Shared/interfaces';
// import { useHistory, Link, useLocation } from 'react-router-dom';
// import queryString from 'query-string';
import OfferingModelsList from './OfferingModelsList';
import OfferingModelsDetails from './OfferingModelsDetails';
import OfferingModelsNew from './OfferingModelsNew';
import PhasesNew from '../Phases/New';
import PhasesDetails from '../Phases/Details';
import PhasesEdit from '../Phases/Edit';
import PhasesList from '../Phases/List';

export default function OfferingModels(props: {
  tokenConfigurationProcess: TokenConfigurationProcess | undefined;
  userInfo: User;
  saveOfferingModel: (req: any) => Promise<APIResponse>;
  savePhase: (req: any) => Promise<APIResponse>;
  editPhase: (req: any) => Promise<APIResponse>;
}) {
  // const history = useHistory();
  // const location = useLocation();
  // const { code } = queryString.parse(location.search);

  const [screen, setScreen] = useState<OfferingModelScreenAction>({
    component: 'offering-models-list',
  });

  return (
    <>
      {screen.component === 'offering-models-list' && (
        <OfferingModelsList {...props} screen={screen} setScreen={setScreen} />
      )}
      {screen.component === 'offering-models-new' && (
        <OfferingModelsNew {...props} setScreen={setScreen} />
      )}
      {[
        'offering-models-details',
        'phases-new',
        'phases-details',
        'phases-edit',
      ].includes(screen.component) && (
        <>
          <OfferingModelsDetails
            {...props}
            screen={screen}
            setScreen={setScreen}
          />

          {screen.component === 'offering-models-details' && (
            <PhasesList {...props} screen={screen} setScreen={setScreen} />
          )}

          {screen.component === 'phases-new' && (
            <PhasesNew {...props} screen={screen} setScreen={setScreen} />
          )}
          {screen.component === 'phases-details' && (
            <PhasesDetails {...props} screen={screen} setScreen={setScreen} />
          )}
          {screen.component === 'phases-edit' && (
            <PhasesEdit {...props} screen={screen} setScreen={setScreen} />
          )}
        </>
      )}
    </>
  );
}
