import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ListTeamManagment from './List';
import New from './New';
import Details from './Details';
import EditMember from './EditMember';


export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <ListTeamManagment/>
      </Route>
      <Route path={`${path}/new`}>
        <New/>
      </Route>
      <Route path={`${path}/edit/:id`}>
        <EditMember/>
      </Route>
      <Route path={`${path}/:id`}>
        <Details/>
      </Route>
    </Switch>
  );
}