import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import KYCVerificationRequest from './KYCVerificationRequest';
import TeamMemberKYCRequests from './TeamMemberKYCRequests';
import AddDueDiligenceForIssuer from './AddDueDiligenceForIssuer';
import ReviewDueDligence from './ReviewDueDiligence';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/kyc-verification-request`}>
       <KYCVerificationRequest/>
      </Route>
      <Route path={`${path}/team-member-kyc-requests`}>
       <TeamMemberKYCRequests></TeamMemberKYCRequests>
      </Route>
      <Route path={`${path}/add-due-diligence-for-issuer`}>
       <AddDueDiligenceForIssuer/>
      </Route>
      <Route path={`${path}/review-due-diligence`}>
       <ReviewDueDligence/>
      </Route>
    </Switch>
  )
}
