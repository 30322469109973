import React, { useState, useEffect, useRef } from "react";
import {
  List,
  Button,
  Form,
  Radio,
  Input,
  Collapse,
  Progress,
  Modal,
  Checkbox,
  message,
  Space,
  Popconfirm,
} from "antd";
import { IssuerSuperAdminService } from "./../IssuerSuperAdmin.service";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { SharedService } from "../../Shared/Shared.service";
import { AuthService } from "../../Shared/Auth.service";

const auth = new AuthService();
const useUserContext = () => auth.useUserContext();
const sharedService = new SharedService();
const { Panel } = Collapse;
const issuerSuperAdminService = new IssuerSuperAdminService();

function Data() {
  this.question = "";
  this.type = "";
  this.category = "";
  this.answer = "";
  this.isAnswered = false;
  this.partOfWebpage = false;
  this.hidden = false;
}
export default () => {
  const { userInfo } = useUserContext();
  const [generalQuestions, setGeneralQuestions] = useState([]);
  const [offeringDocsQuestions, setOfferingDocsQuestions] = useState([]);
  const [socialMediaQuestions, setSocialMediaQuestions] = useState([]);
  const [businessQuestions, setBusinessQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [displayFlag, setDisplayFlag] = useState(false);
  const [questionToAnswer, setQuestionToAnswer] = useState(null);
  const [options, setOptionsView] = useState(false);
  const [textArea, setTextAreaView] = useState(false);
  const [textBox, setTextBoxView] = useState(false);
  const [attachment, setAttachmentView] = useState(false);
  const [content, setContent] = useState("");
  const [infoModalView, setInfoModalView] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const videoURLRef = useRef(null);
  const campaignTitleRef = useRef(null);
  const [issuerWebpageData, setIssuerWebpageData] = useState(null);
  const [issuerWebpageModalView, setIssuerWebpageModalView] = useState(false);
  const [documentsToSubmit, setDocumentsToSubmit] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [filesObj, setFilesObj] = useState({
    attachement: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });

  const initialFileObj = {
    attachement: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  };

  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      const response = await issuerSuperAdminService.getQuestions({
        company: userInfo.companyName.toLowerCase(),
      });
      setQuestions(response.data);
      applyFilter(response.data);

      const issuerWebpageConfigResult =
        await issuerSuperAdminService.getIssuerWebpageConfig({
          companyId: userInfo.company.id,
          company: userInfo.company.name,
        });
      if (issuerWebpageConfigResult.success) {
        await setIssuerWebpageData(issuerWebpageConfigResult.data);
        await setInfoModalView(
          !issuerWebpageConfigResult.data.neverDisplayInfoModal
        );
      } else {
        setInfoModalView(true);
      }
    })();
  }, []);

  const applyFilter = (ques) => {
    const generalQuestions = ques?.filter(
      (question) => question.category === "General"
    );
    setGeneralQuestions(generalQuestions);
    console.log(generalQuestions);
    const offeringDocsQuestions = ques?.filter(
      (question) => question.category === "Offering docs"
    );
    setOfferingDocsQuestions(offeringDocsQuestions);
    const businessQuestions = ques?.filter(
      (question) => question.category === "Business"
    );
    setBusinessQuestions(businessQuestions);
    const socialMediaQuestions = ques?.filter(
      (question) => question.category === "Social Media"
    );
    setSocialMediaQuestions(socialMediaQuestions);
  };

  const setIssuerWebpageConfig = async (data) => {
    console.log(data);
    const dataToSend = await Object.assign(
      { companyId: userInfo.company.id },
      data
    );
    console.log(dataToSend);
    const response = await issuerSuperAdminService.setIssuerWebpageConfig(
      dataToSend
    );
    if (!response.success || !response.data) {
      message.error("Error while saving data");
    }
  };

  const uploadPromotionalVideo = async () => {
    setSubmitting(true);
    const response = await setIssuerWebpageConfig({
      videoURL: videoURLRef.current.state.value,
    });
    await message.success("Video url updated successfully.");
    issuerWebpageData.videoURL = videoURLRef.current.state.value;
    videoURLRef.current.state.value = "";
    setSubmitting(false);
  };

  const uploadCampaignTitle = async () => {
    setSubmitting(true);
    const response = await setIssuerWebpageConfig({
      campaignTitle: campaignTitleRef.current.state.value,
    });
    await message.success("Campaign title updated successfully.");
    issuerWebpageData.campaignTitle = campaignTitleRef.current.state.value;
    campaignTitleRef.current.state.value = "";
    setSubmitting(false);
  };

  const uploadCampaignTitleImage = async () => {
    setSubmitting(true);
    const response = await setIssuerWebpageConfig({
      campaignTitleImage: {
        URL: filesObj.attachement.URL,
        name: filesObj.attachement.name,
      },
    });
    await message.success("Campaign title image updated successfully.");
    issuerWebpageData.campaignTitleImage = {
      URL: filesObj.attachement.URL,
      name: filesObj.attachement.name,
    };
    filesObj.attachement.URL = null;
    filesObj.attachement.uploadFilePercentage = 0;
    setSubmitting(false);
  };

  const answerQuestion = (question) => {
    setDisplayFlag(true);
    setQuestionToAnswer(question);
    if (question.type === "option") {
      setOptionsView(true);
    }
    if (question.type === "textArea") {
      setTextAreaView(true);
    }
    if (question.type === "textBox") {
      setTextBoxView(true);
    }
    if (question.type === "attachement") {
      setAttachmentView(true);
    }
  };
  const onFinished = async (values) => {
    var data = new Data();
    data.question = questionToAnswer.question;
    data.category = questionToAnswer.category;
    data.type = questionToAnswer.type;
    data.isAnswered = true;
    data.hidden = false;
    data.partOfWebpage = questionToAnswer.partOfWebpage;
    data.company = userInfo.companyName.toLowerCase();
    if (questionToAnswer.type === "textBox") {
      if (values.textBox) {
        if (values.textBox.trim().length <= 0) {
          alert("Answer cannot be empty!!");
          return;
        }
      } else {
        alert("Answer cannot be empty!!");
        return;
      }
      data.answer = values.textBox.trim();
    }
    if (questionToAnswer.type === "textArea") {
      if (values.textArea) {
        data.answer = content;
      } else {
        alert("Answer cannot be empty!!");
        return;
      }
    }
    if (questionToAnswer.type === "option") {
      if (values.option) {
        data.answer = values.option;
      } else {
        alert("Please select a option !");
        return;
      }
    }
    if (questionToAnswer.type === "attachement") {
      if (documentsToSubmit) {
        data.answer = documentsToSubmit;
      } else {
        alert("Please select a file !");
        return;
      }
    }
    setSubmitting(true);
    const response = await issuerSuperAdminService.setAnswer(data);
    if (response.success) {
      const que = questions.filter(
        (question) => question.question !== questionToAnswer.question
      );
      applyFilter(que);
      setQuestions(que);
      setOptionsView(false);
      setTextAreaView(false);
      setTextBoxView(false);
      setOptionsView(false);
      setAttachmentView(false);
      setFilesObj(initialFileObj);
      setDocuments([]);
      setDocumentsToSubmit([]);
      form.resetFields();
      setDisplayFlag(false);
    }
    setSubmitting(false);
  };

  const handleChange = (content, editor) => {
    setContent(content);
  };

  const uploadFile = (e, key, setFilesObj, form) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (!file) return;

    const fileType = sharedService.getFileType(file.name);
    setFilesObj((prev) => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    issuerSuperAdminService.uploadFile(
      file,
      (uploadFilePercent) => {
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = true;
          current[key].uploadFilePercentage = uploadFilePercent;
          return current;
        });
      },
      (err) => {
        console.error(err);
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      },
      async (response) => {
        if (response.success) {
          const URL = response.data.url;
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].URL = URL;
            current[key].uploading = false;
            current[key].name = file.name;
            current[key].type = fileType.toLowerCase();
            return current;
          });
          let fileDetails = { [key]: { URL, name: file.name } };
          form.setFieldsValue(fileDetails);
          setDocuments((prev) => prev.concat(file.name));
          setDocumentsToSubmit((prev) => prev.concat({ URL, name: file.name }));
        } else {
          console.error(response.error);
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].uploading = false;
            return current;
          });
        }
      }
    );
  };

  const back = () => {
    setOptionsView(false);
    setTextAreaView(false);
    setTextBoxView(false);
    setOptionsView(false);
    setAttachmentView(false);
    setFilesObj(initialFileObj);
    form.resetFields();
    setDisplayFlag(false);
  };

  const deleteItem = (index) => {
    setDocuments(documents.filter((document) => document !== documents[index]));
    setDocumentsToSubmit(
      documentsToSubmit.filter(
        (document) => document !== documentsToSubmit[index]
      )
    );
  };
  return (
    <>
      <br />
      <div hidden={displayFlag}>
        <Collapse>
          <Panel header="General" key="General" style={{ textAlign: "left" }}>
            <List style={{ textAlign: "left" }} loading={!questions}>
              {generalQuestions?.map((question, index) => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  key={index}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => answerQuestion(generalQuestions[index])}
                    >
                      Answer
                    </Button>,
                  ]}
                >
                  <List.Item.Meta title={question.question}></List.Item.Meta>
                </List.Item>
              ))}
            </List>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel header="Business" key="Business" style={{ textAlign: "left" }}>
            <List style={{ textAlign: "left" }} loading={!questions}>
              {businessQuestions?.map((question, index) => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  key={index}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => answerQuestion(businessQuestions[index])}
                    >
                      Answer
                    </Button>,
                  ]}
                >
                  <List.Item.Meta title={question.question}></List.Item.Meta>
                </List.Item>
              ))}
            </List>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header="Social Media"
            key="Social Media"
            style={{ textAlign: "left" }}
          >
            <List style={{ textAlign: "left" }} loading={!questions}>
              {socialMediaQuestions?.map((question, index) => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  key={index}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() =>
                        answerQuestion(socialMediaQuestions[index])
                      }
                    >
                      Answer
                    </Button>,
                  ]}
                >
                  <List.Item.Meta title={question.question}></List.Item.Meta>
                </List.Item>
              ))}
            </List>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header="Offering Docs"
            key="Offering Docs"
            style={{ textAlign: "left" }}
          >
            <List style={{ textAlign: "left" }} loading={!questions}>
              {offeringDocsQuestions?.map((question, index) => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  key={index}
                  actions={[
                    <Button
                      type="primary"
                      onClick={() =>
                        answerQuestion(offeringDocsQuestions[index])
                      }
                    >
                      Answer
                    </Button>,
                  ]}
                >
                  <List.Item.Meta title={question.question}></List.Item.Meta>
                </List.Item>
              ))}
            </List>
          </Panel>
        </Collapse>
      </div>
      <div hidden={!displayFlag}>
        <Form name="answerForm" form={form} onFinish={onFinished}>
          <Form.Item label="Question">
            <label>
              <h1>{questionToAnswer?.question}</h1>
            </label>
          </Form.Item>
          <div hidden={!options}>
            <Form.Item name="option">
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div hidden={!textArea}>
            <Form.Item name="textArea">
              <SunEditor value={content} onChange={handleChange} />
            </Form.Item>
          </div>
          <div hidden={!textBox}>
            <Form.Item name="textBox">
              <Input type="url" style={{ width: "60%" }}></Input>
            </Form.Item>
          </div>
          <div hidden={!attachment}>
            <Form.Item name="attachement">
              <Input
                type="file"
                onChange={(e) => {
                  uploadFile(e, "attachement", setFilesObj, form);
                }}
                disabled={submitting}
              />
              {filesObj.attachement.uploadFilePercentage > 0 && (
                <>
                  <Progress
                    percent={filesObj.attachement.uploadFilePercentage}
                  />
                  <br />
                  <div align="left">
                    Files Uploaded:
                    <List itemLayout="horizontal" style={{ textAlign: "left" }}>
                      {documents?.map((obj, index) => (
                        <List.Item
                          textalign="left"
                          style={{ cursor: "pointer" }}
                          key={index}
                          actions={[
                            <Popconfirm
                              title="This item will be deleted!"
                              onConfirm={() => deleteItem(index)}
                            >
                              <Button type="primary" danger>
                                X
                              </Button>
                            </Popconfirm>,
                          ]}
                        >
                          <List.Item.Meta title={obj} />
                        </List.Item>
                      ))}
                    </List>
                  </div>
                </>
              )}{" "}
            </Form.Item>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              SUBMIT
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button htmlType="button" onClick={() => back()}>
              BACK
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        title="Info"
        visible={infoModalView}
        footer={[
          <Button type="primary" onClick={() => setInfoModalView(false)}>
            OK
          </Button>,
        ]}
      >
        Answer to the questions will reflect on your offering webpage.Please
        provide true and accurate information.
        <br />
        <br />
        <Checkbox
          onChange={(e) =>
            setIssuerWebpageConfig({ neverDisplayInfoModal: e.target.checked })
          }
        >
          Never want to see this message again.
        </Checkbox>
      </Modal>
      <br />
      <div style={{ textAlign: "right" }}>
        <Space>
          <Button
            type="primary"
            href="/issuer-super-admin/view-and-edit-answers"
          >
            Edit answers
          </Button>
          {/* <Button type="primary" onClick={()=>{setIssuerWebpageModalView(true)}} >Preview Offering page</Button> */}
        </Space>
      </div>

      {/* <Divider orientation="left" ><span style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }} >WEB PAGE CONFIGURATION </span></Divider>
            <div>
            <Row>
              <Col justify="left" >
              <Space direction="vertical" >
                <Space>
                  Upload Promotional Video(s):<Input ref={videoURLRef}  placeholder="video URL" ></Input>
                  <Button type="primary" onClick={()=>{uploadPromotionalVideo()}} diabled={submitting} >Submit</Button>
                  {issuerWebpageData?.videoURL && <>Uploaded Video URL:<a href={issuerWebpageData?.videoURL} target="_blank" >{issuerWebpageData?.videoURL}</a></>}
                </Space>
                <Space>
                  Title for Campaign:<Input ref={campaignTitleRef} placeholder="campaign title" ></Input><Button onClick={uploadCampaignTitle} type="primary" >Submit</Button>
                  {issuerWebpageData?.campaignTitle && <> Current title for campaign:<b>{issuerWebpageData?.campaignTitle}</b></>}
                </Space>
                <Space>
                  Image for Campaign:<Input type='file' onChange={e => { uploadFile(e,'attachement') }} />
                  {filesObj.attachement.uploadFilePercentage>0 && 
                  <Progress percent={filesObj.attachement.uploadFilePercentage} />}
                  {filesObj.attachement.uploadFilePercentage===100 && <Button type="primary" onClick={uploadCampaignTitleImage} >Submit</Button>}
                  {issuerWebpageData?.campaignTitleImage && <> Current Image URL for campaign:<a target="_blank" href={issuerWebpageData?.campaignTitleImage.URL} >{issuerWebpageData?.campaignTitleImage.name}</a></>}
                </Space>
              </Space>
              </Col>
            </Row>
            <Modal
              title="Token Issuer Details"
              closable={false}
              visible={issuerWebpageModalView}
              footer={
                <Button htmlType="button" type="primary" onClick={()=>setIssuerWebpageModalView(false)}>OK</Button>
              }
              width={1024}
              >
               {issuerWebpageModalView && <CompanyDetails companyId={userInfo.company.id} company={userInfo.company.name} investButtonCallback={()=>{alert('Only available for investors.')}} ></CompanyDetails>}
              </Modal>
            </div> */}
    </>
  );
};
