import React, { useState, useEffect } from 'react';
import {
  OfferingModelScreenAction,
  TokenConfigurationProcess,
  TokenPhase,
  User,
} from '../../../../Shared/interfaces';
import moment from 'moment';
import { Button, Row, Col, Spin, Card, Typography, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TokenConfigurationService } from '../../../TokenConfiguration.service';

// import { AuthService } from '../../../../Shared/Auth.service';

const { Title } = Typography;

const tokenConfigurationService = new TokenConfigurationService();

// const useUserContext = () => new AuthService().useUserContext();

export default (props: {
  tokenConfigurationProcess: TokenConfigurationProcess | undefined;
  userInfo: User;
  screen: OfferingModelScreenAction;
  setScreen: (action: OfferingModelScreenAction) => void;
}) => {
  const { tokenConfigurationProcess, userInfo, screen, setScreen } = props;

  const [phases, setPhases] = useState<TokenPhase[]>();
  const [loadingMore, setLoadingMore] = useState<boolean>();
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [filter, setFilter] = useState({ limit: 10, searchPage: 1 });

  useEffect(() => {
    (async () => {
      if (!tokenConfigurationProcess) return;

      const response = await tokenConfigurationService.getPhasesByModelId({
        tokenOfferingModelId: screen.modelId as string,
        limit: filter.limit,
        searchPage: 1,
      });

      const _phases: TokenPhase[] = response.data;

      setPhases(_phases);
      setFilter((prev: any) => ({ ...prev, searchPage: prev.searchPage + 1 }));
      setCanLoadMore(_phases.length === filter.limit);
      setLoadingMore(false);
    })();
  }, [tokenConfigurationProcess, filter.limit, setScreen]);

  const loadMore = async () => {
    if (loadingMore || !phases || !tokenConfigurationProcess) return;

    setLoadingMore(true);

    const response = await tokenConfigurationService.getPhasesByModelId({
      tokenOfferingModelId: screen.modelId as string,
      limit: filter.limit,
      searchPage: filter.searchPage,
    });
    const morePhases: TokenPhase[] = response.data;

    setPhases((prev) => [...(prev as any), ...morePhases]);
    setFilter((prev: any) => ({ ...prev, searchPage: prev.searchPage + 1 }));
    setCanLoadMore(morePhases.length === filter.limit);
    setLoadingMore(false);
  };

  const colums = [
    {
      title: 'Phase',
      dataIndex: 'phaseName',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (value: number) => moment(value).format('LLL'),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: (value: number) => moment(value).format('LLL'),
    },
    {
      title: 'Details',
      render: (value, record: TokenPhase) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setScreen({
                  ...screen,
                  component: 'phases-details',
                  tokenPhaseId: record._id,
                });
              }}
            >
              View
            </Button>

            {(userInfo.role === 'issuer super admin' ||
              userInfo.role === 'issuer token admin') && (
              <Button
                type="link"
                onClick={() => {
                  setScreen({
                    ...screen,
                    component: 'phases-edit',
                    tokenPhaseId: record._id,
                  });
                }}
              >
                Edit
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={20}>
          {!phases && (
            <div style={{ textAlign: 'center' }}>
              <br />
              <Spin size="large" />
            </div>
          )}
          {phases && (
            <>
              <Card>
                <Title level={2} style={{ textAlign: 'center' }}>
                  Phases
                </Title>
                <Table
                  columns={colums}
                  dataSource={phases}
                  rowKey="_id"
                  pagination={false}
                  footer={() => (
                    <div style={{ textAlign: 'center' }}>
                      {phases && canLoadMore && (
                        <Button onClick={loadMore}>
                          <PlusOutlined />
                          Load more
                        </Button>
                      )}
                    </div>
                  )}
                />
                {userInfo.role === 'issuer token admin' && (
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      size="large"
                      style={{ marginRight: '5px' }}
                      htmlType="button"
                      onClick={() =>
                        setScreen({ component: 'offering-models-list' })
                      }
                    >
                      BACK
                    </Button>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      onClick={() =>
                        setScreen({ ...screen, component: 'phases-new' })
                      }
                    >
                      NEW PHASE
                    </Button>
                  </div>
                )}
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
