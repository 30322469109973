import React, { useEffect, useState } from "react";
import * as queryString from "query-string";
import { Result, Button, Descriptions, message, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";
import CardHOC from "../../CardHOC";
import { InvestorService } from "../Investor.service";
import { AuthService } from "../../Shared/Auth.service";
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();

const skrillStatusMap = {
  "2": "Processed",
  "0": "Pending",
  "-1": "Cancelled",
  "-2": "Failed",
  "-3": "Chargeback"
}

const SkrillPaymentStatus = () => {
  const [paymentData, setPaymentData] = useState();
  const [paymentStatusScheduler, setPaymentStatusScheduler] = useState(null)
  const [transaction_id, setTransactionId] = useState()
  const [loading, setLoading] = useState(false)
  const history = useHistory();
  const { userInfo } = useUserContext();
  // const { transaction_id, slug } = useParams();

  useEffect(() => {
    setLoading(true)
    const queryUrl = window.location.search
    const params = queryString.parse(queryUrl)
    const {transaction_id} = params
    setTransactionId(transaction_id)
    setLoading(false)
  }, [])

  const fetchSkrillPaymentDetails = async () => {
    setLoading(true)
    const response = await investorService.getSkrillPaymentDetails({
      transaction_id
    });
    if (response.success && response.data) {
      console.log("Data", response.data);
      setPaymentData(response.data);
    } else {
      message.error("Something went wrong. Please try again");
      setPaymentData();
    }
    setLoading(false)
  }

  const fetchPaymentUtil = async () => {
    if (paymentData && paymentData!==null && paymentData.status) {
      if (paymentData.staus == 0) {
        fetchSkrillPaymentDetails()
      }
    } else {
      if (!paymentData || paymentData === null) {
        fetchSkrillPaymentDetails()
      }
    }
  }

  const updateSkrillStatus = async () => {
    try {
      const response = await investorService.updateSkrillPaymentStatus({
        transaction_id,
        status: 2
      })
      if (response.success && response.data) {
        console.log("Data", response.data);
        setPaymentData(response.data);
      } else {
        message.error("Something went wrong. Please try again");
        setPaymentData();
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      message.error("Something Went Wrong")
    }
  }

  useEffect(() => {
    setLoading(true)
    if (transaction_id) {
      updateSkrillStatus()
    }
    setLoading(false)
  }, [transaction_id]);

  useEffect(() => {
    setLoading(true)
    if (transaction_id) {
      const interval = setInterval(fetchPaymentUtil, 10*1000)
      setPaymentStatusScheduler(interval)
      return () => clearInterval(paymentStatusScheduler)
    }
    setLoading(false)
  }, [transaction_id]);

  return (
    <>
      {loading && <Spin />}
      {paymentData && paymentData.status && parseInt(paymentData.status) === 2 && (
        <Result
          loading={!paymentData}
          status="success"
          title="Payment Completed"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/investor/home")}
            >
              Home
            </Button>,
          ]}
        >
          <div>
            <Descriptions
              title="Payment Details"
              extra={[<Button>Download Reciept</Button>]}
              bordered
            >
              <Descriptions.Item label="First Name">
                {userInfo?.firstName}
              </Descriptions.Item>
              <Descriptions.Item label="Last Name">
                {userInfo?.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Mode of payment">
                Crypto Currency
              </Descriptions.Item>
              <Descriptions.Item label="Agent">Coinbase</Descriptions.Item>
              <Descriptions.Item label="Transaction ID">
                {transaction_id}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">{`${paymentData?.amount} ${paymentData?.amount}`}</Descriptions.Item>
            </Descriptions>
          </div>
        </Result>
      )}
      {paymentData && (!paymentData.status || parseInt(paymentData.status) < 0) && (
        <Result
          loading={!paymentData}
          status="error"
          title="Invalid Payment"
          subTitle="you are reached here because your payment is invalid."
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/investor/home")}
            >
              Home
            </Button>,
          ]}
        ></Result>
      )}
      {JSON.stringify(paymentData, null, 4)}
    </>
  );
};

export default () => {
  return <CardHOC heading="Payment Status" component={<SkrillPaymentStatus />} />;
};
