import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AddRole from './AddRole';
import EditRole from './EditRole';
import ListRoleManagment from './ListRoleManagment';


export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <ListRoleManagment/>
      </Route>
      <Route path={`${path}/edit-role/:roleToDeleteId`}>
        <EditRole/>
      </Route>
      <Route path={`${path}/add-role`}>
        <AddRole/>
      </Route>
    </Switch>
  );
}