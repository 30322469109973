import React,{useEffect, useState} from 'react'
import {Card,Col,Row,Typography, Divider, Collapse} from 'antd'
import {IssuerSuperAdminService} from '../IssuerSuperAdmin.service'
import {AuthService} from '../../Shared/Auth.service'
import { Link } from 'react-router-dom';

const auth=new AuthService();
const useUserContext=()=> auth.useUserContext();
const {Title}=Typography
const {Panel}=Collapse
const issuerSuperAdminService=new IssuerSuperAdminService()
export default ()=>{
  const [questions,setQuestions]=useState([])
  const {userInfo,setUserInfo}=useUserContext();
  const [generalQuestions,setGeneralQuestions]=useState([])
  const [offeringDocsQuestions,setOfferingDocsQuestions]=useState([])
  const [socialMediaQuestions,setSocialMediaQuestions]=useState([])
  const [businessQuestions,setBusinessQuestions]=useState([])
  const [generalAnswers,setGeneralAnswers]=useState('')
  const [socialMediaAnswers,setSocialMediaAnswers]=useState('')
  const [businessAnswers,setBusinessAnswers]=useState('')
  const [offeringDocsAnswers,setOfferingDocsAnswers]=useState('')
  useEffect(()=>{
    (async ()=>{
      const response=await issuerSuperAdminService.getQuestionsToView({company:userInfo.companyName});
      const ques=response.data.filter(que=>que.isAnswered!==false)
      setQuestions(ques)
      applyFilter(ques)
      generateData()
    })();
  },[questions])

  const applyFilter=(ques)=>{
    const generalQuestions=ques?.filter((question)=>question.category==='General')
    setGeneralQuestions(generalQuestions)
    const offeringDocsQuestions=ques?.filter((question)=>question.category==='Offering docs')
    setOfferingDocsQuestions(offeringDocsQuestions)
    const businessQuestions=ques?.filter((question)=>question.category==='Business')
    setBusinessQuestions(businessQuestions)
    const socialMediaQuestions=ques?.filter((question)=>question.category==='Social Media')
    setSocialMediaQuestions(socialMediaQuestions)

  }

  const generateData=()=>{
    var answer=''
    for(var i=0;i<generalQuestions.length;i++)
    {
      if(generalQuestions[i].hidden===true)continue
      answer+='<h1>'+generalQuestions[i].question+'</h1>'
      if(generalQuestions[i].type==='attachement')answer+='<a href='+generalQuestions[i].answer.URL+'>'+generalQuestions[i].answer.name+'</a>';
      else answer+=generalQuestions[i].answer;
    }
    setGeneralAnswers(answer)
    answer=''
    for(i=0;i<businessQuestions.length;i++)
    {
      if(businessQuestions[i].hidden===true)continue
      answer+='<h1>'+businessQuestions[i].question+'</h1>'
      if(businessQuestions[i].type==='attachement')answer+='<a href='+businessQuestions[i].answer.URL+'>'+businessQuestions[i].answer.name+'</a>';
      else answer+=businessQuestions[i].answer;
    }
    setBusinessAnswers(answer)
    answer=''
    for(i=0;i<socialMediaQuestions.length;i++)
    {
      if(socialMediaQuestions[i].type==='attachement' || socialMediaQuestions[i].hidden===true)continue
      answer+='<h1>'+socialMediaQuestions[i].question+'</h1>'
      if(socialMediaQuestions[i].type==='attachement')answer+='<a href='+socialMediaQuestions[i].answer.URL+'>'+socialMediaQuestions[i].answer.name+'</a>';
      else answer+=socialMediaQuestions[i].answer;
    }
    setSocialMediaAnswers(answer)
    answer=''
    for(i=0;i<offeringDocsQuestions.length;i++)
    {
      if(offeringDocsQuestions[i].hidden===true)continue
      answer+='<h1>'+offeringDocsQuestions[i].question+'</h1>'
      if(offeringDocsQuestions[i].type==='attachement')answer+='<a href='+offeringDocsQuestions[i].answer.URL+'>'+offeringDocsQuestions[i].answer.name+'</a>';
      else answer+=offeringDocsQuestions[i].answer;
    }
    setOfferingDocsAnswers(answer)
  }
  return(
    <>
    <br/>
     <Row justify="center">
      <Col span={22}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Home
          </Title>
          <Divider/>
          <Collapse>
          <Panel header="General" key="general" style={{textAlign:'left'}}>
            <div  id='generalQuestions' dangerouslySetInnerHTML={{
              __html:generalAnswers
            }}>
            </div>
          </Panel>
          </Collapse>
          <Collapse>
          <Panel header="Business" key="business" style={{textAlign:'left'}}>
            <div id='businessQuestions' dangerouslySetInnerHTML={{
              __html:businessAnswers
            }}>
            </div>
          </Panel>
          </Collapse>
          <Collapse>
          <Panel header="Social Media" key="socialMedia" style={{textAlign:'left'}}>
            <div id='socialMedia' dangerouslySetInnerHTML={{
              __html:socialMediaAnswers
            }}>
            </div>
          </Panel>
          </Collapse>
          <Collapse>
          <Panel header="Offering docs" key="offeringDocs" style={{textAlign:'left'}}>
            <div id='offeringDocs'  dangerouslySetInnerHTML={{ __html:offeringDocsAnswers }} >
            </div>
          </Panel>
          </Collapse>
        </Card>
      </Col>
     </Row>
    </>
  )
}