import { MetamaskService } from '../../../Metamask.service';
import {TrustedForwarderService} from "../../../TrustedForwarder.service";


const trustedForwarderService = new TrustedForwarderService();

export default class OwnershipFacet {
  contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }


  async transferOwnership(
    tokenAddress: string,
    _from: string,
    _newOwner: string,
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.transferOwnership(_newOwner);

    if (!extra.delegate) return method.send({ from: _from });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: _from
    });

  }

  async owner(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.owner().call()) as string;
  }

};