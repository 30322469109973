import React, { useState } from "react";
import { Button, Form, Input, Progress } from "antd";
import { SharedService } from "../../Shared/Shared.service";
import { IssuerSuperAdminService } from "./../IssuerSuperAdmin.service";
const sharedService = new SharedService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const { Item } = Form;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 10,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 6,
  },
};
const validationRules = {
  required: [
    {
      required: true,
      message: "Field can't be empty",
    },
  ],
};

export default ({ onSubmit, submitting, view, APIConfiguration }) => {
  const [apiKeyForm] = Form.useForm();

  const [filesObj, setFilesObj] = useState({
    companyLogo: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  });

  const initialFileObj = {
    companyLogo: {
      uploading: false,
      uploadFilePercentage: 0,
      URL: null,
      name: null,
      type: null,
    },
  };

  const uploadFile = (e, key) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    console.log(file);

    if (!file) return;

    const fileType = sharedService.getFileType(file.name);

    setFilesObj((prev) => {
      const current = sharedService.clone(prev);
      current[key].URL = null;
      return current;
    });

    issuerSuperAdminService.uploadFile(
      file,
      (uploadFilePercent) => {
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = true;
          current[key].uploadFilePercentage = uploadFilePercent;
          return current;
        });
      },
      (err) => {
        console.error(err);
        setFilesObj((prev) => {
          const current = sharedService.clone(prev);
          current[key].uploading = false;
          return current;
        });
      },
      async (response) => {
        if (response.success) {
          const URL = response.data.url;
          await apiKeyForm.setFieldsValue({ [key]: URL });

          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].URL = URL;
            current[key].uploading = false;
            current[key].name = file.name;
            current[key].type = fileType.toLowerCase();
            return current;
          });
        } else {
          console.error(response.error);
          setFilesObj((prev) => {
            const current = sharedService.clone(prev);
            current[key].uploading = false;
            return current;
          });
        }
      }
    );
  };

  const submit = async (data) => {
    await onSubmit(data);
    apiKeyForm.resetFields();
    setFilesObj(initialFileObj);
  };
  return (
    <>
      <Form {...layout} form={apiKeyForm} onFinish={submit}>
        {view === "SendGrid" && (
          <>
            <Item
              label="Sendgrid private key"
              name="sendgridPrivateKey"
              rules={validationRules.required}
            >
              <Input disabled={submitting}></Input>
            </Item>
            <br />
            <b>Current key:</b> {APIConfiguration?.sendgridPrivateKey}
            <br />
          </>
        )}
        {view === "Coinbase" && (
          <>
            <Item
              label="Coinbase API key"
              name="coinbaseAPIKey"
              rules={validationRules.required}
            >
              <Input disabled={submitting}></Input>
            </Item>
            <br />
            <b>Current key:</b>
            {APIConfiguration?.coinbaseAPIKey}
            <br />
          </>
        )}
        {view === "Prime Trust" && (
          <>
            <Item
              name="primeTrustAPIKey"
              label="Primetrust API key"
              rules={validationRules.required}
            >
              <Input disabled={submitting} />
            </Item>
            <br />
            <b>Current key:</b>
            {APIConfiguration?.primeTrustAPIKey}
            <br />
          </>
        )}
        {view === "Mobile app url" && (
          <>
            <Item
              name="mobileAppURL"
              label="Mobile app URL:"
              rules={validationRules.required}
            >
              <Input disabled={submitting} />
            </Item>
            <br />
            <b>Current URL:</b>
            {APIConfiguration?.mobileAppURL}
            <br />
          </>
        )}
        {view === "noreplyEmail" && (
          <>
            <Item
              name="noreplyEmail"
              label="No reply email:"
              rules={validationRules.required}
            >
              <Input disabled={submitting} />
            </Item>
            <br />
            <b>Current Email:</b>
            {APIConfiguration?.noreplyEmail}
            <br />
          </>
        )}
        {view === "support email" && (
          <>
            <Item
              name="supportEmail"
              label="Support Email:"
              rules={validationRules.required}
            >
              <Input disabled={submitting} />
            </Item>
            <br />
            <b>Current Email:</b>
            {APIConfiguration?.supportEmail}
            <br />
          </>
        )}
        {view === "Main app URL" && (
          <Item
            name="mainAppURL"
            label="Main App URL:"
            rules={validationRules.required}
          >
            <Input disabled={submitting} />
          </Item>
        )}
        {view === "Company Name" && (
          <>
            <Item
              name="companyName"
              label="Company name:"
              rules={validationRules.required}
            >
              <Input disabled={submitting} />
            </Item>
            <br />
            <b>Current name:</b>
            {APIConfiguration?.companyName}
            <br />
          </>
        )}
        {view === "Company Logo" && (
          <>
            <Item
              name="companyLogo"
              label="Company Logo:"
              rules={validationRules.required}
            >
              <>
                <Input
                  type="file"
                  onChange={(e) => {
                    uploadFile(e, "companyLogo");
                  }}
                />
                {filesObj.companyLogo.uploadFilePercentage > 0 && (
                  <Progress
                    percent={filesObj.companyLogo.uploadFilePercentage}
                  />
                )}
              </>
            </Item>
            <br />
            <b>Current Logo:</b>
            <a
              href={APIConfiguration?.companyLogo}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={APIConfiguration?.companyLogo} alt="company logo" />
            </a>
            <br />
          </>
        )}
        <Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Submit
          </Button>
        </Item>
      </Form>
    </>
  );
};
