import React, { useState, useEffect } from "react";
import { CheckCircleTwoTone, ClockCircleTwoTone } from "@ant-design/icons";
import { Checkbox, Card, Button, Radio, Input, message } from "antd";
import "./styles.scss";
import dot from "../../../assets/images/logo/dot.svg";
import { AuthService } from "../../Shared/Auth.service";
import { IssuerCounselService } from "./../IssuerCounsel.service";

const Dot = () => <img className="dot" src={dot} />;
const { TextArea } = Input;

const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const issuerCounselService = new IssuerCounselService();

const TransactionDocuments = () => {
  const { userInfo, setUserInfo } = useUserContext();
  const [transactionDoc, setTransactionDoc] = useState("");
  const [status, setStatus] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await issuerCounselService.getCouncelInfo(
        userInfo.company.id
      );
      if (response) {
        console.log(response?.data);
        setStatus(response?.data?.documentStatus);
        setTransactionDoc(response?.data?.transactionDocuments);
        if (response?.data?.documentStatus) {
          setDisabled(true);
        }
      }
    })();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    if (transactionDoc) {
      const response = await issuerCounselService.setTransactionDocuments({
        company_id: userInfo.company.id,
        transactionDocuments: transactionDoc,
        documentStatus: status,
      });
      if (response) {
        if (status) {
          setSubmit(true);
          setDisabled(true);
        }
      } else message.error("Error occured");
    }
    setLoading(false);
  };

  return (
    <div className="ica-dashboard">
      <Card
        className="issue-card"
        title={<span className="dashboard-title">Transaction Documents</span>}
      >
        <span className="header-text">
          Add List of Documents prepared for the Issuer Company Offering :-
        </span>
        <TextArea
          rows={7}
          value={transactionDoc}
          onChange={({ target }) => setTransactionDoc(target.value)}
          placeholder="List of documents..."
        />
        <ul>
          <li className="uppertext">For example :</li>
          <li>
            <Dot />
            1. Purchase agreement or placement agency agreement with financial
            intermediaries.
          </li>
          <li>
            <Dot />
            2. Subscription agreement with purchaser.
          </li>
          <li>
            <Dot />
            3. Indenture, if issuer is offering debt securities.
          </li>
        </ul>
      </Card>
      <br />
      <div className="buttons-footer">
        <Radio.Group
          value={status}
          onChange={() => setStatus(!status)}
          size="large"
        >
          <Radio.Button value={false} disabled={disabled}>
            <ClockCircleTwoTone /> In-Progress
          </Radio.Button>
          <Radio.Button value={true}>
            <CheckCircleTwoTone twoToneColor="#52c41a" /> Completed
          </Radio.Button>
        </Radio.Group>
        {!submit && (
          <Button
            type="primary"
            loading={loading}
            onClick={onSubmit}
            size="large"
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default TransactionDocuments;
