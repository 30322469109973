import React from "react";
import { Button, Form, Input } from "antd";
const { Item } = Form;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 10,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 6,
  },
};
const validationRules = {
  required: [
    {
      required: true,
      message: "Field can't be empty",
    },
  ],
};

export default ({ onSubmit, submitting, view, APIConfiguration }) => {
  const [apiKeyForm] = Form.useForm();
  const submit = async (data) => {
    await onSubmit(data);
    apiKeyForm.resetFields();
  };
  return (
    <>
      <Form {...layout} form={apiKeyForm} onFinish={submit}>
        {view === "SendGrid" && (
          <>
            <Item
              label="Sendgrid private key"
              name="sendgridPrivateKey"
              rules={validationRules.required}
            >
              <Input disabled={submitting}></Input>
            </Item>
            <br />
            <b>Current key:</b> {APIConfiguration?.sendgridPrivateKey}
            <br />
          </>
        )}
        {view === "Coinbase" && (
          <>
            <Item
              label="Coinbase API key"
              name="coinbaseAPIKey"
              rules={validationRules.required}
            >
              <Input disabled={submitting}></Input>
            </Item>
            <br />
            <b>Current key:</b>
            {APIConfiguration?.coinbaseAPIKey}
            <br />
          </>
        )}
        {view === "Prime Trust" && (
          <>
            <Item
              name="primeTrustAPIKey"
              label="Primetrust API key"
              rules={validationRules.required}
            >
              <Input disabled={submitting} />
            </Item>
            <br />
            <b>Current key:</b>
            {APIConfiguration?.primeTrustAPIKey}
            <br />
          </>
        )}
        {view === "Mobile app url" && (
          <>
            <Item
              name="mobileAppURL"
              label="Mobile app URL:"
              rules={validationRules.required}
            >
              <Input disabled={submitting} />
            </Item>
            <br />
            <b>Current URL:</b>
            {APIConfiguration?.mobileAppURL}
            <br />
          </>
        )}
        {view === "noreplyEmail" && (
          <>
            <Item
              name="noreplyEmail"
              label="No reply email:"
              rules={validationRules.required}
            >
              <Input disabled={submitting} />
            </Item>
            <br />
            <b>Current Email:</b>
            {APIConfiguration?.noreplyEmail}
            <br />
          </>
        )}
        {view === "support email" && (
          <>
            <Item
              name="supportEmail"
              label="Support Email:"
              rules={validationRules.required}
            >
              <Input disabled={submitting} />
            </Item>
            <br />
            <b>Current Email:</b>
            {APIConfiguration?.supportEmail}
            <br />
          </>
        )}
        {view === "Main app URL" && (
          <Item
            name="mainAppURL"
            label="Main App URL:"
            rules={validationRules.required}
          >
            <Input disabled={submitting} />
          </Item>
        )}
        {view === "Company name" && (
          <>
            <Item
              name="companyName"
              label="Company name:"
              rules={validationRules.required}
            >
              <Input disabled={submitting} />
            </Item>
            <br />
            <b>Current name:</b>
            {APIConfiguration?.companyName}
            <br />
          </>
        )}
        <Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Submit
          </Button>
        </Item>
      </Form>
    </>
  );
};
