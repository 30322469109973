import React, { useState, useEffect, useContext } from 'react'
import { User } from './interfaces';
// import * as firebase from 'firebase/app';
import axios from 'axios';
import { APIResponse } from '../Shared/interfaces';
import { environment } from '../../environments/environment';
import { SharedService } from '../Shared/Shared.service';
// import { useEventCallback } from 'rxjs-hooks';
// import { Observable } from 'rxjs';

const sharedService = new SharedService();


export class AuthService {

  // private static userSession: any;
  private APIURL = environment.APIURL;


  useUserInfo() {
    const [userInfo, setUserInfo] = useState<User | undefined | null>();

    useEffect(() => {
      (async () => {
        const isValidToken = sharedService.isValidToken;

        if(!isValidToken) {
          setUserInfo(null);
          sharedService.deleteToken();
          return;
        }
          
        const response = await this.getUserbyToken();

        if(!response.success) {
          setUserInfo(null);
          sharedService.deleteToken();
          return;
        }

        setUserInfo(response.data);
        
      })();
      // firebase.auth().onAuthStateChanged(firebaseUser => {
      //   if(!firebaseUser) {
      //     setUserInfo(null);
      //     return;
      //   }

        
      //   AuthService.userSession = firebase.firestore()
      //     .doc(`users/${firebaseUser.uid}`)
      //     .onSnapshot(doc => setUserInfo(doc.data() as User));

      // }, err => {
      //   console.error(err);
      //   this.logout();
      // });
    
    }, []);

    return [userInfo, setUserInfo] as any[];
  }

  // useAuthState() {
  //   const [setIsAuthenticated, isAuthenticated] = useEventCallback((event$: Observable<boolean>) => event$, sharedService.isValidToken);
  //   return [isAuthenticated, setIsAuthenticated];
  // }

  
  static UserContext = React.createContext
    <{
      userInfo: User | undefined | null, 
      setUserInfo: React.Dispatch<React.SetStateAction<User | null | undefined>>
    }>
    ({
      userInfo: undefined, 
      setUserInfo: () => {}
    });


  useUserContext() {
    return useContext(AuthService.UserContext);
  }
  
  // public async logout(){
  //   await firebase.auth().signOut();
  //   AuthService.userSession();
  // }

  async getUserbyToken() {
    return axios.post<any, APIResponse>(`${this.APIURL}/users/getUser`, {}, {headers: await sharedService.getAuthHeader()});
  }

}