import { MetamaskService } from '../../../Metamask.service';
import {TrustedForwarderService} from "../../../TrustedForwarder.service";

const trustedForwarderService = new TrustedForwarderService();

export default class Rule144Facet {
  contract = {
    ABI: require('./ABI.json')
  };

  private get web3() {
    return MetamaskService.web3;
  }

  saveRule144Config(
    tokenAddress: string,
    owner: string,
    _maxAffiliateSellPercentage: string,
    _maxAffiliateAmountMonths: number,
    _maxAffiliateAmount: string,
    _maxAffiliateTokensMonths: number,
    _maxAffiliateTokens: string,
    _investorLockPeriord: number,
    extra: {delegate: boolean} = {delegate: false}
  ) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    const method = contractInstance.methods.saveRule144Config(
      _maxAffiliateSellPercentage,
      _maxAffiliateAmountMonths,
      _maxAffiliateAmount,
      _maxAffiliateTokensMonths,
      _maxAffiliateTokens,
      _investorLockPeriord
    );

    if (!extra.delegate) return method.send({ from: owner });

    return trustedForwarderService.delegateTransaction({
      encodedFunction: method.encodeABI(),
      contractAddress: tokenAddress,
      signerAddress: owner
    });
  }

  async getMaxAffiliateSellPercentage(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getMaxAffiliateSellPercentage().call()) as string;
  }

  async getMaxAffiliateAmountMonths(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getMaxAffiliateAmountMonths().call()) as string;
  }

  async getMaxAffiliateAmount(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getMaxAffiliateAmount().call()) as string;
  }

  async getMaxAffiliateTokensMonths(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getMaxAffiliateTokensMonths().call()) as string;
  }

  async getMaxAffiliateTokens(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getMaxAffiliateTokens().call()) as string;
  }

  async getAffiliateRule144Params(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.getAffiliateRule144Params().call()) as string[];
  }

  async rule144ConfigInitialized(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
    return (await contractInstance.methods.rule144ConfigInitialized().call()) as boolean;
  }
};

// Not Implemented
// function investorLockPeriord() external view returns (uint256) {
// }
