import React, {useState, useEffect} from 'react';
import { Typography, Row, Col, Card, Input, Button, notification } from 'antd';
import { AuthService } from '../../Shared/Auth.service';
import { useHistory } from 'react-router-dom';
import { AccountService } from '../Account.service';
import { SharedService } from '../../Shared/Shared.service';



const {Title} = Typography;

const accountService = new AccountService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();


export default function TOTP() {
  const {userInfo, setUserInfo} = useUserContext();
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useState('');


  useEffect(() => {
    if(userInfo && !userInfo['differentIP'] && !userInfo['differentDevice'] && !userInfo['deviceExpired']) history.push('/token-configurations');

  },[userInfo, history]);


  const verifyTOTP = async() => {
    setSubmitting(true);

    try {
      const response = await accountService.verifyTOTP({token});

      if (response.success) {
        notification.success({
          message: 'Success',
          description: 'Device Authenticated successfully'
        });

        setUserInfo(prev => {
          if(!prev) return;
          const current = sharedService.clone(prev);
          current.status.firstTimeLoggedIn = false;
          // current.lastIP = response.data;
          current['differentIP'] = false;
          current['differentDevice'] = false;
          current['deviceExpired'] = false;
          return current; 
        });
        
        history.push('/token-configurations');

      } else {
        notification.error({
          message: 'Error',
          description: response.error.message
        });
      }
      
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'An error has ocurred. Try again later'
      });
    }

    setSubmitting(false);
  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {/* {loading && 
            <div style={{textAlign:'center'}}>
              <Spin size='large'/>
            </div>
          } */}
            <>
              <Card>
                <Title level={1} style={{textAlign:'center'}}>Device Authentication</Title>
                <Row justify="center">
                  <Col span={20}>
                    <Card bordered={false} className='card-shadow'>
                      <br/>
                      <div>
                        <p style={{textAlign:'center'}}>Enter the 2-step verification code provided by your Authenticator App</p>
                        <Row>
                          <Col offset={8} span={6}>
                            <Input onChange={(e) => setToken(e.target.value)}/>
                          </Col>
                        </Row>
                        
                        <br/>
                        <div style={{textAlign:'right'}}>
                          <Button type='primary' disabled={!token} loading={submitting} onClick={verifyTOTP}>VERIFY</Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </>
        </Col>
      </Row>
    </>
  );

}