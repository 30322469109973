import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';

const headers = {
  'Content-Type': 'application/json',
  'apiKey': environment.apiKey
}

const sharedService = new SharedService();

export class AccountService {

  private APIURL = environment.APIURL;

  login(email: string, password: string) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/login`, {email, password}, {headers});
    // return new Promise(async(resolve, reject) => { //CHECK IF EMAIL IS VERIFIED
      // await firebase.auth().setPersistence('none'); //local session none
      // firebase.auth().signInWithEmailAndPassword(email, password)
      //   .then(() => resolve()) // CHECK IF userinf exists
      //   .catch(err =>{
      //     console.error(err);
  
      //     if(err.code === 'auth/user-not-found') {
      //       reject('Invalid Email'); return;
      //     }
      //     if(err.code === 'auth/invalid-password' || err.code === 'auth/wrong-password') {
      //       reject('Invalid Password'); return;
      //     }
      //     if(err.code === 'auth/invalid-email') {
      //       reject('Invalid email format'); return;
      //     }
      //     if(err.code === 'auth/network-request-failed') {
      //       reject('Network connection error'); return;
      //     }
      //     reject('An error has ocurred. Try again later');
      //   });
    // });
  }

  registerIssuerSuperAdmin(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/registerIssuerSuperAdmin`, req, {headers});
  }

  async generateTOTPSecret() {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/generateTOTPSecret`, {}, {headers: await sharedService.getAuthHeader()});
  }

  async verifyTOTP(req: {token: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/verifyTOTP`, req, {headers: await sharedService.getAuthHeader()});
  }

  registerTeamMember(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/registerTeamMember`, req, {headers});
  }

  registerIssuerCounsel(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/registerIssuerCounsel`, req, {headers});
  }

  registerIssuerCompanyOfficer(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/registerIssuerCompanyOfficer`, req, {headers});
  }

  registerIssuerTokenAdmin(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/registerIssuerTokenAdmin`, req, {headers});
  }

  registerPlatformAdmin(req: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/registerPlatformAdmin`, req, { headers });
  }

  verifyEmail(req: {code: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/verifyEmail`, req, {headers});
  }

  resendAndUploadEmailVerificationCode(req: {email: string}) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/resendAndUploadEmailVerificationCode`, req, {headers});
  }
  async getData(data:any)
  { 
    return axios.post<any, APIResponse>(`${this.APIURL}/platformSuperAdmin/getUserByEmail`,data, {headers});
  }

  async registerUser(issuer:any)
  {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/registerUser`,issuer, {headers});
  }
  async registerInvestor(req:any)
  {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/registerInvestor`,req, {headers});
  }
  sendDeviceRegistrationOTP(user:any)
  {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/sendDeviceRegistrationOTP`,user, {headers});
  }
  verifyOTP(data:any)
  {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/verifyOTP`,data, {headers});
  }
  getTeamMemberDetails(data:any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/getTeamMemberDetails`, data, { headers });
  }
  async isAllQuestionAnswered(data: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/isAllQuestionAnswered`, data, { headers });
  }

  async isAllDDAnswered(data: any) {
    return axios.post<any, APIResponse>(`${this.APIURL}/account/isAllDDAnswered`, data, { headers });
  }
  async getSecretCode() {
    return axios.get<any, APIResponse>(`${this.APIURL}/account/getSecretCode`,{ headers });
  }
}
