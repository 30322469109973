import { SharedService } from '../Shared/Shared.service';
import { APIResponse} from '../Shared/interfaces';
import { environment } from '../../environments/environment';
import axios from 'axios';


const sharedService = new SharedService();


export class RecoveryRequestsService {
  private APIURL = environment.APIURL;


  async requestRecoveryProcess(prop: { oldWallet: string; newWallet: string; reason: string; tokenSymbol: string }){
    return axios.post<any, APIResponse>(`${this.APIURL}/recoveryRequests/requestRecoveryProcess`, prop, {headers: await sharedService.getAuthHeader()});    
  }

  async getRecoveryProcessRequestByUserId(prop: {tokenSymbol: string}) {
    return axios.get<any, APIResponse>(`${this.APIURL}/recoveryRequests/getRecoveryProcessRequestByUserId`, {params: prop, headers: await sharedService.getAuthHeader()});
  }

  async getRecoveryProcessRequests() {
    return axios.get<any, APIResponse>(`${this.APIURL}/recoveryRequests/getRecoveryProcessRequests`, {headers: await sharedService.getAuthHeader()});
  }

  async getRecoveryProcessRequestById(prop: {recoveryProcessReqId: string}) {
    return axios.get<any, APIResponse>(`${this.APIURL}/recoveryRequests/getRecoveryProcessRequestById`, {params: prop, headers: await sharedService.getAuthHeader()});
  }


  async approveRecoveryProcessRequest(prop: {recoveryProcessReqId: string}){
    return axios.post<any, APIResponse>(`${this.APIURL}/recoveryRequests/approveRecoveryProcessRequest`, prop, {headers: await sharedService.getAuthHeader()});    
  }

  async getInvestorTokenSymbols() {
    return axios.get<any, APIResponse>(`${this.APIURL}/recoveryRequests/getInvestorTokenSymbols`, {headers: await sharedService.getAuthHeader()});
  }

  async getWalletWhitelistRequestByAddress(address: string) {
    return axios.get<any, APIResponse>(`${this.APIURL}/issuerComplianceAdmin/getWalletWhitelistRequestByAddress`, {
      params: {address},
      headers: await sharedService.getAuthHeader()
    });
  }




}