import { Button, Card, Col, Row, Spin, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { RecoveryRequest } from '../../Shared/interfaces';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { RecoveryRequestsService } from '../RecoveryRequests.service';
import { SharedService } from '../../Shared/Shared.service';
import { MetamaskService } from '../../Shared/Metamask.service';


const {Title} = Typography;
const recoveryRequestsService = new RecoveryRequestsService();
const sharedService = new SharedService();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


export default function RecoveryRequestsList() {
const {networkId} = useSelectedWalletContext();

  // const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [loading, setLoading] = useState<boolean>(true);

  const [recoveryProcessReqs, setRecoveryProcessReqs] = useState<RecoveryRequest[]>();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const _recoveryProcessReqs = (await recoveryRequestsService.getRecoveryProcessRequests()).data as RecoveryRequest[];
      setRecoveryProcessReqs(_recoveryProcessReqs);
      setLoading(false);

    })();
  },[]);


  const columns = [
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      render: (value: string, record) => `${record.firstName} ${record.lastName}`
    },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email'
    },
    {
      title: 'Old Wallet',
      dataIndex: 'oldWallet',
      render: (value: string) => {
        return (
          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}>
            {sharedService.minifyAddress(value)}
          </a> 
        );
      }
    },
    {
      title: 'New Wallet',
      dataIndex: 'newWallet',
      render: (value: string) => {
        return (
          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}>
            {sharedService.minifyAddress(value)}
          </a> 
        );
      }
    },
    {
      title: 'ACTION',
      render: (value, record) => {
        return (
          <>
            <Button type="primary" onClick={() => history.push(`/recovery-requests/${record._id}`)}>
              View
            </Button>
          </>
        );
      }
    }
  ];


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={20}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }

          {!loading && 
            <Card>
              <Title level={3} style={{textAlign:'center'}}>Securities Recovery Processes</Title>
              <Table
                // scroll={{x: true}}
                columns={columns} 
                dataSource={recoveryProcessReqs}
                rowKey='_id'
                pagination={false}
              />
            </Card>
          }
        </Col>
      </Row>
    </>
  );
}