import React, { Fragment } from "react";
import { Card, Form, Radio } from "antd";
import { SharedService } from "../../../Shared/Shared.service";

const typeOfSecurityRadioStyle = {
  display: "block",
  // height: '30px',
  lineHeight: "30px",
};

const sharedService = new SharedService();

const commonStockOptions = sharedService.commonStockOptions;
const equitySecurityClassOptions = sharedService.equitySecurityClassOptions;
const preferentialSecurityClassOptions =
  sharedService.preferentialSecurityClassOptions;

const getEquitityBackSecurityItem = (getFieldValue, typeOfSecurity: string) => {
  return getFieldValue("typeOfSecurity") === typeOfSecurity ? (
    <Card
      style={{ borderColor: "darkgrey" }}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <Form.Item
        labelCol={{ push: 2 }}
        wrapperCol={{ push: 2 }}
        style={{ marginBottom: 0 }}
        label={`Select one of the option for Equity Backed Securities`}
        name="stock"
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Radio.Group>
          {commonStockOptions.map((option) => (
            <Fragment key={option.value}>
              <Radio value={option.value} style={typeOfSecurityRadioStyle}>
                {option.name}
              </Radio>
              {
                // option.value.startsWith("common") &&
                <Form.Item
                  style={{ marginBottom: 0 }}
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.stock !== currentValues.stock
                  }
                >
                  {({ getFieldValue }) =>
                    getFieldValue("stock") === option.value ? (
                      <Card
                        style={{ marginRight: "30px", borderColor: "darkgrey" }}
                        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <Form.Item
                          label="Select Class"
                          name="equitySecurityClass"
                          labelCol={{ span: 24 }}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Radio.Group style={{ display: "block" }}>
                            {equitySecurityClassOptions.map((option) => (
                              <Radio
                                key={option.value}
                                value={option.value}
                                style={typeOfSecurityRadioStyle}
                              >
                                {option.name}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item
                          label="Voting Rights for Common Stock Equity Backed Security"
                          name="hasVotingRights"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio key="yes" value={true}>
                              Yes
                            </Radio>
                            <Radio key="no" value={false}>
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Card>
                    ) : null
                  }
                </Form.Item>
              }
            </Fragment>
          ))}
        </Radio.Group>
      </Form.Item>
    </Card>
  ) : null;
};

const getPreferentialSecurityItem = (getFieldValue, typeOfSecurity: string) => {
  return getFieldValue("typeOfSecurity") === typeOfSecurity ? (
    <Card
      style={{ borderColor: "darkgrey" }}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <Form.Item
        labelCol={{ push: 2 }}
        wrapperCol={{ push: 2 }}
        style={{ marginBottom: 0 }}
        label={`Select the Class`}
        name="preferentialSecurityClass"
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Radio.Group>
          {preferentialSecurityClassOptions.map((option) => (
            <Radio
              key={option.value}
              value={option.value}
              style={typeOfSecurityRadioStyle}
            >
              {option.name}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </Card>
  ) : null;
};

export { getEquitityBackSecurityItem, getPreferentialSecurityItem };
