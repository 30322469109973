import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ListWithdrawalRequests from './ListWithdrawalRequests';



export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} exact>
        <ListWithdrawalRequests/>
      </Route>

      {/* <Route path={`${path}/add-crypto-account`}>
        <AddApprover/>
      </Route> */}
    </Switch>
  );
}
