import React from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AIVerificationRequest from './AIVerificationRequest'
import InvestorPaymentDetails from './InvestorPaymentDetails'
import AllowCountries from '../IssuerTokenAdmin/AllowCountries';
import TokenManagment from './TokenManagment';
import IssueTokens from './IssueTokens';
import RemoveLegend from './RemoveLegend';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/ai-verification-request`}>
      <AIVerificationRequest/>
      </Route>
      <Route path={`${path}/transfer-restriction/select-countries`}>
        <AllowCountries />
      </Route>
      <Route path={`${path}/investor-payment-details`}>
      <InvestorPaymentDetails/>
      </Route>
      <Route path={`${path}/token-managment`}>
        <TokenManagment/>
      </Route>
      <Route path={`${path}/issue-tokens`}>
        <IssueTokens/>
      </Route>
      <Route path={`${path}/remove-legend/:tokenConfigurationProcessId`}>
        <RemoveLegend/>
      </Route>
    </Switch>
  )
}
