import React, {Component} from 'react';
import ReactTableToExcel from 'react-html-table-to-excel';
 
class ExportPendingToExcel extends Component {
 
    constructor(props) {
        super(props);
    }
 
    render() {
        return (
            <div >
            <ReactTableToExcel
                    id="test-table-xls-button"
                    table="table-to-xls"
                    filename={this.props.fileName}
                    sheet="Pending Investor Details"
                    buttonText="Export"/>
                <table id="table-to-xls" hidden={true}>
                  <tr>
                    <td>FIRST NAME</td>
                    <td>LAST NAME</td>
                    <td>COUNTRY</td>
                    <td>TOKEN TYPE</td>
                    <td>REGULATION</td>
                    <td>TOKEN TO BE ISSUED</td>
                    <td>DATE OF PURCHASE</td>
                    <td>WALLET ADDRESS</td>
                    <td>PHASE</td>
                  </tr>
                    {
                        this.props.data.map((data,index)=>{
                            return(

                                <tr key={index}>
                                <td>{data.firstName}</td>
                                <td>{data.lastName}</td>
                                <td>{data.country}</td>
                                <td>{data.tokenType}</td>
                                <td>{data.regulation}</td>
                                <td>{data.tokenToIssued}</td>
                                <td>{data.dateOfPurchase}</td>
                                <td>{data.walletAddress}</td>
                                <td>{data.phase}</td>
                                </tr>
                                );
                        })
                    }
                </table>
 
            </div>
        );
    }
}
 
export default ExportPendingToExcel