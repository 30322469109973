import React,{useState,useEffect}from 'react'
import {Form, Table, Checkbox, Button,Input} from 'antd'
import { Select } from 'antd'
import {PlatformSuperAdminService} from '../PlatformSuperAdmin.service'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const platformSuperAdminService=new PlatformSuperAdminService();
export default()=>{
  const [roles,setRoles]=useState(null)
  const [data,setData]=useState(null)
  const [displayFlag,setDisplayFlag]=useState(false)
  const [form]=Form.useForm()
  
  useEffect(
    ()=>{
      (async ()=>{
          const response=await platformSuperAdminService.getRoles();
          setRoles(response.data['0'].roles);
      })();
    },[data]
  )
  const getDataByRole=(selectedValue)=>{
    (async ()=>{
      const response=await platformSuperAdminService.getUserByRole(selectedValue);
      console.log(data)
      if(response.success)setData(response.data)
    })();
  }
  const columns=[
    {
      title:"Action",
      render:(text,index)=><Button onClick={()=>switchToEditView(index)}>Edit</Button>
    },
    {
      title:'First Name',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'Last Name',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'Email',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'Contact No.',
      dataIndex:'contactNumber',
      key:'contactNumber'
    },
    {
      title:'Issuer',
      dataIndex:'role',
      key:'role'

    },
    {
      title:'Active',
      dataIndex:'active',
      render:(text,index)=><Checkbox onChange={()=>selectionBox(index)} defaultChecked={text}></Checkbox>
    },
  ]
  const switchToEditView=value=>{
    form.setFieldsValue({role:value.role})
    form.setFieldsValue({firstName:value.firstName})
    form.setFieldsValue({lastName:value.lastName})
    form.setFieldsValue({email:value.email})
    form.setFieldsValue({role:value.role})
    form.setFieldsValue({contactNumber:value.contactNumber})
    setDisplayFlag(true)
  }
  const reset=()=>{
    form.resetFields()
    setDisplayFlag(false)
  }
  const selectionBox=async value=>{
    if(value.active)
    {
      value.active=false
    }
    else
    {
      value.active=true
    }
    console.log(value)
    await platformSuperAdminService.updateUserRole(value);   
  }
  const onFinished=(values)=>{
    (async()=>{
      console.log(values)
      const response=await platformSuperAdminService.updateUserRole(values);
      console.log(response)
      if(response.success)
      {
        getDataByRole(values.role)
        reset();
      }
    })()
  }
  return(
    <>
    <div hidden={displayFlag}>
    <Form>
      <Form.Item
      label="Select the Admin Role"
      >
        <Select loading={!roles} defaultValue={-1} style={{width:280}} onChange={getDataByRole}>
          <Select.Option key={-1} value={-1}>&lt;select&gt;</Select.Option>
          {roles?.map((role,index)=><Select.Option key={index} value={role}>{role}</Select.Option>)}
        </Select>
      </Form.Item>
    </Form>
    <Table dataSource={data} columns={columns} disabled={!data}></Table>
    </div>

    <div hidden={!displayFlag}>
    <Form
    onFinish={onFinished}
    form={form}
    >
      <Form.Item
      name='role'
      hidden={true}
      >
      <Input></Input>
      </Form.Item>
      <Form.Item
      name="firstName"
      label='First Name'
      rules={
        [
          {
            required:true,
            message:"required"
          }
        ]
      }
      >
      <Input style={{width:280,marginLeft:185}} placeholder='First Name'></Input>
      
      </Form.Item>
      <Form.Item
      label='Last Name'
      name='lastName'
      rules={
        [
          {
            required:true,
            message:"required"
          }
        ]
      }
      >
      <Input style={{width:280,marginLeft:185}} placeholder='Last Name' ></Input>
      </Form.Item>
      <Form.Item
      name="email"
      label='Email Address'
      rules={
        [
          {
            required:true,
            message:"required"
          }
        ]
      }
      >
      <Input  style={{width:280,marginLeft:165}} placeholder='Email'></Input>
      </Form.Item>
      <Form.Item
      name="contactNumber"
      label='Contact Number'
      rules={[{
        required:true,
        message:'Required!!'
      }]}
      > 
      <PhoneInput
        style={{width:310,marginLeft:310}}
      />

      

      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>UPDATE ROLE</Button>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={reset}>CANCEL</Button>
      </Form.Item>
      
    </Form>
 
    </div>
    </>
  )
}